import {
  Container,
  AppBar,
  Toolbar,
  TextField,
  FormControl
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom";
import React from "react";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { updateRating, getRequestIsRated } from "../../store/actions";
import Rating from "@material-ui/lab/Rating";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";

const queryString = require("query-string");

class RatingPage extends React.Component {
  state = {
    params: {},
    formDisplay: false,
    thankYouDisplay: false,
    loading: true,
    rating: 0,
    showRatingReqMessage: false
  };

  componentDidMount = async () => {
    let params = queryString.parse(this.props.location.search);
    params = {
      requestId: Buffer.from(params.r, "base64").toString()
    };
    this.setState(prevState => ({
      ...prevState,
      params
    }));
    let response = await this.props.getRequestIsRated(params.requestId);
    if (response.isRated === 0) {
      this.setState(prevState => ({
        ...prevState,
        formDisplay: true
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        thankYouDisplay: true
      }));
    }
    this.setState(prevState => ({
      ...prevState,
      loading: false
    }));
  };

  submitHandler = async () => {
    if (this.state.rating != 0) {
      const info = {
        rating: this.state.rating,
        requestId: this.state.params.requestId
      };
      let response = await this.props.updateRating(info);
      if (response === "success") {
        this.setState(prevState => ({
          ...prevState,
          formDisplay: false,
          thankYouDisplay: true
        }));
      } else {
        alert("Something wrong has happened. Please try again later.");
      }
    } else {
      this.setState(prevState => ({
        ...prevState,
        showRatingReqMessage: true
      }));
    }
  };
  ratingHandler = (event, newValue) => {
    this.setState(prevState => ({
      ...prevState,
      rating: newValue
    }));
  };

  render() {
    const { classes } = this.props;

    return (
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <AppBar className={classes.navbar}>
          <Toolbar>
            <div>
              <div style={{ fontSize: "1px", lineHeight: "5px" }}>&nbsp;</div>
              <img
                align="center"
                alt="BRI-icon"
                src="https://berealibiza-properties-images.s3.eu-central-1.amazonaws.com/Logos/BRI_logo.png"
                title="BRI-icon"
                width={140}
              />
            </div>
          </Toolbar>
        </AppBar>
        <div className={classes.card}>
          {this.state.loading ? (
            <CircularProgress style={{ color: "#DCAC38" }} />
          ) : (
            <div>
              <span
                className={classes.thankyou}
                style={{ opacity: this.state.thankYouDisplay ? "1" : "0" }}
              >
                Thank you!
              </span>
              <div
                className={classes.form}
                style={{ display: this.state.formDisplay ? "flex" : "none" }}
              >
                <span className={classes.title}>
                  What do you think of your rental last week?
                </span>
                <span
                  className={classes.reqMessage}
                  style={{
                    opacity: this.state.showRatingReqMessage ? "1" : "0"
                  }}
                >
                  * Enter your rating
                </span>
                <Rating
                  name="read-only"
                  value={this.state.rating}
                  size="large"
                  onChange={this.ratingHandler}
                />
                <DefaultButton text="Submit" onClick={this.submitHandler} />
              </div>
            </div>
          )}
        </div>
      </Container>
    );
  }
}

const styles = theme => ({
  navbar: {
    backgroundColor: "#fff", //'#cd943c'
    boxShadow: "0px 1px rgba(138, 138, 138, 0.2)"
  },
  card: {
    marginTop: "10rem",
    width: "27rem",
    height: "22rem",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    padding: "20px",
    color: "#717171",
    fontSize: "20px",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0rem 0rem 1rem rgba(0,0,0,0.2)",
    borderRadius: "2px"
  },
  title: {
    marginBottom: "2.5rem",
    letterSpacing: "1px",
    color: "#cd943c",
    textAlign: "center"
  },
  form: {
    display: "flex",
    opacity: "1",
    transition: "all 1s",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center"
  },
  formItem: {
    marginBottom: "20px",
    width: "20rem"
  },
  submit: {
    width: "10rem",
    height: "2.5rem",
    marginTop: "4rem",
    borderWidth: "0px",
    backgroundColor: "#cbaa5c",
    color: "#fff",
    fontWeight: "400",
    fontSize: "16px",
    textTransform: "uppercase",
    borderRadius: "100px",
    transition: "all 0.3s",
    "&:active": {
      outline: "none"
    },
    "&:focus": {
      outline: "none"
    },
    "&:hover": {
      backgroundColor: "#fff",
      color: "#cbaa5c",
      borderColor: "#cbaa5c",
      borderWidth: "1px"
    }
  },
  thankyou: {
    letterSpacing: "1px",
    color: "#cd943c",
    textAlign: "center",
    marginBottom: "0",
    textTransform: "uppercase",
    opacity: "0",
    transition: "all 1s"
  },
  reqMessage: {
    alignSelf: "flex-start",
    color: "#ff5151",
    fontSize: "12px",
    opacity: "0",
    transition: "all 0.3s",
    margin: "auto"
  }
});
const formItemStyle = {
  root: {
    "& label.Mui-focused": {
      color: "#e6b74d"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#BBB",
      borderWidth: "1px"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBB",
        borderWidth: "1px"
      },
      "&:hover fieldset": {
        borderColor: "#999",
        borderWidth: "1px"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e6b74d",
        borderWidth: "1px"
      }
    }
  }
};
const CssTextField = withStyles(formItemStyle)(TextField);
const CssFormControl = withStyles(formItemStyle)(FormControl);

const mapDispatchToProps = {
  updateRating,
  getRequestIsRated
};
const mapStateToProps = state => ({
  //   isAuthenticated: state.auth.token
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(RatingPage)));
