import { withRouter } from "react-router-dom";
import React from "react";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import OwnerCard from "../../components/OwnerCard/OwnerCard";
import Grid from "@material-ui/core/Grid";
import { getOwners } from "../../store/actions";
import Pagination from "../../components/Pagination/Pagination";
import TextInput from "../../components/TextInput/TextInput";
import CircularProgress from "@material-ui/core/CircularProgress";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";

class OwnersPage extends React.Component {
  state = {
    owners: [],
    search: "",
    currentPage: 1,
  };

  componentDidMount = async () => {
    const owners = await this.props.getOwners();
    console.log(owners);
    console.log("owners");
    this.setState((prevState) => ({
      ...prevState,
      owners,
    }));
  };

  paginate = async (pageNumber) => {
    this.setState((prevState) => ({
      ...prevState,
      currentPage: pageNumber,
    }));
  };

  searchChangedHandler = (event) => {
    this.setState((prevState) => ({
      ...prevState,
      search: event.target.value,
      currentPage: 1,
    }));
  };

  render() {
    const { classes } = this.props;
    let matchingOwners = [];
    if (this.state.owners) {
      if (this.state.search) {
        matchingOwners = this.state.owners.filter(
          (owner) =>
            owner.name
              .toLowerCase()
              .includes(this.state.search.toLowerCase()) ||
            owner.email.toLowerCase().includes(this.state.search.toLowerCase())
        );
      } else {
        matchingOwners = this.state.owners;
      }
    }

    const indexOfLastOwner = this.state.currentPage * 12;
    const indexOfFirstOwner = indexOfLastOwner - 12;
    const currentOwners = matchingOwners.slice(
      indexOfFirstOwner,
      indexOfLastOwner
    );

    let ownersList = [];
    if (currentOwners.length > 0) {
      ownersList = currentOwners.map((owner, index) => (
        <Grid key={index} item>
          <OwnerCard owner={owner} />
        </Grid>
      ));
    }
    return (
      <div style={{ minWidth: "1480px", paddingTop: 50 }}>
        <h3 className="title-5" style={{ paddingLeft: "15rem" }}>
          Owners
        </h3>
        <div className={classes.ownersSection}>
          {this.state.owners ? (
            <div className={classes.gridContainer}>
              <div
                style={{
                  width: "100%",
                  marginBottom: 15,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TextInput
                  label="Search"
                  value={this.state.search}
                  onChange={(event) => {
                    event.persist();
                    this.searchChangedHandler(event);
                  }}
                  variant="outlined"
                  width="38%"
                  type="text"
                />
                <DefaultButton
                  text="Add Owner"
                  onClick={() => {
                    this.props.history.push("/addowner");
                  }}
                  style={{ margin: 0, alignSelf: "center" }}
                />
              </div>
              <Grid container spacing={3}>
                {ownersList}
              </Grid>

              <div style={{ marginTop: "2rem" }}>
                <Pagination
                  postsPerPage={12}
                  totalPosts={matchingOwners.length}
                  paginate={this.paginate}
                  currentPage={this.state.currentPage}
                />
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                width: "100vw",
                color: "#DCAC38",
                position: "absolute",
                top: "0px",
                left: "0px",
              }}
            >
              <CircularProgress
                style={{ color: "#DCAC38", marginBottom: "2rem" }}
              />
              <div style={{ width: "29rem", textAlign: "center" }}>
                The loading process should be quick. If it is not, please check
                your internet connection and reload the page.
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  ownersSection: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "200px",
  },
  searchContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  gridContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "68rem",
    margin: "1rem",
    padding: "2rem",
    border: "1px solid #f7f7f7",
    borderRadius: "2px",
    boxSizing: "border-box",
  },
});

const mapDispatchToProps = {
  getOwners,
};
const mapStateToProps = (state) => ({
  //   isAuthenticated: state.auth.token
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(OwnersPage)));
