import {Container} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import React from "react";
import {withStyles} from "@material-ui/styles";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {getRenters, sendClientsEmail} from "../../store/actions";
import Pagination from "../../components/Pagination/Pagination";
import TextField from "@material-ui/core/TextField";
import {Alert} from "reactstrap";
import {colors} from "../../utils";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";

const CssTextField = withStyles({
  root: {
    "input:-internal-autofill-selected": {
      backgroundColor: "#fff",
    },
    "& label.Mui-focused": {
      color: "#e6b74d",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#BBB",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBB",
      },
      "&:hover fieldset": {
        borderColor: "#e6b74d",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e6b74d",
      },
    },
  },
})(TextField);

const styles = (theme) => ({
  container: {},
});

class ClientsBulkEmail extends React.Component {
  state = {
    clients: [],
    currentPage: 1,
    clientsPerPage: 10,
    allClients: false,
    body: "",
    subject: "",
    visible: false,
    search: "",
  };

  componentDidMount = async () => {
    var response = await this.props.getRenters();
    response = response.map((obj) => ({...obj, isSelected: false}));
    this.setState({
      clients: response,
    });
  };

  paginate = async (pageNumber) => {
    this.setState({
      currentPage: pageNumber,
    });
  };

  clientCheckBoxHandler = (event) => {
    const value = event.target.checked;
    const name = event.target.name;
    this.setState((prevState) => ({
      ...prevState,
      clients: prevState.clients.map((obj) =>
        obj.id == name ? {...obj, isSelected: value} : obj,
      ),
    }));
  };

  allClientCheckBoxHandler = (event) => {
    const value = event.target.checked;
    this.setState((prevState) => ({
      ...prevState,
      allClients: value,
      clients: prevState.clients.map((obj) => ({...obj, isSelected: value})),
    }));
  };

  bodyHandler = (event) => {
    const value = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      body: value,
    }));
  };

  subjectHandler = (event) => {
    const value = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      subject: value,
    }));
  };

  sendEmailsHandler = async () => {
    var selectedClients = this.state.clients.filter(
      (client) => client.isSelected === true,
    );
    const result = await this.props.sendClientsEmail(
      selectedClients,
      this.state.subject,
      this.state.body,
    );
    if (result == "success") {
      this.onShowAlert();
      this.resetState();
    }
  };

  onShowAlert = () => {
    this.setState({visible: true});
    setTimeout(
      function () {
        this.setState({visible: false});
      }.bind(this),
      3000,
    );
  };

  resetState = () => {
    this.setState((prevState) => ({
      ...prevState,
      clients: prevState.clients.map((obj) => ({...obj, isSelected: false})),
      currentPage: 1,
      allClients: false,
      body: "",
      subject: "",
    }));
  };

  searchTermChangedHandler = (event) => {
    this.setState((prevState) => ({
      ...prevState,
      search: event.target.value,
      currentPage: 1,
    }));
  };

  render() {
    let matchingClients = this.state.clients.filter((client) => {
      if (client.name.toLowerCase().includes(this.state.search.toLowerCase())) {
        return client;
      } else {
        return;
      }
    });
    const indexOfLastClient =
      this.state.currentPage * this.state.clientsPerPage;
    const indexOfFirstClient = indexOfLastClient - this.state.clientsPerPage;
    const currentClients = matchingClients.slice(
      indexOfFirstClient,
      indexOfLastClient,
    );

    let clientsList = [];
    if (currentClients.length > 0) {
      clientsList = currentClients.map((client, index) => (
        <tr key={index}>
          <td style={{textAlign: "center"}}>
            <label className="au-checkbox">
              <input
                name={client.id}
                type="checkbox"
                checked={client.isSelected}
                onChange={this.clientCheckBoxHandler}
              />
              <span className="au-checkmark" />
            </label>
          </td>
          <td>{client.name}</td>
          <td>{client.email}</td>
          <td>{client.phone}</td>
        </tr>
      ));
    }
    let isAnyClientSelected = this.state.clients.filter(
      (client) => client.isSelected === true,
    ).length;

    const {classes} = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          minWidth: 1200,
          padding: 50,
          margin: 0,
          paddingLeft: 216,
        }}>
        <div
          style={{
            textAlign: "center",
            width: "100%",
          }}>
          <div>
            <div className="section__content section__content--p30">
              <div className="container-fluid">
                <div className="row">
                  <label className="au-checkbox" style={{color: "#717171"}}>
                    Email Message
                  </label>
                </div>
                <div
                  className="row"
                  style={{
                    marginBottom: "10px",
                  }}>
                  <CssTextField
                    id="standard-basic"
                    label="Subject"
                    value={this.state.subject}
                    onChange={this.subjectHandler}
                    fullWidth
                    variant="outlined"
                    style={{background: "#fff"}}
                  />
                </div>
                <div className="row">
                  <CssTextField
                    style={{borderColor: "#cd943c"}}
                    id="standard-multiline-static"
                    label="Body"
                    multiline
                    rows="8"
                    value={this.state.body}
                    onChange={this.bodyHandler}
                    fullWidth
                    variant="outlined"
                  />
                </div>
                <div className="row">
                  <Alert
                    isOpen={this.state.visible}
                    color="success"
                    style={{
                      zIndex: "100",
                      position: "fixed",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "15px",
                      width: "300px",
                      height: "50px",
                      textAlign: "center",
                    }}>
                    Email Sent Successfully!
                  </Alert>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive table-responsive-data2 remove-scrollbar">
                      <div className="tr-shadow">
                        <div className="row m-t-30">
                          <div className="col-md-12">
                            <div className="table-responsive m-b-40"></div>
                          </div>
                        </div>
                        <table className="table table-borderless table-data3">
                          <thead>
                            <tr>
                              <th>
                                <label
                                  className="au-checkbox"
                                  style={{
                                    marginLeft: "25px",
                                    marginBottom: "20px",
                                  }}>
                                  <input
                                    name="allClients"
                                    type="checkbox"
                                    checked={this.state.allClients}
                                    onChange={this.allClientCheckBoxHandler}
                                  />
                                  <span className="au-checkmark" />
                                </label>
                              </th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Phone</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td></td>
                              <td
                                colSpan={1}
                                style={{
                                  padding: "12px 0px",
                                  textAlign: "center",
                                }}>
                                <div className="rs-select2--light rs-select2--sm">
                                  <input
                                    style={{
                                      padding: "5px 15px",
                                      margin: "3px 0",
                                      border: "1px solid #DCDCDC",
                                      borderRadius: "4px",
                                      width: "100%",
                                    }}
                                    type="text"
                                    placeholder="Search"
                                    className="js-select2"
                                    name="name"
                                    onChange={(event) => {
                                      event.persist();
                                      this.searchTermChangedHandler(event);
                                    }}
                                  />
                                </div>
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                            {clientsList}
                          </tbody>
                        </table>
                        <Pagination
                          postsPerPage={this.state.clientsPerPage}
                          totalPosts={matchingClients.length}
                          paginate={this.paginate}
                          currentPage={this.state.currentPage}
                        />
                      </div>
                    </div>
                    <div className="row user-data__footer">
                      {/* <button className="au-btn au-btn-load">load more</button> */}
                      {/* <div className="col-6"> */}
                      <DefaultButton
                        text="Send Email to Clients"
                        disabled={!isAnyClientSelected}
                        onClick={this.sendEmailsHandler}
                        style={{
                          background: isAnyClientSelected ? null : "#808080",
                          marginBottom: "20px",
                          borderColor: isAnyClientSelected ? null : "#808080",
                          color: isAnyClientSelected ? null : colors.WHITE,
                          margin: "auto",
                        }}
                      />

                      {/* </div> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12"></div>
                  </div>
                </div>
              </div>
              <div className="section__content section__content--p30">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  //isAuthenticated: state.auth.token
});

const mapDispatchToProps = {
  getRenters,
  sendClientsEmail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(ClientsBulkEmail)));
