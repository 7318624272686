import {Container} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import React from "react";
import {withStyles} from "@material-ui/styles";
import {connect} from "react-redux";
import {colors} from "../../utils";
import {addOwner} from "../../store/actions";
import TextInput from "../../components/TextInput/TextInput";
import OkModal from "../../components/OkModal/OkModal";
import CircularProgress from "@material-ui/core/CircularProgress";

class OwnerDetails extends React.Component {
  state = {
    loading: false,
    params: null,
    owner: {
      address: "",
      city: "",
      companyId: "",
      companyIdNumber: "",
      email: "",
      id: "",
      language: "",
      legalName: "",
      mobileNumber: "",
      name: "",
      phone: "",
      refNames: "",
      website: "",
      destination: "",
    },
    showNameRequiredMessage: false,
    showEmailRequiredMessage: false,
    showModal: false,
    modalMessage: "",
    modalHeader: "",
  };

  componentDidMount = async () => {};

  startLoading = () => {
    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
  };

  stopLoading = () => {
    this.setState((prevState) => ({
      ...prevState,
      loading: false,
    }));
  };

  setShowEmailRequiredMessage = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      showEmailRequiredMessage: value,
    }));
  };

  setShowNameRequiredMessage = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      showNameRequiredMessage: value,
    }));
  };

  setShowModal = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      showModal: value,
    }));
  };

  clearOwnerInfo = () => {
    this.setState((prevState) => ({
      ...prevState,
      owner: {
        address: "",
        city: "",
        companyId: "",
        companyIdNumber: "",
        email: "",
        id: "",
        language: "",
        legalName: "",
        mobileNumber: "",
        name: "",
        phone: "",
        refNames: "",
        website: "",
        destination: "",
      },
    }));
  };

  setModalHeader = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      modalHeader: value,
    }));
  };

  setModalMessage = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      modalMessage: value,
    }));
  };
  addHandler = async () => {
    if (this.state.owner.name !== "" && this.state.owner.email !== "") {
      this.startLoading();
      const response = await this.props.addOwner(this.state.owner);
      if (response === "failed") {
        this.setModalHeader("Error");
        this.setModalMessage(
          "Something went wrong while adding the owner. Please try again later.",
        );
      } else {
        this.setModalHeader("Added Successfully");
        this.setModalMessage("The new owner has been added successfully.");
        this.clearOwnerInfo();
      }
      this.setShowModal(true);
      this.stopLoading();
      this.setShowNameRequiredMessage(false);
      this.setShowEmailRequiredMessage(false);
    } else {
      if (this.state.owner.name === "") {
        this.setShowNameRequiredMessage(true);
      } else {
        this.setShowNameRequiredMessage(false);
      }
      if (this.state.owner.email === "") {
        this.setShowEmailRequiredMessage(true);
      } else {
        this.setShowEmailRequiredMessage(false);
      }
    }
  };

  setOwnerProperty = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      owner: {
        ...prevState.owner,
        [name]: value,
      },
    }));
  };
  closeDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      showModal: false,
    }));
  };

  render() {
    const {classes} = this.props;
    return (
      <Container className={classes.root}>
        <OkModal
          visible={this.state.showModal}
          onClose={this.closeDialog}
          title={this.state.modalHeader}
          text={this.state.modalMessage}
          onAccept={this.closeDialog}
        />
        <div>
          <div className={classes.topSection}>
            <div className={classes.avatarContainer}>
              <div className={classes.image} />
              <h5 className={classes.name}>
                <span>{this.state.owner.name}</span>
              </h5>
            </div>
            <div className={classes.infoContainer}>
              <div>
                <div className={classes.header}>About</div>
                <div
                  style={{
                    display: "flex",
                  }}
                  className={classes.editInfoContainer}>
                  <div className={classes.inputRow}>
                    <TextInput
                      id="name"
                      type="text"
                      label="Name"
                      value={this.state.owner.name}
                      onChange={(event) =>
                        this.setOwnerProperty("name", event.target.value)
                      }
                      fontSize="1rem"
                      error={this.state.showNameRequiredMessage}
                      helperText={"* Enter the name"}
                      required={true}
                    />
                    <TextInput
                      width="20rem"
                      id="email"
                      type="email"
                      label="Email"
                      value={this.state.owner.email}
                      onChange={(event) =>
                        this.setOwnerProperty("email", event.target.value)
                      }
                      fontSize="1rem"
                      error={this.state.showEmailRequiredMessage}
                      helperText={"* Enter the email"}
                      required={true}
                    />
                    <TextInput
                      //width="150%"
                      id="phone"
                      type="text"
                      label="Phone"
                      value={this.state.owner.phone}
                      onChange={(event) =>
                        this.setOwnerProperty("phone", event.target.value)
                      }
                      fontSize="1rem"
                    />
                  </div>
                  <div className={classes.inputRow}>
                    <TextInput
                      //width="150%"
                      id="leganName"
                      type="text"
                      label="Legal Name"
                      value={this.state.owner.legalName}
                      onChange={(event) =>
                        this.setOwnerProperty("legalName", event.target.value)
                      }
                      fontSize="1rem"
                    />
                    <TextInput
                      //width="150%"
                      id="city"
                      type="text"
                      label="City"
                      value={this.state.owner.city}
                      onChange={(event) =>
                        this.setOwnerProperty("city", event.target.value)
                      }
                      fontSize="1rem"
                    />
                    <TextInput
                      //width="150%"
                      id="address"
                      type="text"
                      label="Address"
                      value={this.state.owner.address}
                      onChange={(event) =>
                        this.setOwnerProperty("address", event.target.value)
                      }
                      fontSize="1rem"
                    />
                  </div>
                  <div className={classes.inputRow}>
                    <TextInput
                      //width="150%"
                      id="companyIdNumber"
                      type="text"
                      label="Company ID Number"
                      value={this.state.owner.companyIdNumber}
                      onChange={(event) =>
                        this.setOwnerProperty(
                          "companyIdNumber",
                          event.target.value,
                        )
                      }
                      fontSize="1rem"
                    />
                    <TextInput
                      //width="150%"
                      id="language"
                      type="text"
                      label="Language"
                      value={this.state.owner.language}
                      onChange={(event) =>
                        this.setOwnerProperty("language", event.target.value)
                      }
                      fontSize="1rem"
                    />
                    <TextInput
                      //width="150%"
                      id="website"
                      type="text"
                      label="Website"
                      value={this.state.owner.website}
                      onChange={(event) =>
                        this.setOwnerProperty("website", event.target.value)
                      }
                      fontSize="1rem"
                    />
                  </div>
                  <div className={classes.inputRow}>
                    <TextInput
                      //width="150%"
                      id="destination"
                      type="text"
                      label="Destination"
                      value={this.state.owner.destination}
                      onChange={(event) =>
                        this.setOwnerProperty("destination", event.target.value)
                      }
                      fontSize="1rem"
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                  justifyContent: "space-between",
                }}>
                {this.state.loading ? (
                  <CircularProgress style={{color: "#DCAC38"}} />
                ) : (
                  <button
                    style={{
                      display: "block",
                    }}
                    className={classes.add}
                    onClick={this.addHandler}>
                    {"Add"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}
const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    marginLeft: "11rem",
    minWidth: "1200px",
    paddingTop: 50,
  },
  topSection: {
    display: "flex",
  },
  circularProgress: {
    color: colors.PRIMARY,
    position: "absolute",
    left: "50%",
    top: "50%",
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "1rem",
  },
  image: {
    width: "12rem",
    height: "12rem",
    backgroundImage: `linear-gradient(rgba(203,170,92,0.3), rgba(0,0,0,0)),url("https://berealibiza-properties-images.s3.eu-central-1.amazonaws.com/Logos/avatar.jpg")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    transition: "all 0.4s",
  },
  name: {
    color: colors.BLACK,
    textTransform: "uppercase",
    letterSpacing: "2px",
    fontSize: "1rem",
    fontWeight: "500",
    boxSizing: "content-box",
    width: "12rem",
    textAlign: "center",
    marginTop: "1.5rem",
    zIndex: 1000,
  },
  infoContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "59rem",
    height: "18rem",
    marginLeft: "2rem",
    padding: "1.5rem",
    border: "1px solid #DEDFDF",
    boxShadow: "0px 0px 2px #d7d7d7",
    borderRadius: "2px",
    boxSizing: "border-box",
    letterSpacing: "1px",
    color: colors.DARK_LABEL_GREY,
    fontSize: "0.9rem",
  },
  editInfoContainer: {
    height: "10rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  inputRow: {
    display: "flex",
    width: "50rem",
    justifyContent: "space-between",
  },
  header: {
    fontSize: "1.2rem",
    fontWeight: "500",
    color: colors.DARK_LABEL_GREY,
    marginBottom: "2rem",
    alignSelf: "flex-start",
    letterSpacing: "1px",
  },
  edit: {
    color: colors.PRIMARY,
    backgroundColor: "transparent",
    marginBottom: 0,
    marginRight: "1rem",
    cursor: "pointer",
    textTransform: "uppercase",
    letterSpacing: "1px",
    fontSize: "1rem",
    "&:focus": {
      outline: "none",
    },
  },
  info: {
    display: "flex",
    flexDirection: "column",
  },
  bold: {
    fontWeight: "500",
  },
  proeprtiesContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "73rem",
    margin: "1rem",
    marginTop: "2rem",
    padding: "2rem",
    border: "1px solid #DEDFDF",
    boxShadow: "0px 0px 2px #d7d7d7",
    borderRadius: "2px",
    boxSizing: "border-box",
  },
  websiteLink: {
    color: colors.PRIMARY,
    transition: "all 1s",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
      color: colors.PRIMARY,
    },
    "&:focus": {
      backgroundColor: "transparent",
      textDecoration: "underline",
      color: colors.PRIMARY,
    },
  },
  add: {
    textTransform: "uppercase",
    padding: "5px 20px 5px 20px",
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    borderRadius: "3px",
    "&:focus": {
      outline: "none",
    },
  },
});

const mapStateToProps = (state) => ({
  //   isAuthenticated: state.auth.token
});

const mapDispatchToProps = {
  addOwner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(OwnerDetails)));
