import {withStyles} from "@material-ui/styles";
import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Alert} from "reactstrap";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";
import Dropdown from "../../components/Dropdown/Dropdown";
import PropertiesList from "../../components/PropertiesList/PropertiesList";
import RequestInfo from "../../components/RequestInfo/RequestInfo";
import RequestInfoTable from "../../components/RequestInfoTable/RequestInfoTable";
import api from "../../services/api";
import {
  approveProperties,
  getProperties,
  getRequests,
  sendProperties,
} from "../../store/actions";
import {colors} from "../../utils";
import YesNoDialog from "../../components/YesNoDialog/YesNoDialog";
const queryString = require("query-string");

const styles = (theme) => ({
  container: {},
});

class Requests extends React.Component {
  state = {
    requests: [],
    selectedRequest: {
      approvePayment: "-",
      approveContract: "-",
      status: "",
      id: "",
    },
    properties: [],
    currentPage: 1,
    propertiesPerPage: 10,
    isAlertVisible: false,
    showDeleteDialog: false,
    deleteRequestId: 0,
  };

  showDeleteDialog = (params) => {
    this.setState((prevState) => ({
      ...prevState,
      deleteRequestId: params.row.id,
      showDeleteDialog: true,
    }));
  };

  closeDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDeleteDialog: false,
    }));
  };

  componentDidMount = async () => {
    let response = await this.props.getRequests();
    console.log(response);
    response = response.map((request) => {
      return {
        ...request,
        interestedServices: request.interestedServices
          ? request.interestedServices.split("|")
          : [],
      };
    });
    let params = queryString.parse(this.props.location.search);
    let requestId = "";
    if (params.id != null) {
      requestId = Buffer.from(params.id, "base64").toString();
      var selectedRequest = response.find((request) => request.id == requestId);
      this.setState((prevState) => ({
        ...prevState,
        selectedRequest,
      }));
      let requestedProperties = await this.props.getProperties(
        requestId,
        selectedRequest.from,
        selectedRequest.to,
      );
      requestedProperties = requestedProperties.map((obj) => ({
        ...obj,
        isSelected: false,
      }));
      this.setState((prevState) => ({
        ...prevState,
        properties: requestedProperties,
        currentPage: 1,
      }));
    }
    this.setState({
      requests: response,
    });
  };

  selectRequestHandler = async (event) => {
    const selectedId = parseInt(event.selectionModel[0]);
    var selectedRequest = this.state.requests.find(
      (request) => request.id === selectedId,
    );
    this.setState((prevState) => ({
      ...prevState,
      selectedRequest,
    }));
    let requestedProperties = await this.props.getProperties(
      selectedId,
      selectedRequest?.from,
      selectedRequest?.to,
    );
    requestedProperties = requestedProperties.map((property) => ({
      ...property,
      isSelected: false,
    }));
    requestedProperties = requestedProperties.filter(
      (property) => (property.active === "Yes" || property.active === "PDF"),
    );
    this.setState((prevState) => ({
      ...prevState,
      properties: requestedProperties,
      currentPage: 1,
    }));
  };

  dataHandler = (field, params, fieldValue) => {
    let requests = this.state.requests;
    requests = requests.map((request) => {
      if (request.id === params.row.id)
        return {...request, [field]: fieldValue, edited: true};
      else return request;
    });
    this.setState((prevState) => ({
      ...prevState,
      requests: requests,
    }));
  };

  deleteRequest = async () => {
    let requests = this.state.requests;
    requests = requests.filter(
      (request) => request.id !== this.state.deleteRequestId,
    );

    const response = await api.deleteRenterRequest(this.state.deleteRequestId);

    this.setState((prevState) => ({
      ...prevState,
      requests: requests,
      showDeleteDialog: false,
    }));
  };

  paginate = async (pageNumber) => {
    this.setState({
      currentPage: pageNumber,
    });
  };

  propertyCheckBoxHandler = (event) => {
    const value = event.target.checked;
    const name = event.target.name;
    this.setState((prevState) => ({
      ...prevState,
      properties: prevState.properties.map((obj) =>
        obj.id == name ? {...obj, isSelected: value} : obj,
      ),
    }));
  };

  sendPropertiesHandler = async () => {
    if (this.state.properties.length > 0) {
      var selectedProperties = this.state.properties.filter(
        (property) => property.isSelected === true,
      );
      selectedProperties = selectedProperties.map((obj) => obj.id);

      const info = {
        propertyIds: selectedProperties,
        requestId: this.state.selectedRequest.id,
      };
      let requestedProperties = await this.props.sendProperties(info);
      let properties = await this.props.getProperties(
        this.state.selectedRequest.id,
        this.state.selectedRequest.from,
        this.state.selectedRequest.to,
      );
      properties = properties.map((obj) => ({...obj, isSelected: false}));
      this.setState((prevState) => ({
        ...prevState,
        properties: properties,
        currentPage: 1,
      }));
      //////////////////UPDATE REQUESTS IN STATE///////////////////////////
      var updatedRequests = this.state.requests.map((request) =>
        request.id === this.state.selectedRequest.id
          ? {...request, status: "Pending Client Choice"}
          : request,
      );
      var updatedSelectedRequest = {
        ...this.state.selectedRequest,
        status: "Pending Client Choice",
      };
      this.setState((prevState) => ({
        ...prevState,
        selectedRequest: updatedSelectedRequest,
        requests: updatedRequests,
      }));
      ///////////////////////////////////////////////////////////////////
    }
  };
  approvePropertiesHandler = async () => {
    if (this.state.properties.length > 0) {
      var selectedProperties = this.state.properties.filter(
        (property) => property.isSelected === true,
      );
      selectedProperties = selectedProperties.map((obj) => obj.id);

      const info = {
        propertyIds: selectedProperties,
        requestId: this.state.selectedRequest.id,
      };
      let requestedProperties = await this.props.approveProperties(info);
      //////////////////UPDATE PROPERTIES IN STATE///////////////////////////
      let properties = this.state.properties.map((property) => {
        property.isSelected === true
          ? (property.clientChoice = "Approved by admin")
          : (property.clientChoice = property.clientChoice);
        property.isSelected = false;
        return property;
      });
      this.setState((prevState) => ({
        ...prevState,
        properties: properties,
        currentPage: 1,
      }));
      /////////////////////////////////////////////////////////////////////
      //////////////////UPDATE REQUESTS IN STATE///////////////////////////
      var updatedRequests = this.state.requests.map((request) =>
        request.id === this.state.selectedRequest.id
          ? {...request, status: "Pending First Payment & Contract"}
          : request,
      );
      var updatedSelectedRequest = {
        ...this.state.selectedRequest,
        status: "Pending First Payment & Contract",
      };
      this.setState((prevState) => ({
        ...prevState,
        selectedRequest: updatedSelectedRequest,
        requests: updatedRequests,
      }));
      /////////////////////////////////////////////////////////////////////
    }
  };

  saveRequestInfo = async (event) => {
    let requests = [];
    let renters = [];
    let data = {};
    this.state.requests.forEach((request) => {
      if (request.edited) {
        requests.push([
          request.id,
          request.status,
          request.propertyType,
          request.noOfPeople,
          request.noOfBedrooms,
          request.from,
          request.to,
          request.budget,
          request.paymentMethod,
          request.notes,
        ]);
        renters.push([
          request.renterId,
          request.name,
          request.phone,
          request.email,
          request.visitedBefore,
          request.preferredLocation,
        ]);
      }
    });
    data.requests = requests;
    data.renters = renters;
    console.log(data);
    const response = await api.saveRequestInfo(data);

    if (response.data.status == "success") {
      this.onShowAlert();
    }
  };

  resetCurrentPageHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
  };

  onShowAlert = () => {
    this.setState({isAlertVisible: true});
    setTimeout(
      function () {
        this.setState({isAlertVisible: false});
      }.bind(this),
      3000,
    );
  };

  propertyDetails = (id, page) => {
    this.props.history.push({
      pathname:
        "editproperty?id=" +
        Buffer.from(id).toString("base64") +
        "&page=" +
        Buffer.from(page).toString("base64"),
      state: {propertyIds: [Number(id)]},
    });
    this.props.history.go(0);
  };

  render() {
    let isAnyPropertySelected = this.state.properties.filter(
      (property) => property.isSelected === true,
    ).length;
    let propertiesPending = this.state.properties.some(
      (property) => property.clientChoice === "Pending",
    );
    const {classes} = this.props;

    // return !this.props.isAuthenticated ? (
    //   <Redirect to="/login" />
    // ) : (
    return (
      <div style={{minWidth: "1480px", paddingLeft: "7rem"}}>
        <YesNoDialog
          visible={this.state.showDeleteDialog}
          onClose={this.closeDialog}
          title="Are You sure?"
          text="Are you sure you want to delete this request?"
          onAccept={this.deleteRequest}
          onReject={this.closeDialog}
        />
        <div style={{paddingLeft: "120px", paddingTop: "50px"}}>
          <div className="row">
            <div className="col-md-12">
              <h3 className="title-5">Detailed Requests</h3>
              <div className="table-responsive table-responsive-data2 remove-scrollbar">
                <div className="tr-shadow">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 20,
                      paddingLeft: 0,
                    }}>
                    <RequestInfoTable
                      requests={this.state.requests}
                      selectRequestHandler={this.selectRequestHandler}
                      dataHandler={this.dataHandler}
                      deleteDialog={this.showDeleteDialog}
                    />
                    <Alert
                      isOpen={this.state.isAlertVisible}
                      color="success"
                      style={{
                        position: "absolute",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "15px",
                        width: "300px",
                        height: "50px",
                        textAlign: "center",
                      }}>
                      Info Updated Successfully!
                    </Alert>
                    <DefaultButton
                      text="Save Info"
                      onClick={this.saveRequestInfo}
                      style={{
                        marginBottom: "20px",
                      }}
                    />
                    <PropertiesList
                      page={"requests"}
                      properties={this.state.properties}
                      propertiesPerPage={this.state.propertiesPerPage}
                      propertyCheckBoxHandler={this.propertyCheckBoxHandler}
                      currentPage={this.state.currentPage}
                      paginate={this.paginate}
                      resetCurrentPage={this.resetCurrentPageHandler}
                      propertyDetails={this.propertyDetails}
                    />
                  </div>
                </div>
              </div>
              <div className="row user-data__footer">
                {/* <button className="au-btn au-btn-load">load more</button> */}
                <div className="col-6">
                  <DefaultButton
                    text="Send Properties to Client"
                    disabled={!(isAnyPropertySelected && !propertiesPending)}
                    onClick={this.sendPropertiesHandler}
                    style={{
                      background:
                        isAnyPropertySelected && !propertiesPending
                          ? null
                          : "#808080",
                      marginBottom: "20px",
                      borderColor:
                        isAnyPropertySelected && !propertiesPending
                          ? null
                          : "#808080",
                      color:
                        isAnyPropertySelected && !propertiesPending
                          ? null
                          : colors.WHITE,
                    }}
                  />
                </div>
                <div className="col-6">
                  <DefaultButton
                    text="Approve Properties to Client"
                    disabled={!isAnyPropertySelected}
                    onClick={this.approvePropertiesHandler}
                    style={{
                      background: isAnyPropertySelected ? null : "#808080",
                      marginBottom: "20px",
                      borderColor: isAnyPropertySelected ? null : "#808080",
                      color: isAnyPropertySelected ? null : colors.WHITE,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.token,
  user: state.auth.user,
});

const mapDispatchToProps = {
  getRequests,
  getProperties,
  sendProperties,
  approveProperties,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(Requests)));
