import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {colors, incr_date, getFirstWeekDayDate, isValidDate} from '../../utils';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {withStyles} from '@material-ui/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import TextInput from '../../components/TextInput/TextInput';
import CircularProgress from '@material-ui/core/CircularProgress';
import './main.scss'; // webpack must be configured to do this
import {Alert} from 'reactstrap';
import {asRoughSeconds} from '@fullcalendar/core';
import {
  getCalendarEvents,
  updateAvailabiliyEvents,
  updatePriceEvents,
  getStartingSeason,
} from '../../store/actions';
import TextField from '@material-ui/core/TextField';
import DefaultButton from '../../components/Buttons/DefaultButton/DefaultButton';
const queryString = require('query-string');

const styles = (theme) => ({
  circularProgress: {
    color: colors.PRIMARY,
    position: 'absolute',
    left: '50%',
    top: '50%',
  },
  buttonLoading: {
    color: colors.PRIMARY,
  },
  textField: {
    width: 400,
    '& label.Mui-focused': {
      color: colors.PRIMARY,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.PRIMARY,
    },
  },
});

const PrimaryCheckbox = withStyles({
  root: {
    color: colors.PRIMARY,
    '&$checked': {
      color: colors.PRIMARY,
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);

const PrimaryRadio = withStyles({
  root: {
    color: colors.PRIMARY,
    '&$checked': {
      color: colors.PRIMARY,
    },
  },
  checked: {},
})((props) => <Radio color='default' {...props} />);

const SecondaryRadio = withStyles({
  root: {
    color: colors.DISABLED_GREY,
    '&$checked': {
      color: colors.DISABLED_GREY,
    },
  },
  checked: {},
})((props) => <Radio color='default' {...props} />);

class PropertyCalendar extends React.Component {
  state = {
    events: null,
    open: false,
    availability: 'available',
    price: '',
    startDate: '',
    endDate: '',
    textInputEndDate: '',
    loading: false,
    weeksLoading: false,
    propertyId: '',
    startDateError: '',
    endDateError: '',
    isAlertVisible: false,
    propertyPage: '',
    week1: {
      number: 1,
      startDate: '',
      price: '',
      availability: 'available',
    },
    week2: {
      number: 2,
      startDate: '',
      price: '',
      availability: 'available',
    },
    week3: {
      number: 3,
      startDate: '',
      price: '',
      availability: 'available',
    },
    week4: {
      number: 4,
      startDate: '',
      price: '',
      availability: 'available',
    },
    startingSeason: '',
    currentMonth: '',
  };

  nextMonth = (info) => {
    if (this.state.startingSeason != '') {
      console.log(this.state);
      let currentDate = info.view.currentEnd.toISOString().split('T')[0];
      let currentMonth = parseInt(currentDate.split('-')[1]);
      this.setWeeksNumbers(currentMonth, this.state.startingSeason);
      this.setState((prevState) => ({
        ...prevState,
        currentMonth: currentMonth,
      }));
    }
  };

  getDateFormat(date) {
    return date.toISOString().split('T')[0];
  }

  setWeeksNumbers(m, startingDate) {
    // if (m < 5) m = 5;
    let startingMonth = parseInt(startingDate.split('-')[1]);
    let month = m - startingMonth;
    let d = new Date(startingDate);
    let week1StartDate = new Date(d.setDate(d.getDate() + month * 4 * 7));
    let week1EndDate = new Date(d.setDate(d.getDate() + 7));
    d = new Date(startingDate);
    let week2StartDate = new Date(d.setDate(d.getDate() + (month * 4 * 7 + 7)));
    let week2EndDate = new Date(d.setDate(d.getDate() + 7));
    d = new Date(startingDate);
    let week3StartDate = new Date(
      d.setDate(d.getDate() + (month * 4 * 7 + 14)),
    );
    let week3EndDate = new Date(d.setDate(d.getDate() + 7));
    d = new Date(startingDate);
    let week4StartDate = new Date(
      d.setDate(d.getDate() + (month * 4 * 7 + 21)),
    );
    let week4EndDate = new Date(d.setDate(d.getDate() + 7));

    this.setState((prevState) => ({
      ...prevState,
      week1: {
        ...prevState.week1,
        number: month * 4 + 1,
        startDate: this.getDateFormat(week1StartDate),
        endDate: this.getDateFormat(week1EndDate),
      },
      week2: {
        ...prevState.week2,
        number: month * 4 + 2,
        startDate: this.getDateFormat(week2StartDate),
        endDate: this.getDateFormat(week2EndDate),
      },
      week3: {
        ...prevState.week3,
        number: month * 4 + 3,
        startDate: this.getDateFormat(week3StartDate),
        endDate: this.getDateFormat(week3EndDate),
      },
      week4: {
        ...prevState.week4,
        number: month * 4 + 4,
        startDate: this.getDateFormat(week4StartDate),
        endDate: this.getDateFormat(week4EndDate),
      },
    }));
    return;
  }
  componentDidMount = async () => {
    let role = localStorage.getItem('authType');
    let propertyIds = this.props.location.state.propertyIds;
    //let currentDate = this.getCalendarDate();
    var currentDate = new Date().toISOString().slice(0, 10);
    var startingSeason = await this.props.getStartingSeason();
    let startSeasonDate = '';
    if (
      currentDate.split('-')[0] == startingSeason.startingSeason.split('-')[0]
    )
      startSeasonDate = startingSeason.startingSeason;
    else {
      //weekDay -> Sunday = 0 month -> Jan = 0
      //First Saturday in May in CurrentYear
      startSeasonDate = getFirstWeekDayDate(6, 4, currentDate.split('-')[0]);
    }
    console.log(startSeasonDate);
    let currentMonth = parseInt(currentDate.split('-')[1]);
    this.setWeeksNumbers(currentMonth, startSeasonDate);

    let params = queryString.parse(this.props.location.search);
    let propertyId = Buffer.from(params.id, 'base64').toString();
    let propertyPage = Buffer.from(params.page, 'base64').toString();
    await this.setState((prevState) => ({
      ...prevState,
      propertyId,
      propertyPage,
      role,
      startingSeason: startSeasonDate,
      currentMonth: currentMonth,
      propertyIds,
    }));

    const response = await this.props.getCalendarEvents(propertyId);
    let unavailabilityEvents = response.data.unavailability.map((event) => {
      return {
        ...event,
        title: 'Unavailable',
        backgroundColor: colors.DISABLED_GREY,
        borderColor: colors.DISABLED_GREY,
        description: 'Unavailable',
        textColor: colors.WHITE,
      };
    });
    let priceEvents = response.data.prices.map((event) => {
      return {
        ...event,
        title: '€' + event.price,
        backgroundColor: colors.PRIMARY,
        borderColor: colors.PRIMARY,
        description: 'Price',
        textColor: colors.WHITE,
      };
    });
    this.onShowStartAlert();
    this.setState((prevState) => ({
      ...prevState,
      events: [...unavailabilityEvents, ...priceEvents],
    }));
    console.log(this.state);
  };

  onShowAlert = () => {
    this.setState({isAlertVisible: true});
    setTimeout(
      function () {
        this.setState({isAlertVisible: false});
      }.bind(this),
      10000,
    );
  };

  onShowStartAlert = () => {
    this.setState({isAlertStartVisible: true});
    setTimeout(
      function () {
        this.setState({isAlertStartVisible: false});
      }.bind(this),
      10000,
    );
  };

  handleDateClick = (arg) => {
    if (this.state.startDate == '') {
      this.setState((prevState) => ({
        ...prevState,
        startDate: arg.dateStr,
        isAlertStartVisible: false,
      }));
      this.onShowAlert();
    } else {
      this.setState((prevState) => ({
        ...prevState,
        isAlertVisible: false,
        open: true,
        endDate: incr_date(arg.dateStr),
        textInputEndDate: arg.dateStr,
      }));
    }
  };

  handleClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      open: false,
      isAlertVisible: false,
    }));

    setTimeout(
      this.setState((prevState) => ({
        ...prevState,
        availability: 'available',
        price: '',
        loading: false,
        startDate: '',
        endDate: '',
        textInputEndDate: '',
      })),
      500,
    );
  };

  handleAvailabilityCheckBox = (event) => {
    this.setState({
      availability: event.target.value,
    });
  };

  setDate = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  setWeek = (name, field, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        [field]: value,
      },
    }));
  };

  setEndDate = (name, value) => {
    if (value.length == 10) {
      this.setState((prevState) => ({
        ...prevState,
        [name]: value,
        endDate: incr_date(value),
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  setPrice = (event) => {
    this.setState({price: event.target.value});
  };

  dateValidErrorHandler = (dateName, error) => {
    this.setState((prevState) => ({
      ...prevState,
      [dateName]: error,
    }));
  };

  isDateValidHandler = () => {
    let isStartDateValid = isValidDate(this.state.startDate);
    let isEndDateValid = isValidDate(this.state.endDate);
    this.dateValidErrorHandler(
      'startDateError',
      isStartDateValid ? '' : 'Invalid date',
    );
    this.dateValidErrorHandler(
      'endDateError',
      isEndDateValid ? '' : 'Invalid date',
    );
    return isStartDateValid && isEndDateValid;
  };
  formatStartToEnd = (startDate, endDate) => {
    let start = startDate.split('-')[1] + '/' + startDate.split('-')[2];
    let end = endDate.split('-')[1] + '/' + endDate.split('-')[2];
    return start + ' - ' + end;
  };

  setCalendarWeek = async (startDate, endDate, price, availability) => {
    await this.setState((prevState) => ({
      ...prevState,
      startDate: startDate,
      endDate: endDate,
      price: price,
      availability: availability,
    }));
    await this.savePriceAvailability();
  };

  saveWeeklyPriceAvailability = async () => {
    await this.setState((prevState) => ({
      ...prevState,
      weeksLoading: true,
    }));
    if (
      this.state.week1.price != '' ||
      this.state.week1.availability != 'available'
    ) {
      await this.setCalendarWeek(
        this.state.week1.startDate,
        this.state.week1.endDate,
        this.state.week1.price,
        this.state.week1.availability,
      );
    }
    if (
      this.state.week2.price != '' ||
      this.state.week2.availability != 'available'
    ) {
      await this.setCalendarWeek(
        this.state.week2.startDate,
        this.state.week2.endDate,
        this.state.week2.price,
        this.state.week2.availability,
      );
    }
    if (
      this.state.week3.price != '' ||
      this.state.week3.availability != 'available'
    ) {
      await this.setCalendarWeek(
        this.state.week3.startDate,
        this.state.week3.endDate,
        this.state.week3.price,
        this.state.week3.availability,
      );
    }
    if (
      this.state.week4.price != '' ||
      this.state.week4.availability != 'available'
    ) {
      await this.setCalendarWeek(
        this.state.week4.startDate,
        this.state.week4.endDate,
        this.state.week4.price,
        this.state.week4.availability,
      );
    }
    this.setState((prevState) => ({
      ...prevState,
      weeksLoading: false,
    }));
  };

  savePriceAvailability = async () => {
    console.log(this.state);
    let datesValid = this.isDateValidHandler();
    if (!datesValid) return;
    if (this.state.startDate >= this.state.endDate) {
      this.dateValidErrorHandler('startDateError', 'Invalid date');
      this.dateValidErrorHandler('endDateError', 'Invalid date');
      return;
    }
    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    if (this.state.availability == 'unavailable') {
      console.log(this.state.events);
      let eventFound = [];
      let newEvent = {start: this.state.startDate, end: this.state.endDate};
      this.state.events.forEach((old) => {
        if (
          ((newEvent.start >= old.start && newEvent.start <= old.end) ||
            (newEvent.end >= old.start && newEvent.end <= old.end)) &&
          old.description == 'Unavailable'
        ) {
          if (newEvent.start > old.start)
            newEvent = {...newEvent, start: old.start};
          if (newEvent.end < old.end) newEvent = {...newEvent, end: old.end};
          eventFound.push(old.eventId);
        } else if (
          old.start >= newEvent.start &&
          newEvent.end >= old.end &&
          old.description == 'Unavailable'
        ) {
          eventFound.push(old.eventId);
        }
      });
      let eventInfo = {...newEvent, propertyId: this.state.propertyId};
      let updateAvailabiliy = {
        eventIds: eventFound,
        eventsUpdate: [],
        eventInfo: eventInfo,
      };
      const response = await this.props.updateAvailabiliyEvents(
        updateAvailabiliy,
      );
      let events = this.state.events.filter((event) => {
        if (!eventFound.includes(event.eventId) || event.description == 'Price')
          return event;
      });
      events = [
        ...events,
        {
          ...newEvent,
          eventId: response.eventId,
          title: 'Unavailable',
          backgroundColor: colors.DISABLED_GREY,
          borderColor: colors.DISABLED_GREY,
          description: 'Unavailable',
          textColor: colors.WHITE,
        },
      ];
      console.log(events);
      this.setState((prevState) => ({
        ...prevState,
        events: events,
      }));
    } else if (this.state.availability == 'available') {
      let eventFound = [];
      let eventUpdateFound = [];
      let newEvent = {start: this.state.startDate, end: this.state.endDate};
      let newEventFlag = false;
      this.state.events.forEach((old) => {
        if (
          newEvent.start > old.start &&
          newEvent.start < old.end &&
          newEvent.end > old.start &&
          newEvent.end < old.end &&
          old.description == 'Unavailable'
        ) {
          eventUpdateFound.push({...old, end: newEvent.start});
          newEvent = {...newEvent, start: newEvent.end, end: old.end};
          newEventFlag = true;
        } else if (
          old.start >= newEvent.start &&
          newEvent.end >= old.end &&
          old.description == 'Unavailable'
        ) {
          eventFound.push(old.eventId);
        } else if (
          ((newEvent.start >= old.start && newEvent.start < old.end) ||
            (newEvent.end > old.start && newEvent.end <= old.end)) &&
          old.description == 'Unavailable'
        ) {
          if (newEvent.start > old.start)
            eventUpdateFound.push({...old, end: newEvent.start});
          else if (newEvent.end < old.end)
            eventUpdateFound.push({...old, start: newEvent.end});
        }
      });
      console.log('eventUpdateFound');
      console.log(eventUpdateFound);
      console.log(eventFound);
      if (eventUpdateFound.length > 0 || eventFound.length > 0) {
        let eventsUpdate = eventUpdateFound.map((event) => {
          return [event.eventId, event.start, event.end];
        });
        let updateAvailabiliy = {
          eventIds: eventFound,
          eventsUpdate: eventsUpdate,
          eventInfo: newEventFlag
            ? {...newEvent, propertyId: this.state.propertyId}
            : {},
        };
        const response = await this.props.updateAvailabiliyEvents(
          updateAvailabiliy,
        );
        let events = this.state.events.filter((event) => {
          if (
            !eventFound.includes(event.eventId) ||
            event.description == 'Price'
          )
            return event;
        });
        if (newEventFlag)
          events = [
            ...events,
            {
              ...newEvent,
              eventId: response.eventId,
              title: 'Unavailable',
              backgroundColor: colors.DISABLED_GREY,
              borderColor: colors.DISABLED_GREY,
              description: 'Unavailable',
              textColor: colors.WHITE,
            },
          ];
        events = events.map((event) => {
          let updatedEvent = eventUpdateFound.find(
            (x) =>
              x.eventId === event.eventId && x.description == event.description,
          );
          if (updatedEvent) {
            return updatedEvent;
          } else return event;
        });
        console.log(events);
        this.setState((prevState) => ({
          ...prevState,
          events: events,
        }));
      }
    }

    if (this.state.price != '') {
      await this.savePrice();
    }

    this.setState((prevState) => ({
      ...prevState,
      availability: 'available',
      loading: false,
      price: '',
      startDate: '',
      endDate: '',
      textInputEndDate: '',
      open: false,
    }));
  };

  savePrice = async () => {
    console.log(this.state.events);
    let priceEventFound = [];
    let priceEventUpdate = [];
    let newPriceEvent = {
      start: this.state.startDate,
      end: this.state.endDate,
    };
    let newPriceEvent2 = {};
    let newEventFlag = false;
    this.state.events.forEach((old) => {
      if (
        newPriceEvent.start > old.start &&
        newPriceEvent.start < old.end &&
        newPriceEvent.end > old.start &&
        newPriceEvent.end < old.end &&
        old.description == 'Price' &&
        old.title.replace('€', '') != this.state.price
      ) {
        priceEventUpdate.push({...old, end: newPriceEvent.start});
        newPriceEvent2 = {
          propertyId: this.state.propertyId,
          start: newPriceEvent.end,
          end: old.end,
          price: old.title.replace('€', ''),
        };
        newEventFlag = true;
      } else if (
        ((newPriceEvent.start >= old.start && newPriceEvent.start <= old.end) ||
          (newPriceEvent.end >= old.start && newPriceEvent.end <= old.end)) &&
        old.description == 'Price' &&
        old.title.replace('€', '') == this.state.price
      ) {
        if (newPriceEvent.start > old.start)
          newPriceEvent = {...newPriceEvent, start: old.start};
        if (newPriceEvent.end < old.end)
          newPriceEvent = {...newPriceEvent, end: old.end};
        priceEventFound.push(old.eventId);
      } else if (
        old.start >= newPriceEvent.start &&
        newPriceEvent.end >= old.end &&
        old.description == 'Price'
      ) {
        priceEventFound.push(old.eventId);
      } else if (
        ((newPriceEvent.start >= old.start && newPriceEvent.start < old.end) ||
          (newPriceEvent.end > old.start && newPriceEvent.end <= old.end)) &&
        old.description == 'Price' &&
        old.title.replace('€', '') != this.state.price
      ) {
        if (newPriceEvent.start > old.start)
          priceEventUpdate.push({...old, end: newPriceEvent.start});
        else if (newPriceEvent.end < old.end)
          priceEventUpdate.push({...old, start: newPriceEvent.end});
      }
    });
    console.log('priceEventUpdate');
    console.log(priceEventUpdate);
    let eventInfo = {
      propertyId: this.state.propertyId,
      ...newPriceEvent,
      price: this.state.price,
    };
    eventInfo = newEventFlag ? [eventInfo, newPriceEvent2] : [eventInfo];
    let eventsUpdate = priceEventUpdate.map((event) => {
      return [event.eventId, event.start, event.end];
    });
    let updatePrice = {
      eventIds: priceEventFound,
      eventsUpdate: eventsUpdate,
      eventInfo: eventInfo,
    };
    const response = await this.props.updatePriceEvents(updatePrice);
    let events = this.state.events.filter((event) => {
      if (
        !priceEventFound.includes(event.eventId) ||
        event.description == 'Unavailable'
      )
        return event;
    });
    console.log('price');
    console.log(this.state.price);
    events = [
      ...events,
      {
        ...newPriceEvent,
        eventId: response.eventId[0],
        title: '€' + this.state.price,
        backgroundColor: colors.PRIMARY,
        borderColor: colors.PRIMARY,
        description: 'Price',
        textColor: colors.WHITE,
      },
    ];
    if (newEventFlag)
      events = [
        ...events,
        {
          ...newPriceEvent2,
          eventId: response.eventId[1],
          backgroundColor: colors.PRIMARY,
          borderColor: colors.PRIMARY,
          description: 'Price',
          title: '€' + newPriceEvent2.price,
          textColor: colors.WHITE,
        },
      ];
    events = events.map((event) => {
      let updatedEvent = priceEventUpdate.find(
        (x) =>
          x.eventId === event.eventId && x.description == event.description,
      );
      if (updatedEvent) {
        console.log(updatedEvent);
        return updatedEvent;
      } else return event;
    });
    console.log(events);
    this.setState((prevState) => ({
      ...prevState,
      events: events,
    }));
  };

  navigateBack = () => {
    this.props.history.push({
      pathname:
        this.state.role == 'admin'
          ? '/editproperty?id=' +
            Buffer.from(this.state.propertyId.toString()).toString('base64') +
            `&page=${Buffer.from(this.state.propertyPage).toString('base64')}`
          : '/owner/editproperty?id=' +
            Buffer.from(this.state.propertyId.toString()).toString('base64') +
            `&page=${Buffer.from(this.state.propertyPage).toString('base64')}`,

      state: {propertyIds: this.state.propertyIds},
    });
    this.props.history.go(0);
  };

  render() {
    const {classes} = this.props;
    return (
      <div
        style={{
          minWidth: 1200,
          paddingTop: 50,
          margin: 0,
          paddingLeft: this.state.role == 'admin' ? 216 : 0,
        }}>
        <div className='section__content section__content--p30'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col'>
                <Alert
                  isOpen={this.state.isAlertVisible}
                  color='info'
                  style={{
                    zIndex: '1000',
                    position: 'fixed',
                    left: '55%',
                    top: '50%',
                    transform:
                      parseInt(this.state.currentMonth) >
                        parseInt(this.state.startingSeason.split('-')[1]) - 1 &&
                      parseInt(this.state.currentMonth) <
                        parseInt(this.state.startingSeason.split('-')[1]) + 6
                        ? 'translate(-120%, -50%)'
                        : 'translate(-50%, -50%)',
                    fontSize: '15px',
                    width: '300px',
                    height: '50px',
                    textAlign: 'center',
                  }}>
                  Please Select End Date!
                </Alert>
                <Alert
                  isOpen={this.state.isAlertStartVisible}
                  color='info'
                  style={{
                    zIndex: '1000',
                    position: 'fixed',
                    left: '55%',
                    top: '50%',
                    transform:
                      parseInt(this.state.currentMonth) >
                        parseInt(this.state.startingSeason.split('-')[1]) - 1 &&
                      parseInt(this.state.currentMonth) <
                        parseInt(this.state.startingSeason.split('-')[1]) + 6
                        ? 'translate(-120%, -50%)'
                        : 'translate(-50%, -50%)',
                    fontSize: '15px',
                    width: '300px',
                    height: '50px',
                    textAlign: 'center',
                  }}>
                  Please Select Start Date!
                </Alert>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignSelf: 'flex-end',
                    justifyContent: 'flex-end',
                  }}>
                  <DefaultButton
                    text='Back'
                    onClick={this.navigateBack}
                    style={{
                      marginRight: this.state.role == 'owner' ? '80px' : '0px',
                      marginTop: this.state.role == 'owner' ? '20px' : '0px',
                    }}
                  />
                </div>
                <div
                  className='au-card'
                  style={{
                    paddingBottom: '80px',
                    paddingLeft: '80px',
                    paddingRight: '80px',
                    paddingTop: '20px',
                  }}>
                  <div>
                    <Dialog
                      onClose={this.handleClose}
                      aria-labelledby='simple-dialog-title'
                      open={this.state.open}
                      fullWidth={true}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: 10,
                        }}>
                        <DialogTitle id='simple-dialog-title'>
                          Set Price/Availability
                        </DialogTitle>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}>
                          <TextInput
                            id='price'
                            type='number'
                            label='Price'
                            value={this.state.price}
                            onChange={this.setPrice}
                          />
                          {/* {Use radio button available unavailable for a better experience} */}
                          <FormControl component='fieldset'>
                            <RadioGroup
                              aria-label='availability'
                              name='availability'
                              onChange={this.handleAvailabilityCheckBox}
                              value={this.state.availability}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginLeft: '20px',
                                marginTop: '20px',
                              }}>
                              <FormControlLabel
                                value='available'
                                control={<PrimaryRadio />}
                                label='Available'
                              />
                              <FormControlLabel
                                value='unavailable'
                                control={<SecondaryRadio />}
                                label='Unavailable'
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'space-between',
                            width: '80%',
                          }}>
                          <div
                            style={{
                              width: '40%',
                            }}>
                            <TextField
                              style={{width: '80%'}}
                              error={this.state.startDateError != ''}
                              className={classes.textField}
                              margin='normal'
                              id='startDate'
                              label='Start'
                              value={this.state.startDate}
                              onChange={(event) =>
                                this.setDate('startDate', event.target.value)
                              }
                              helperText={this.state.startDateError}
                            />
                          </div>
                          <div style={{width: '40%'}}>
                            <TextField
                              style={{width: '80%'}}
                              error={this.state.endDateError != ''}
                              className={classes.textField}
                              margin='normal'
                              id='endDate'
                              label='End'
                              value={this.state.textInputEndDate}
                              onChange={(event) =>
                                this.setEndDate(
                                  'textInputEndDate',
                                  event.target.value,
                                )
                              }
                              helperText={this.state.endDateError}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '80%',
                          }}>
                          {this.state.loading ? (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '20%',
                              }}>
                              <CircularProgress
                                className={classes.buttonLoading}
                              />
                            </div>
                          ) : (
                            <DefaultButton
                              text='Update'
                              onClick={this.savePriceAvailability}
                              style={{marginRight: 15, alignSelf: 'center'}}
                            />
                          )}
                        </div>
                      </div>
                    </Dialog>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        marginTop: '2%',
                      }}>
                      <div style={{height: '70%', width: '50%'}}>
                        <FullCalendar
                          selectable={true}
                          firstDay={1}
                          dateClick={this.handleDateClick}
                          defaultView='dayGridMonth'
                          plugins={[dayGridPlugin, interactionPlugin]}
                          events={this.state.events}
                          contentHeight={450}
                          height={100}
                          aspectRatio={0.5}
                          datesRender={this.nextMonth}
                        />
                      </div>
                      {parseInt(this.state.currentMonth) >
                        parseInt(this.state.startingSeason.split('-')[1]) - 1 &&
                      parseInt(this.state.currentMonth) <
                        parseInt(this.state.startingSeason.split('-')[1]) +
                          6 ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'start',
                            marginTop: '2%',
                          }}>
                          <div
                            style={{
                              marginTop: '20%',
                              display: 'flex',
                              row: 'column',
                            }}>
                            <TextInput
                              id='week1'
                              label={
                                'Week ' +
                                this.state.week1.number +
                                ' ' +
                                this.formatStartToEnd(
                                  this.state.week1.startDate,
                                  this.state.week1.endDate,
                                )
                              }
                              type='number'
                              value={this.state.week1.price}
                              onChange={(event) =>
                                this.setWeek(
                                  'week1',
                                  'price',
                                  event.target.value,
                                )
                              }
                            />
                            <FormControl component='fieldset'>
                              <RadioGroup
                                aria-label='availability'
                                name='availability'
                                onChange={(event) =>
                                  this.setWeek(
                                    'week1',
                                    'availability',
                                    event.target.value,
                                  )
                                }
                                value={this.state.week1.availability}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginLeft: '20px',
                                  marginTop: '20px',
                                }}>
                                <FormControlLabel
                                  value='available'
                                  control={<PrimaryRadio />}
                                  label='Available'
                                />
                                <FormControlLabel
                                  value='unavailable'
                                  control={<SecondaryRadio />}
                                  label='Unavailable'
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          <div>
                            <TextInput
                              id='week2'
                              label={
                                'Week ' +
                                this.state.week2.number +
                                ' ' +
                                this.formatStartToEnd(
                                  this.state.week2.startDate,
                                  this.state.week2.endDate,
                                )
                              }
                              type='number'
                              value={this.state.week2.price}
                              onChange={(event) =>
                                this.setWeek(
                                  'week2',
                                  'price',
                                  event.target.value,
                                )
                              }
                            />
                            <FormControl component='fieldset'>
                              <RadioGroup
                                aria-label='availability'
                                name='availability'
                                onChange={(event) =>
                                  this.setWeek(
                                    'week2',
                                    'availability',
                                    event.target.value,
                                  )
                                }
                                value={this.state.week2.availability}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginLeft: '20px',
                                  marginTop: '20px',
                                }}>
                                <FormControlLabel
                                  value='available'
                                  control={<PrimaryRadio />}
                                  label='Available'
                                />
                                <FormControlLabel
                                  value='unavailable'
                                  control={<SecondaryRadio />}
                                  label='Unavailable'
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          <div>
                            <TextInput
                              id='week3'
                              label={
                                'Week ' +
                                this.state.week3.number +
                                ' ' +
                                this.formatStartToEnd(
                                  this.state.week3.startDate,
                                  this.state.week3.endDate,
                                )
                              }
                              type='number'
                              value={this.state.week3.price}
                              onChange={(event) =>
                                this.setWeek(
                                  'week3',
                                  'price',
                                  event.target.value,
                                )
                              }
                            />
                            <FormControl component='fieldset'>
                              <RadioGroup
                                aria-label='availability'
                                name='availability'
                                onChange={(event) =>
                                  this.setWeek(
                                    'week3',
                                    'availability',
                                    event.target.value,
                                  )
                                }
                                value={this.state.week3.availability}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginLeft: '20px',
                                  marginTop: '20px',
                                }}>
                                <FormControlLabel
                                  value='available'
                                  control={<PrimaryRadio />}
                                  label='Available'
                                />
                                <FormControlLabel
                                  value='unavailable'
                                  control={<SecondaryRadio />}
                                  label='Unavailable'
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          <div>
                            <TextInput
                              id='week4'
                              label={
                                'Week ' +
                                this.state.week4.number +
                                ' ' +
                                this.formatStartToEnd(
                                  this.state.week4.startDate,
                                  this.state.week4.endDate,
                                )
                              }
                              type='number'
                              value={this.state.week4.price}
                              onChange={(event) =>
                                this.setWeek(
                                  'week4',
                                  'price',
                                  event.target.value,
                                )
                              }
                            />
                            <FormControl component='fieldset'>
                              <RadioGroup
                                aria-label='availability'
                                name='availability'
                                onChange={(event) =>
                                  this.setWeek(
                                    'week4',
                                    'availability',
                                    event.target.value,
                                  )
                                }
                                value={this.state.week4.availability}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginLeft: '20px',
                                  marginTop: '20px',
                                }}>
                                <FormControlLabel
                                  value='available'
                                  control={<PrimaryRadio />}
                                  label='Available'
                                />
                                <FormControlLabel
                                  value='unavailable'
                                  control={<SecondaryRadio />}
                                  label='Unavailable'
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          {this.state.weeksLoading ? (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignSelf: 'center',
                                width: '20%',
                              }}>
                              <CircularProgress
                                className={classes.buttonLoading}
                              />
                            </div>
                          ) : (
                            <DefaultButton
                              text='Set Price/Availability'
                              onClick={this.saveWeeklyPriceAvailability}
                              style={{width: '60%', alignSelf: 'center'}}
                            />
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  //   isAuthenticated: state.auth.token
});

const mapDispatchToProps = {
  getCalendarEvents,
  updateAvailabiliyEvents,
  updatePriceEvents,
  getStartingSeason,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(PropertyCalendar)));
