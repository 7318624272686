import api from "../../services/api";
import { errorHandler } from "../../utils";

export const getOwnerProperties = ownerId => async dispatch => {
  try {
    const response = await api.getOwnerProperties(ownerId);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getOwnerPropertyDetails = propertyId => async dispatch => {
  try {
    const response = await api.getOwnerPropertyDetails(propertyId);
    if (response.data.status !== "success") {
      return response.data;
    }
    return response.data.data;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while processing property details");
  }
};

export const updateAvailabiliyEvents = updateAvailabiliy => async dispatch => {
  try {
    const response = await api.updateAvailabiliyEvents(updateAvailabiliy);
    if (response.data.status !== "success") {
      return response.data;
    }
    return response.data.eventId;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while setting availability events");
  }
};

export const updatePriceEvents = updatePrice => async dispatch => {
  try {
    const response = await api.updatePriceEvents(updatePrice);
    if (response.data.status !== "success") {
      return response.data;
    }
    return response.data.eventId;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while setting price events");
  }
};

export const getCalendarEvents = propertyId => async dispatch => {
  try {
    const response = await api.getCalendarEvents(propertyId);
    if (response.data.status !== "success") {
      return response.data;
    }
    return response.data.data;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while processing property events");
  }
};

export const updateProperty = (propertyInfo) => async dispatch => {
  try {
    const response = await api.updateProperty(propertyInfo);

    if (response.data.status !== "success") {
      return response.data.status;
    }
    return response.data;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while processing property details");
  }
};

export const updatePropertyLocation = (long, lat, propertyId) => async dispatch => {
  try {
    const response = await api.updatePropertyLocation(long, lat, propertyId);

    if (response.data.status !== "success") {
      return errorHandler("An error occured while updating property location");
    }
    return response.data.status;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while updating property location");
  }
};

export const addProperty = (propertyInfo) => async dispatch => {
  try {
    const response = await api.addProperty(propertyInfo);

    if (response.data.status !== "success") {
      return response.data.status;
    }
    return response.data;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while processing property details");
  }
};


export const removePropertyPhoto = (propertyId, imageId) => async dispatch => {
  try {
    const response = await api.removePropertyPhoto(propertyId, imageId);

    console.log(response.data);

    if (response.data.status !== "success") {
      return response.data.status;
    }
    return response.data;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while processing property details");
  }
};

export const getPropertyImagesOwner = propertyId => async dispatch => {
  try {
    const response = await api.getPropertyImagesOwner(propertyId);
    return response.data.property;
  } catch (err) {
    console.log(err);
  }
};

export const updatePropertyImagesOwner = info => async dispatch => {
  try {
    const response = await api.updatePropertyImagesOwner(info);

    if (response.data.status !== "success") {
      return response.data.status;
    }
    return response.data;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while processing property details");
  }
};

export const updatePropertyDefaultImageOwner = info => async dispatch => {
  try {
    const response = await api.updatePropertyDefaultImageOwner(info);

    if (response.data.status !== "success") {
      return response.data.status;
    }
    return response.data;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while processing property details");
  }
};
