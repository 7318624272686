import React from "react";
import { withStyles } from "@material-ui/styles";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { colors } from "../../utils";
import "./SortableImages.css";

const SortableImages = (props) => {
  const SortableItem = SortableElement(({ item }) => (
    <div
      className="item"
      style={{
        border: `5px solid ${
          props.defaultImage === item ? colors.PRIMARY : colors.TRANSPARENT
        }`,
        marginTop: props.defaultImage === item ? 24 : 0,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            color: colors.RED,
            zIndex: 1000,
          }}
          onClick={() => props.onDeleteImage(item)}
          title="Delete image"
        >
          <i
            style={{ marginLeft: "5px", marginTop: "4px" }}
            className="fas fa-trash"
          ></i>
        </div>
        {props.defaultImage !== item ? (
          <span
            title="Set as default"
            className="property-info-icon icon-edit"
            style={{ cursor: "pointer", zIndex: 1000 }}
            onClick={() => props.onSetDefaultImage(item)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 24 24"
            >
              <path
                className="meta-icon"
                fill={colors.PRIMARY}
                d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
              />
            </svg>
          </span>
        ) : null}
      </div>
      <div className="inner-item">
        <img
          style={{ cursor: "pointer", objectFit: "contain", height: "100%" }}
          className="d-block w-100"
          src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${props.refName}/${item}?t=${Date.now()}`}
        />
      </div>
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => (
    <div className="container">
      {items.map((item, index) => (
        <SortableItem key={`image${index}`} index={index} item={item} />
      ))}
    </div>
  ));

  return (
    <SortableList
      items={props.items}
      onSortEnd={props.onSortEnd}
      axis="xy"
      helperClass="SortableHelper"
      useWindowAsScrollContainer={true}
      distance={1}
    />
  );
};

const styles = (theme) => ({});

export default withStyles(styles)(SortableImages);
