import React, { useState } from "react";
import InvoiceItem from "../InvoiceItem/InvoiceItem";
import Pagination from "../Pagination/Pagination";

const InvoicesList = (props) => {
  const [search, setSearch] = useState("");
  const [bedroomsFilter, setBedroomsFilter] = useState("");

  let matchingInvoices = props.invoices;
  // filter(property => {
  //   if (
  //     property.assetName.toLowerCase().includes(search.toLowerCase()) ||
  //     property.refName.toLowerCase().includes(search.toLowerCase())
  //   ) {
  //     if (bedroomsFilter !== "") {
  //       if (property.bedroom === bedroomsFilter) {
  //         return property;
  //       } else {
  //         return;
  //       }
  //     } else {
  //       return property;
  //     }
  //   } else {
  //     return;
  //   }
  // });

  const indexOfLastProperty = props.currentPage * props.invoicesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - props.invoicesPerPage;
  const currentInvoices = matchingInvoices.slice(
    indexOfFirstProperty,
    indexOfLastProperty
  );

  let invoicesList = [];
  if (currentInvoices.length > 0) {
    invoicesList = currentInvoices.map((invoice, index) => (
      <InvoiceItem
        page={props.page}
        invoice={invoice}
        key={index}
        deleteInvoice={props.deleteInvoice}
        requestPayment={props.requestPayment}
      />
    ));
  }
  return (
    <div style={{ marginLeft: "9%" }}>
      <table
        style={{ width: "82vw" }}
        className="table table-borderless table-data3"
      >
        <thead>
          <tr style={{ textAlign: "center" }}>
            <th>Delete</th>
            <th>Name</th>
            <th>Request Date</th>
            <th>Service Date</th>
            <th>Invoice#</th>
            <th>Service</th>
            <th>Price</th>
            <th>Paid</th>
            <th>Balance Due</th>
            <th>Send Inv</th>
          </tr>
        </thead>
        <tbody>
          {/* <tr>
            <td></td>
            <td></td>
            <td colSpan={1} style={{ padding: "12px 0px", textAlign: "center" }}>
              <div className="rs-select2--light rs-select2--sm">
                <input
                  style={{
                    padding: "5px 15px",
                    margin: "3px 0",
                    border: "1px solid #DCDCDC",
                    borderRadius: "4px",
                    width: "100%"
                  }}
                  type="text"
                  placeholder="Search"
                  className="js-select2"
                  name="property"
                  onChange={event => {
                    setSearch(event.target.value);
                    props.resetCurrentPage();
                  }}
                />
              </div>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td
              colSpan={1}
              style={{ padding: "12px 0px", textAlign: "center" }}
            >
              <div className="rs-select2--light rs-select2--sm">
                <input
                  style={{
                    padding: "5px 15px",
                    margin: "3px 0",
                    border: "1px solid #DCDCDC",
                    borderRadius: "4px",
                    width: "100%"
                  }}
                  type="number"
                  placeholder="Search"
                  className="js-select2"
                  name="property"
                  onChange={event => {
                    setBedroomsFilter(event.target.value);
                    props.resetCurrentPage();
                  }}
                />
              </div>
            </td>
          </tr> */}
          {invoicesList}
        </tbody>
      </table>
      <Pagination
        postsPerPage={props.invoicesPerPage}
        totalPosts={matchingInvoices.length}
        paginate={props.paginate}
        currentPage={props.currentPage}
      />
    </div>
  );
};

export default InvoicesList;
