import * as React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridColumnsToolbarButton,
  GridFilterToolbarButton,
} from "@material-ui/data-grid";
import Dropdown from "../Dropdown/Dropdown";
import {makeStyles} from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import TextInput from "../TextInput/TextInput";
import DateInput from "../DateInput/DateInput";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";

function CustomPagination(props) {
  const {state, api} = props;
  const classes = useStyles();

  return (
    <Pagination
      showFirstButton
      showLastButton
      classes={{ul: classes.ul}}
      color="primary"
      count={state.pagination.pageCount}
      page={state.pagination.page + 1}
      onChange={(event, value) => api.current.setPage(value - 1)}
    />
  );
}

const useStyles = makeStyles({
  root: {
    "& .table-header": {
      backgroundColor: "#333",
      color: "#FFF",
    },
    "& .MuiIconButton-root": {
      color: "#CBAA5C",
    },
    "& .MuiButton-textPrimary": {
      color: "#CBAA5C",
    },
  },
  ul: {
    "& .MuiPaginationItem-root": {
      // color: "#fff",
    },
    "& .MuiPaginationItem-textPrimary.Mui-selected": {
      backgroundColor: "#CBAA5C",
    },
  },
});

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridColumnsToolbarButton />
      <GridFilterToolbarButton />
    </GridToolbarContainer>
  );
}

let dataHandler;
let deleteDialog;

const columns = [
  {
    field: "name",
    headerName: "Name",
    headerClassName: "table-header",
    width: 150,
    renderCell: (params) => (
      <TextInput
        id={Math.random()}
        value={params.value}
        onChange={(event) => dataHandler("name", params, event.target.value)}
        required={true}
        inputProps={{style: {fontSize: "0.875rem"}}}
      />
    ),
  },
  {
    field: "email",
    headerClassName: "table-header",
    headerName: "Email",
    width: 150,
    renderCell: (params) => (
      <TextInput
        id={Math.random()}
        value={params.value}
        onChange={(event) => dataHandler("email", params, event.target.value)}
        required={true}
        inputProps={{style: {fontSize: "0.875rem"}}}
      />
    ),
  },
  {
    field: "phone",
    headerClassName: "table-header",
    headerName: "Phone",
    width: 150,
    renderCell: (params) => (
      <TextInput
        id={Math.random()}
        value={params.value}
        onChange={(event) => dataHandler("phone", params, event.target.value)}
        required={true}
        inputProps={{style: {fontSize: "0.875rem"}}}
      />
    ),
  },
  {
    field: "from",
    headerClassName: "table-header",
    headerName: "From",
    width: 170,
    renderCell: (params) => (
      <DateInput
        id={Math.random()}
        value={params.value}
        onChange={(event) => dataHandler("from", params, event)}
        inputProps={{style: {fontSize: "0.875rem"}}}
      />
    ),
  },
  {
    field: "to",
    headerClassName: "table-header",
    headerName: "To",
    width: 170,
    renderCell: (params) => (
      <DateInput
        id={Math.random()}
        value={params.value}
        onChange={(event) => dataHandler("to", params, event)}
        inputProps={{style: {fontSize: "0.875rem"}}}
        style={{"& .MuiInputBase-root": {fontSize: 14}}}
      />
    ),
  },
  {
    field: "noOfPeople",
    headerName: "People",
    headerClassName: "table-header",
    width: 100,
    renderCell: (params) => (
      <TextInput
        id={Math.random()}
        value={params.value}
        onChange={(event) =>
          dataHandler("noOfPeople", params, event.target.value)
        }
        required={true}
        inputProps={{style: {fontSize: "0.875rem"}}}
      />
    ),
  },
  {
    field: "noOfBedrooms",
    headerName: "Bedrooms",
    headerClassName: "table-header",
    width: 110,
    renderCell: (params) => (
      <TextInput
        id={Math.random()}
        value={params.value}
        onChange={(event) =>
          dataHandler("noOfBedrooms", params, event.target.value)
        }
        required={true}
        inputProps={{style: {fontSize: "0.875rem"}}}
      />
    ),
  },
  {
    field: "budget",
    headerName: "Budget",
    headerClassName: "table-header",
    width: 100,
    renderCell: (params) => (
      <TextInput
        id={Math.random()}
        value={params.value}
        onChange={(event) => dataHandler("budget", params, event.target.value)}
        required={true}
        inputProps={{style: {fontSize: "0.875rem"}}}
      />
    ),
  },
  {
    field: "notes",
    headerClassName: "table-header",
    headerName: "Notes",
    width: 150,
    renderCell: (params) => (
      <TextInput
        id={Math.random()}
        value={params.value}
        onChange={(event) =>
          dataHandler("notes", params, event.target.value)
        }
        required={true}
        inputProps={{style: {fontSize: "0.875rem"}}}
      />
    ),
  },
  {
    field: "propertyType",
    headerClassName: "table-header",
    headerName: "Property Type",
    width: 180,
    renderCell: (params) => (
      <Dropdown
        value={params.value}
        onSelect={(event) => dataHandler("propertyType", params, event.target.value)}
        tableDropdown
        styles={{marginBottom: 0, width: "100%", margin: "auto"}}
        options={[                        
          'Countryside',
          'Panoramic Nature',
          'Panoramic Sea View',
          'Partial Sea View',
          'Sea View',
        ]}
      />
    ),
  },
  {
    field: "preferredLocation",
    headerClassName: "table-header",
    headerName: "Preferred Location",
    width: 150,
    renderCell: (params) => (
      <TextInput
        id={Math.random()}
        value={params.value}
        onChange={(event) =>
          dataHandler("preferredLocation", params, event.target.value)
        }
        required={true}
        inputProps={{style: {fontSize: "0.875rem"}}}
      />
    ),
  },
  {
    field: "visitedBefore",
    headerClassName: "table-header",
    headerName: "Visited Before",
    width: 140,
    renderCell: (params) => (
      <Dropdown
        value={parseInt(params.value)}
        onSelect={(event) =>
          dataHandler("visitedBefore", params, event.target.value)
        }
        tableDropdown
        styles={{marginBottom: 0, width: "100%", margin: "auto"}}
        values={[0, 1]}
        options={["No", "Yes"]}
      />
    ),
  },
  {
    field: "interestedServices",
    headerName: "Service Type",
    headerClassName: "table-header",
    width: 150,
    sortable: false,
    renderCell: (params) =>
      params.value ? (
        <Dropdown
          defaultValue={params.value.length + " Services"}
          value={params.value.length + " Services"}
          type={"interestedServices"}
          tableDropdown
          styles={{marginBottom: 0, padding: 2, margin: "auto"}}
          options={params.value}
        />
      ) : (
        <Dropdown defaultValue={""} value={""} options={[]} />
      ),
  },
  {
    field: "status",
    headerName: "Status",
    headerClassName: "table-header",
    width: 250,
    sortable: false,
    renderCell: (params) => (
      <Dropdown
        value={params.value}
        onSelect={(event) => dataHandler("status", params, event.target.value)}
        tableDropdown
        styles={{marginBottom: 0, width: "100%", margin: "auto"}}
        options={[
          "-",
          "Pending Your Selections",
          "Pending Client Choice",
          "Pending Admin Approval",
          "Pending First Payment & Contract",
          "Pending Second Payment",
          "Approved",
        ]}
      />
    ),
  },
  {
    field: "",
    headerName: "Action",
    headerClassName: "table-header",
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return (
        <Button
          key={Math.random()}
          onClick={() => deleteDialog(params)}
          color="danger"
          round
          justIcon
          size="sm">
          <DeleteIcon style={{color: "#f44336"}} />
        </Button>
      );
    },
  },
];

export default function DataGridDemo(props) {
  const classes = useStyles();
  React.useEffect(() => {
    dataHandler = props.dataHandler;
    deleteDialog = props.deleteDialog;
  }, [props]);

  return (
    <div style={{height: 520, width: "100%"}} className={classes.root}>
      <DataGrid
        rows={props.requests}
        columns={columns}
        pageSize={10}
        density="compact"
        components={{
          Toolbar: CustomToolbar,
          Pagination: CustomPagination,
        }}
        pagination
        onSelectionModelChange={props.selectRequestHandler}
      />
    </div>
  );
}
