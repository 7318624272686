import React from "react";
import { colors } from "../../utils";

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];
  const totalPages = Math.ceil(totalPosts / postsPerPage);

  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    let difference = totalPages - currentPage;
    if (difference < 2) {
      difference += 1;
      for (let i = currentPage - 5 + difference; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else if (difference >= totalPages - 2) {
      for (let i = 1; i <= 5; i++) {
        pageNumbers.push(i);
      }
    } else {
      for (let i = currentPage - 2; i <= currentPage + 2; i++) {
        pageNumbers.push(i);
      }
    }
  }
  const anchorDisplay = totalPages > 0 ? "inline-block" : "none";

  return (
    <nav>
      <ul
        className="pagination"
        style={{ margin: "auto", width: "fit-content" }}
      >
        <li id="first-page" key={0} className="page-item">
          <a
            onClick={() => paginate(1)}
            style={{
              margin: "auto",
              display: anchorDisplay,
              cursor: "pointer"
            }}
            className="page-link"
          >
            &#xab;
          </a>
        </li>
        {pageNumbers.map(number => (
          <li
            id={`page${number}`}
            key={number}
            className="page-item"
            style={{
              "&:hover": {
                background: "red"
              }
            }}
          >
            <a
              onClick={() => paginate(number)}
              style={{
                // backgroundColor: currentPage === number ? "#DCAC38" : colors.TRANSPARENT,
                ...(currentPage === number
                  ? { backgroundColor: colors.PRIMARY }
                  : {}),
                color: currentPage === number ? "#FFFFFF" : "#000000",
                margin: "auto",
                cursor: "pointer"
              }}
              className="page-link"
            >
              {number}
            </a>
          </li>
        ))}
        <li id="last-page" key={totalPages + 1} className="page-item">
          <a
            onClick={() => paginate(totalPages)}
            style={{
              margin: "auto",
              display: anchorDisplay,
              cursor: "pointer"
            }}
            className="page-link"
          >
            &#xbb;
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
