import { Container, CardMedia } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import React from "react";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { colors, getImageBase64 } from "../../utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextInput from "../../components/TextInput/TextInput";
import {
  createRequest,
  deleteRequest,
  getRequestPayments,
} from "../../store/actions";
import "./RequestPayment.css";
import "bootstrap/dist/css/bootstrap.min.css";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";
import Card from "@material-ui/core/Card";
import RequestPaymentList from "../../components/RequestPaymentList/RequestPaymentList";
import YesNoDialog from "../../components/YesNoDialog/YesNoDialog";
const queryString = require("query-string");

const styles = (theme) => ({
  circularProgress: {
    color: colors.PRIMARY,
    position: "absolute",
    left: "50%",
    top: "50%",
  },
  buttonLoading: {
    color: colors.PRIMARY,
  },
  reqMessage: {
    alignSelf: "flex-start",
    color: "#ff5151",
    fontSize: "12px",
    opacity: "0",
    transition: "all 0.3s",
  },
});

class RequestPayment extends React.Component {
  state = {
    loading: false,
    showDialog: false,
    deleteLoading: false,
    deleteRequest: "",
    price: "",
    requests: [],
    currentPage: 1,
    requestsPerPage: 10,
    showRequiredMessage: {
      price: false,
    },
  };

  componentDidMount = async () => {
    let role = localStorage.getItem("authType");
    let params = queryString.parse(this.props.location.search);
    let invoiceNumber = Buffer.from(params.id, "base64").toString();
    const requests = await this.props.getRequestPayments(invoiceNumber);

    this.setState((prevState) => ({
      ...prevState,
      requests,
      role,
      invoiceNumber,
    }));
  };

  setShowRequiredMessage = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      showRequiredMessage: {
        ...prevState.showRequiredMessage,
        [name]: value,
      },
    }));
  };

  createRequest = async () => {
    if (this.state.price != "") {
      try {
        this.setState((prevState) => ({
          ...prevState,
          loading: true,
        }));

        let requestInfo = {
          invoiceNumber: this.state.invoiceNumber,
          amount: this.state.price,
        };

        const response = await this.props.createRequest(requestInfo);

        this.setState((prevState) => ({
          ...prevState,
          loading: false,
          price: "",
          requests: response,
        }));
        this.setShowRequiredMessage("price", false);
      } catch (err) {
        this.setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    } else {
      if (this.state.price == "") {
        this.setShowRequiredMessage("price", true);
      } else {
        this.setShowRequiredMessage("price", false);
      }
    }
  };

  resetCurrentPageHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
  };

  paginate = async (pageNumber) => {
    this.setState({
      currentPage: pageNumber,
    });
  };

  setField = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  deleteRequest = async () => {
    this.setState((prevState) => ({
      ...prevState,
      deleteLoading: true,
    }));

    let status = await this.props.deleteRequest(this.state.deleteRequest);

    if (status == "success") {
      let requests = this.state.requests.filter(
        (request) => request.id != this.state.deleteRequest
      );
      this.setState((prevState) => ({
        ...prevState,
        requests: requests,
      }));
    } else {
      console.log("Delete request failed");
    }
    this.closeDialog();
  };

  paymentLink = (requestId) => {
    window.open(
      process.env.REACT_APP_SERVER_URL +
        "/api/user/paymentlink?id=" +
        Buffer.from(requestId.toString()).toString("base64"),
      "_blank"
    );
  };

  showDialog = (id) => {
    this.setState((prevState) => ({
      ...prevState,
      showDialog: true,
      deleteRequest: id,
    }));
  };

  closeDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDialog: false,
      deleteLoading: false,
    }));
  };

  render() {
    const { classes } = this.props;

    return (
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginBottom: "2%",
          marginLeft: 0,
          marginRight: 0,
          minWidth: 1200,
          paddingTop: 50,
          paddingLeft: 210,
        }}
      >
        <YesNoDialog
          visible={this.state.showDialog}
          onClose={this.closeDialog}
          title="Are you sure?"
          text="Are you sure you want to delete this payment request?"
          onAccept={this.deleteRequest}
          onReject={this.closeDialog}
          deleteLoading={this.state.deleteLoading}
        />
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: "2%",
          }}
        >
          <Card
            style={{
              width: "350px",
              padding: "20px",
              height: "250px",
              // marginLeft: "100px",
            }}
            elevation={5}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h4
                className="title-5"
                style={{
                  paddingBottom: "10px",
                  borderBottomWidth: "2px",
                  borderBottomStyle: "solid",
                  borderBottomColor: "rgb(229, 229, 229)",
                  width: "90%",
                }}
              >
                Payment request
              </h4>
              <div style={{ width: "70%" }}>
                <TextInput
                  styles={{ margin: "1rem" }}
                  error={this.state.showRequiredMessage.price}
                  helperText={"* Enter the client name"}
                  required={true}
                  id="price"
                  label="Price (€)"
                  type="text"
                  value={this.state.price}
                  onChange={(event) =>
                    this.setField("price", event.target.value)
                  }
                />
              </div>
              {this.state.loading ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    margin: "1rem",
                  }}
                >
                  <CircularProgress className={classes.buttonLoading} />
                </div>
              ) : (
                <DefaultButton
                  text="Send"
                  onClick={this.createRequest}
                  style={{
                    width: "50%",
                    margin: "auto",
                    marginTop: "1.2rem",
                  }}
                />
              )}
            </div>
          </Card>
        </Container>
        <RequestPaymentList
          page={"invoices"}
          requests={this.state.requests}
          requestsPerPage={this.state.requestsPerPage}
          currentPage={this.state.currentPage}
          paginate={this.paginate}
          resetCurrentPage={this.resetCurrentPageHandler}
          deleteRequest={this.showDialog}
          paymentLink={this.paymentLink}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  //   isAuthenticated: state.auth.token
});

const mapDispatchToProps = {
  createRequest,
  deleteRequest,
  getRequestPayments,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(RequestPayment)));
