import React from "react";
import { AppBar, Toolbar } from "@material-ui/core";
import { colors } from "../../utils";

function TopBar() {
  return (
    <AppBar
      style={{
        backgroundColor: "#fff",
        boxShadow: "0px 1px rgba(138, 138, 138, 0.2)",
        position: "sticky",
        minWidth: "1440px"
      }}
    >
      <Toolbar
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <div>
          <div
            style={{
              fontSize: "1px",
              lineHeight: "5px"
            }}
          >
            &nbsp;
          </div>
          <img
            align="center"
            alt="BRI-icon"
            src="https://berealibiza-properties-images.s3.eu-central-1.amazonaws.com/Logos/BRI_logo.png"
            title="BRI-icon"
            width={140}
          />
        </div>
        <a
          href="/portal/logout"
          style={{
            color: colors.PRIMARY,
            backgroundColor: 'transparent',
            marginBottom: 0,
            cursor: "pointer"
          }}
        >
          Logout
        </a>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
