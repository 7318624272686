import React, {useState} from "react";
import PropertyItem from "../../components/PropertyItem/PropertyItem";
import Pagination from "../../components/Pagination/Pagination";

const PropertiesList = (props) => {
  const [search, setSearch] = useState("");
  const [bedroomsFilter, setBedroomsFilter] = useState("");
  let matchingProperties = props.properties.filter((property) => {
    if (
      property.assetName.toLowerCase().includes(search.toLowerCase()) ||
      property.refName.toLowerCase().includes(search.toLowerCase())
    ) {
      if (bedroomsFilter !== "") {
        if (property.bedroom === bedroomsFilter) {
          return property;
        } else {
          return;
        }
      } else {
        return property;
      }
    } else {
      return;
    }
  });

  const indexOfLastProperty = props.currentPage * props.propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - props.propertiesPerPage;
  const currentProperties = matchingProperties.slice(
    indexOfFirstProperty,
    indexOfLastProperty,
  );

  let propertiesList = [];
  if (currentProperties.length > 0) {
    propertiesList = currentProperties.map((property, index) => (
      <PropertyItem
        page={props.page}
        property={property}
        key={index}
        propertyCheckBoxHandler={props.propertyCheckBoxHandler}
        propertyDetails={props.propertyDetails}
      />
    ));
  }
  return (
    <div>
      <table
        style={{width: "80vw"}}
        className="table table-borderless table-data3">
        <thead>
          <tr>
            <th>Select</th>
            <th>Status</th>
            <th>Property</th>
            <th>Owner</th>
            <th>Availablility</th>
            <th>Location</th>
            <th>Bedrooms</th>
            <th>Price</th>
            <th>License</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td colSpan={1} style={{padding: "12px 0px", textAlign: "center"}}>
              <div className="rs-select2--light rs-select2--sm">
                <input
                  style={{
                    padding: "5px 15px",
                    margin: "3px 0",
                    border: "1px solid #DCDCDC",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  type="text"
                  placeholder="Search"
                  className="js-select2"
                  name="property"
                  onChange={(event) => {
                    setSearch(event.target.value);
                    props.resetCurrentPage();
                  }}
                />
              </div>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td colSpan={1} style={{padding: "12px 0px", textAlign: "center"}}>
              <div className="rs-select2--light rs-select2--sm">
                <input
                  style={{
                    padding: "5px 15px",
                    margin: "3px 0",
                    border: "1px solid #DCDCDC",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  type="number"
                  placeholder="Search"
                  className="js-select2"
                  name="property"
                  onChange={(event) => {
                    setBedroomsFilter(event.target.value);
                    props.resetCurrentPage();
                  }}
                />
              </div>
            </td>
          </tr>
          {propertiesList}
        </tbody>
      </table>
      <Pagination
        postsPerPage={props.propertiesPerPage}
        totalPosts={matchingProperties.length}
        paginate={props.paginate}
        currentPage={props.currentPage}
      />
    </div>
  );
};

export default PropertiesList;
