import React from "react";
import { withStyles } from "@material-ui/styles";
import { colors } from "../../utils";

const Jumbotron = props => {
  const { classes } = props;
  return (
    <div style={{backgroundImage: props.src}} className={classes.header}>
      <h1 className={classes.headerText} style={{color: colors.WHITE}}>{props.children}</h1>
    </div>
  );
};

const styles = theme => ({
  header: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/page-header.jpg)`,
    backgroundSize: "cover",
    backgroundPosition: "center top",
    color: "#FFF",
    minHeight: "160px",
    paddingLeft: "10vw",
    paddingTop: "4rem"
  },
  headerText: {
    fontSize: "2rem",
    fontWeight: "250",
    letterSpacing: "3px",
    textTransform: "uppercase"
  }
});

export default withStyles(styles)(Jumbotron);
