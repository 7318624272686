import React, { useState } from "react";
import RequestPaymentItem from "../RequestPaymentItem/RequestPaymentItem";
import Pagination from "../Pagination/Pagination";

const RequestPaymentList = (props) => {
  const [search, setSearch] = useState("");
  const [bedroomsFilter, setBedroomsFilter] = useState("");

  let matchingRequests = props.requests;
  const indexOfLastProperty = props.currentPage * props.requestsPerPage;
  const indexOfFirstProperty = indexOfLastProperty - props.requestsPerPage;
  const currentRequests = matchingRequests.slice(
    indexOfFirstProperty,
    indexOfLastProperty
  );

  let requestsList = [];
  if (currentRequests.length > 0) {
    requestsList = currentRequests.map((request, index) => (
      <RequestPaymentItem
        page={props.page}
        request={request}
        key={index}
        deleteRequest={props.deleteRequest}
        paymentLink={props.paymentLink}
      />
    ));
  }
  return (
    <div>
      <table
        style={{ width: "80vw" }}
        className="table table-borderless table-data3"
      >
        <thead>
          <tr style={{ textAlign: "center" }}>
            <th style={{ textAlign: "center" }}>Delete</th>
            <th style={{ textAlign: "center" }}>Invoice</th>
            <th style={{ textAlign: "center" }}>Client</th>
            <th style={{ textAlign: "center" }}>Email</th>
            <th style={{ textAlign: "center" }}>Date issue</th>
            <th style={{ textAlign: "center" }}>Amount</th>
            <th style={{ textAlign: "center" }}>Status</th>
            <th style={{ textAlign: "center" }}>Date Paid</th>
            <th style={{ textAlign: "center" }}>Payment Link</th>
          </tr>
        </thead>
        <tbody>{requestsList}</tbody>
      </table>
      <Pagination
        postsPerPage={props.requestsPerPage}
        totalPosts={matchingRequests.length}
        paginate={props.paginate}
        currentPage={props.currentPage}
      />
    </div>
  );
};

export default RequestPaymentList;
