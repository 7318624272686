import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './App.css';
import Login from './pages/Auth/Login/Login';
import Requests from './pages/Requests/Requests';
import PropertyDetails from './pages/PropertyDetails/PropertyDetails';
import EditProperty from './pages/EditProperty/EditProperty';
import EditEvent from './pages/EditEvent/EditEvent';
import EditYacht from './pages/EditYacht/EditYacht';
import AddProperty from './pages/AddProperty/AddProperty';
import Logout from './pages/Auth/Logout/Logout';
import OwnersEmails from './pages/OwnersBulkEmail/OwnersBulkEmail';
import ClientsEmails from './pages/ClientsBulkEmail/ClientsBulkEmail';
import OldClients from './pages/OldClients/OldClients';
import AdminCalendar from './pages/AdminCalendar/AdminCalendar';
import PropertyCalendar from './pages/PropertyCalendar/PropertyCalendar';
import Properties from './pages/Owner/Properties';
import Events from './pages/Events/Events';
import Yachts from './pages/Yachts/Yachts';
import OwnersPage from './pages/OwnersPage/OwnersPage';
import PropertyImages from './pages/PropertyImages/PropertyImages';
import PropertyMap from './pages/PropertyMap/PropertyMap';
import Invoice from './pages/Invoice/Invoice';
import RequestPayment from './pages/RequestPayment/RequestPayment';
import Property from './pages/Owner/Property';
import Home from './pages/Home/Home';
import {authCheckState} from './store/actions';
import {connect} from 'react-redux';
import TopBar from './components/TopBar/TopBar';
import SideBar from './components/SideBar/SideBar';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import ExtraServicesForm from './pages/ExtraServicesForm/ExtraServicesForm';
import RatingPage from './pages/RatingPage/RatingPage';
import SelectPropertyPage from './pages/SelectPropertyPage/SelectPropertyPage';
import OwnerDetails from './pages/OwnerDetails/OwnerDetails';
import GeneratePropertyPDF from './components/GeneratePropertyPDF/GeneratePropertyPDF';
import AddOwner from './pages/AddOwner/AddOwner';
import createBrowserHistory from 'history/createBrowserHistory';

const historyConfig = {
  basename: '/portal',
};
const history = createBrowserHistory(historyConfig);

class App extends Component {
  componentWillMount = async () => {
    await this.props.onTryAutoSignIn();
  };

  render() {
    return (
      <div>
        <link
          rel='stylesheet'
          href='https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css'
          integrity='sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T'
          crossOrigin='anonymous'
        />
        {/* <TopBar /> */}
        <Router basename={'/portal'} history={history}>
          <Switch>
            <Route path='/login'>
              <TopBar />
              <Login />
            </Route>
            <Route path='/logout'>
              <Logout />
            </Route>
            <PrivateRoute component={Requests} path='/requests' />
            <PrivateRoute component={OwnersEmails} path='/ownersemails' />
            <PrivateRoute component={ClientsEmails} path='/clientsemails' />
            <PrivateRoute component={OldClients} path='/addrequest' />
            <PrivateRoute component={AdminCalendar} path='/calendar' />
            <PrivateRoute component={EditProperty} path='/owner/editproperty' />
            <PrivateRoute
              component={PropertyCalendar}
              path='/owner/propertycalendar'
            />
            <PrivateRoute component={Properties} path='/owner/properties' />
            <PrivateRoute component={EditProperty} path='/editproperty' />
            <PrivateRoute component={EditEvent} path='/editevent' />
            <PrivateRoute component={EditEvent} path='/addevent' />
            <PrivateRoute component={EditYacht} path='/edityacht' />
            <PrivateRoute component={EditYacht} path='/addyacht' />
            <PrivateRoute component={OwnerDetails} path='/ownerdetails' />
            <Route
              component={GeneratePropertyPDF}
              path='/generatepropertypdf'
            />
            <PrivateRoute component={AddProperty} path='/addproperty' />
            <PrivateRoute component={OwnersPage} path='/owners' />
            <PrivateRoute component={AddOwner} path='/addowner' />
            <PrivateRoute
              component={PropertyCalendar}
              path='/propertycalendar'
            />
            <PrivateRoute component={Properties} path='/properties' />
            <PrivateRoute component={Yachts} path='/yachts' />
            <PrivateRoute component={Events} path='/events' />
            <PrivateRoute component={Properties} path='/properties' />
            <PrivateRoute component={Invoice} path='/invoice' />
            <PrivateRoute component={RequestPayment} path='/requestpayment' />
            <PrivateRoute component={PropertyImages} path='/manageimages' />
            <PrivateRoute
              component={PropertyImages}
              path='/owner/manageimages'
            />
            <PrivateRoute component={PropertyMap} path='/map' />
            <PrivateRoute component={PropertyMap} path='/owner/map' />
            <Route path='/user/extraservicesform'>
              <ExtraServicesForm />
            </Route>
            <Route path='/user/ratingpage'>
              <RatingPage />
            </Route>
            <Route path='/user/selectproperty'>
              <SelectPropertyPage />
            </Route>
            <PrivateRoute exact path='/' />
            <Route path='/user/propertydetails'>
              <PropertyDetails />
            </Route>
            <PrivateRoute exact path='/' />
          </Switch>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.token,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignIn: () => dispatch(authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
