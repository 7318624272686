import React from "react";
import { withStyles } from "@material-ui/styles";
import { TextField } from "@material-ui/core";
import { colors } from "../../utils";

const TextInput = (props) => {
  const { classes } = props;
  return (
    <CssTextField
      error={props.error ? true : false}
      helperText={props.error ? props.helperText : false}
      required={props.required ? true : false}
      inputProps={{
        style: { fontSize: props.fontSize },
      }}
      style={{
        ...props.styles,
        width: props.width,
        fontSize: props.fontSize,
        ...props.style,
      }}
      id={props.id}
      label={props.label}
      type={props.type}
      value={props.value ? props.value : ""}
      onChange={props.onChange}
      {...(props.variant ? { variant: props.variant } : {})}
      inputProps={props.inputProps}
    />
  );
};

const styles = (theme) => ({});
const formItemStyle = {
  root: {
    "& label.Mui-focused": {
      color: colors.PRIMARY,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#e6b74d",
      borderWidth: "2px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: colors.PRIMARY,
        borderWidth: "1px",
      },
      "&:hover:not($disabled):not($focused):not($error)": {
        borderColor: colors.PRIMARY.toString().concat(" !important"),
        borderWidth: "1px",
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.PRIMARY,
        borderWidth: "1px",
      },
    },
  },
};
const CssTextField = withStyles(formItemStyle)(TextField);

export default withStyles(styles)(TextInput);
