import React from "react";
import { Alert } from "reactstrap";
import Dropdown from "../Dropdown/Dropdown";
import CheckBoxDropdown from "../CheckBoxDropdown/CheckBoxDropdown";

const RequestInfo = props => {
  return (
    <table className="table table-data2">
      <thead>
        <tr>
          <th>name</th>
          <th>email</th>
          <th>Requested Date From</th>
          <th>Requested Date To</th>
          <th>Property Type</th>
          <th>Service Type</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {props.selectedRequest.name ? props.selectedRequest.name : "-"}{" "}
          </td>
          {/* <a href="">View Profile</a> */}
          <td>
            <span className="block-email">
              {props.selectedRequest.email ? props.selectedRequest.email : "-"}
            </span>
          </td>
          <td>
            {props.selectedRequest.from ? props.selectedRequest.from : "-"}{" "}
          </td>
          <td>{props.selectedRequest.to ? props.selectedRequest.to : "-"} </td>
          <td>
            {props.selectedRequest.propertyType
              ? props.selectedRequest.propertyType
              : "-"}
          </td>
          <td>
            {props.selectedRequest.interestedServices ? (
              <Dropdown
                defaultValue={
                  props.selectedRequest.interestedServices.length +
                  " Service Selected"
                }
                value={
                  props.selectedRequest.interestedServices.length +
                  " Service Selected"
                }
                type={"interestedServices"}
                options={props.selectedRequest.interestedServices}
              />
            ) : (
              <Dropdown defaultValue={""} value={""} options={[]} />
            )}
          </td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th>Number of People</th>
          <th>Bedrooms</th>
          <th>Budget</th>
          <th>Status</th>
          <th>Admin</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {" "}
            {props.selectedRequest.noOfPeople
              ? props.selectedRequest.noOfPeople
              : "-"}{" "}
          </td>
          <td>
            {props.selectedRequest.noOfBedrooms
              ? props.selectedRequest.noOfBedrooms
              : "-"}
          </td>
          <td>
            {props.selectedRequest.budget
              ? "€" + props.selectedRequest.budget
              : "-"}{" "}
          </td>
          <td className="desc">
            {" "}
            {props.selectedRequest.status
              ? props.selectedRequest.status
              : "-"}{" "}
          </td>
          <td>
            <Dropdown
              value={props.selectedRequest.status}
              onSelect={props.statusHandler}
              options={[
                "-",
                "Pending Your Selections",
                "Pending Client Choice",
                "Pending Admin Approval",
                "Pending First Payment & Contract",
                "Pending Second Payment",
                "Approved"
              ]}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default RequestInfo;
