import React from "react";
import { withStyles } from "@material-ui/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import { colors } from "../../utils";

const CheckBoxDropdown = (props) => {
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);
  const { classes } = props;

  let menuItems = props.options.map((option, index) => (
    <MenuItem key={index} className={classes.menuItem} value={option}>
      <Checkbox checked={props.values.includes(option)} color="primary" />
      {option}
    </MenuItem>
  ));
  let selectProps = {};
  if (props.value != "-") {
    selectProps.value = props.value;
  }
  return (
    <CssFormControl
      variant="outlined"
      className={classes.select}
      error={props.error ? true : false}
    >
      <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
        {props.label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        multiple
        value={props.values}
        onChange={props.onSelect}
        labelWidth={labelWidth}
        renderValue={(selected) => selected.join(", ")}
      >
        {props.defaultValue ? (
          <MenuItem disabled className={classes.menuItem} value={undefined}>
            <em>{props.defaultValue}</em>
          </MenuItem>
        ) : null}
        {menuItems}
      </Select>
      <FormHelperText>{props.error ? props.helperText : false}</FormHelperText>
    </CssFormControl>
  );
};

const styles = (theme) => ({
  select: {
    marginBottom: "20px",
    width: "14rem",
  },
  menuItem: {
    "&:hover": {
      backgroundColor: colors.PRIMARY,
      color: colors.WHITE,
    },
    "& .MuiCheckbox-colorPrimary": {
      color: colors.PRIMARY,
    },
  },
});
const formItemStyle = {
  root: {
    "& label.Mui-focused": {
      color: colors.PRIMARY,
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#999",
        borderWidth: "1px",
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.PRIMARY,
        borderWidth: "1px",
      },
    },
  },
};
const CssFormControl = withStyles(formItemStyle)(FormControl);

export default withStyles(styles)(CheckBoxDropdown);
