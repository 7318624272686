import { withRouter } from "react-router-dom";
import React from "react";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import EventCard from "../../components/EventCard/EventCard";
import Grid from "@material-ui/core/Grid";
import { getAllEvents } from "../../store/actions";
import Pagination from "../../components/Pagination/Pagination";
import TextInput from "../../components/TextInput/TextInput";
import { colors } from "../../utils";
import Radio from "@material-ui/core/Radio";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";

class Events extends React.Component {
  state = {
    events: [],
    owner: {},
    role: "",
    search: "",
    currentPage: 1,
    active: "Yes",
  };

  componentDidMount = async () => {
    let role = localStorage.getItem("authType");
    if (role == "admin") {
      const events = await this.props.getAllEvents();
      this.setState((prevState) => ({
        ...prevState,
        events,
        role: "admin",
      }));
    }
  };

  searchTermChangedHandler = (event) => {
    this.setState((prevState) => ({
      ...prevState,
      search: event.target.value,
      currentPage: 1,
    }));
  };

  paginate = async (pageNumber) => {
    this.setState((prevState) => ({
      ...prevState,
      currentPage: pageNumber,
    }));
  };

  setActiveChangeHandler = (event) => {
    this.setState((prevState) => ({
      ...prevState,
      active: event.target.value,
    }));
  };

  render() {
    const { classes } = this.props;
    let events = [];
    let matchingEvents = this.state.events;

    if (this.state.events?.length > 0) {
      matchingEvents = matchingEvents.filter((event) =>
        event.title.toLowerCase().includes(this.state.search.toLowerCase())
      );

      const indexOfLastEvent = this.state.currentPage * 12;
      const indexOfFirstEvent = indexOfLastEvent - 12;
      events = matchingEvents.slice(indexOfFirstEvent, indexOfLastEvent);

      events = events.map((event, index) => (
        <Grid key={index} item>
          <EventCard event={event} role={this.state.role} />
        </Grid>
      ));
    }

    return (
      <div style={{
        minWidth: 1440,
        paddingTop: 50,
        margin: 0,
        paddingLeft: 210,
      }}>
        <h3 className="title-5" >
          Events
        </h3>

        <div
          className={classes.eventsSection}
        >
          {this.state.events?.length > 0 ? (
            <div className={classes.gridContainer}>
              <div
                style={{
                  width: "100%",
                  margin: 15,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TextInput
                  label="Search"
                  value={this.state.search}
                  onChange={(event) => {
                    event.persist();
                    this.searchTermChangedHandler(event);
                  }}
                  variant="outlined"
                  width="38%"
                  type="text"
                />
                {this.state.role == "admin" ? (
                  <DefaultButton
                    text="Add Event"
                    onClick={() => {
                      this.props.history.push("/addevent");
                    }}
                    style={{ marginRight: 15, alignSelf: "center" }}
                  />
                ) : null}
              </div>
              <Grid container spacing={3}>
                {events}
              </Grid>

              <div style={{ marginTop: "2rem" }}>
                <Pagination
                  postsPerPage={12}
                  totalPosts={matchingEvents.length}
                  paginate={this.paginate}
                  currentPage={this.state.currentPage}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    // paddingTop: "1rem"
  },
  eventsSection: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem",
  },
  searchContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  gridContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "73rem",
    margin: "1rem",
    padding: "2rem",
    border: "1px solid #f7f7f7",
    borderRadius: "2px",
    boxSizing: "border-box",
  },
});

const GoldRadio = withStyles({
  root: {
    color: colors.PRIMARY,
    "&$checked": {
      color: colors.PRIMARY,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const mapDispatchToProps = {
  getAllEvents,
};
const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(Events)));
