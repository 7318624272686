import React from "react";
import { withStyles } from "@material-ui/styles";
import { colors } from "../../utils";

const PropertyItem = props => {
  return (
    <tr>
      <td style={{ textAlign: "center" }}>
        <label className="au-checkbox">
          <input
            name={props.property.id}
            type="checkbox"
            checked={props.property.isSelected}
            onChange={props.propertyCheckBoxHandler}
          />
          <span className="au-checkmark" />
        </label>
      </td>
      <td>{props.property.clientChoice}</td>
      <td style={{ padding: "12px 0px", textAlign: "center" }}>
        {props.property.refName}
      </td>
      <td>{props.property.name}</td>
      <td className={props.property.calendarAvailability ? "denied" : "process"}>
        {props.property.calendarAvailability ? "Unavailable" : "Available"}
      </td>
      <td>{props.property.locationName}</td>
      <td style={{ padding: "12px 0px", textAlign: "center" }}>
        {props.property.bedroom}
      </td>
      <td>€{props.property.totalPrice}</td>
      <td>{props.property.licenseNumber}</td>
      <td>
        <div
          className={props.classes.sendInvitation}
          style={{ textAlign: "center" }}
          onClick={() => props.propertyDetails(props.property.id.toString(), props.page)}
        >
          Details
        </div>
      </td>
    </tr>
  );
};

const styles = (theme) => ({
  sendInvitation: {
    color: "#808080",
    background: "#f2f2f2",
    borderRadius: "2px",
    lineHeight: "15px",
    padding: "7px",
    cursor: "pointer",
    "&:active": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      backgroundColor: colors.PRIMARY,
      color: colors.WHITE,
    },
  },
});

export default withStyles(styles)(PropertyItem);