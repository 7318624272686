import React from "react";
import { useLocation } from "react-router";
import { colors } from "../../utils";

function TopBar() {
  let location = useLocation();
  let user = JSON.parse(localStorage.getItem("user"));
  let username = user.username;
  //console.log(user.username);

  return (
    <div className=" remove-scrollbar">
      {/* END HEADER MOBILE*/}
      {/* MENU SIDEBAR*/}
      <aside
        className="menu-sidebar remove-scrollbar"
        style={{ width: "210px", minWidth: "210px", zIndex: 1000, position: "absolute" }}
      >
        <div className="logo"></div>
        <div className="menu-sidebar__content js-scrollbar1">
          <nav style={{ width: "15rem" }} className="navbar-sidebar">
            <ul className="list-unstyled navbar__list">
              <li
                className={
                  location.pathname === "/properties" ||
                  location.pathname === "/editproperty" ||
                  location.pathname === "/propertycalendar" ||
                  location.pathname === "/manageimages" ||
                  location.pathname === "/map" ||
                  location.pathname === "/addproperty"
                    ? "active has-sub"
                    : ""
                }
              >
                <a href="/portal/properties" style={{ paddingLeft: "15px" }}>
                  <i className="fas fa-home" />
                  Properties
                </a>
              </li>
              {username != "booking@berealibiza.com" ? (
                <li
                  className={
                    location.pathname === "/owners" ||
                    location.pathname === "/ownerdetails"
                      ? "active has-sub"
                      : ""
                  }
                >
                  <a href="/portal/owners" style={{ paddingLeft: "15px" }}>
                    {/* <i className="fas fa-user-tie" /> */}
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="user-tie"
                      className="svg-inline--fa fa-user-tie fa-w-14"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      height="19px"
                      style={{ marginRight: "18px" }}
                    >
                      <path
                        fill="currentColor"
                        d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm95.8 32.6L272 480l-32-136 32-56h-96l32 56-32 136-47.8-191.4C56.9 292 0 350.3 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-72.1-56.9-130.4-128.2-133.8z"
                      ></path>
                    </svg>
                    Owners
                  </a>
                </li>
              ) : null}
              <li
                className={
                  location.pathname === "/requests" || location.pathname === "/"
                    ? "active has-sub"
                    : ""
                }
              >
                <a href="/portal/requests" style={{ paddingLeft: "15px" }}>
                  <i className="fas fa-table" />
                  Requests
                </a>
              </li>
              <li
                className={
                  location.pathname === "/addrequest" ? "active has-sub" : ""
                }
              >
                <a href="/portal/addrequest" style={{ paddingLeft: "15px" }}>
                  <i className="fas fa-user" />
                   Add Request
                </a>
              </li>
              <li
                className={
                  location.pathname === "/calendar" ? "active has-sub" : ""
                }
              >
                <a href="/portal/calendar" style={{ paddingLeft: "15px" }}>
                  <i className="fas fa-calendar-alt" />
                  Calendar
                </a>
              </li>
              {username != "booking@berealibiza.com" ? (
                <li
                  className={
                    location.pathname === "/clientsemails"
                      ? "active has-sub"
                      : ""
                  }
                >
                  <a
                    href="/portal/clientsemails"
                    style={{
                      transform: "translate(-4px, 0)",
                      paddingLeft: "15px",
                    }}
                  >
                    <i className="fas fa-group" />
                    Email Clients
                  </a>
                </li>
              ) : null}
              {username != "booking@berealibiza.com" ? (
                <li
                  className={
                    location.pathname === "/ownersemails"
                      ? "active has-sub"
                      : ""
                  }
                >
                  <a href="/portal/ownersemails" style={{ paddingLeft: "15px" }}>
                    <i className="fas fa-envelope" />
                    Email Owners
                  </a>
                </li>
              ) : null}
              {username != "booking@berealibiza.com" ? (
                <li
                  className={
                    location.pathname === "/yachts" ||
                    location.pathname === "/edityacht" ||
                    location.pathname === "/addyacht"
                      ? "active has-sub"
                      : ""
                  }
                >
                  <a href="/portal/yachts" style={{ paddingLeft: "15px" }}>
                    <i className="fas fa-ship" />
                    Yachts
                  </a>
                </li>
              ) : null}
              {username != "booking@berealibiza.com" ? (
                <li
                  className={
                    location.pathname === "/events" ||
                    location.pathname === "/editevent"
                      ? "active has-sub"
                      : ""
                  }
                >
                  <a href="/portal/events" style={{ paddingLeft: "15px" }}>
                    <i className="fas fa-music" />
                    Events
                  </a>
                </li>
              ) : null}
              {username != "booking@berealibiza.com" ? (
                <li
                  className={
                    location.pathname === "/requestpayment" ||
                    location.pathname === "/invoice"
                      ? "active has-sub"
                      : ""
                  }
                >
                  <a href="/portal/invoice" style={{ paddingLeft: "15px" }}>
                    <i className="far fa-check-square" />
                    Invoices
                  </a>
                </li>
              ) : null}
            </ul>
          </nav>
        </div>
      </aside>
      {/* END MENU SIDEBAR*/}
    </div>
  );
}

export default TopBar;
