import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { authLogout } from "../../../store/actions";
import { connect } from "react-redux";

class Logout extends Component {
  componentDidMount = () => {
    this.props.authLogout();
  };

  render() {
    return <Redirect to="/login" />;
  }
}

const mapDispatchToProps = {
  authLogout
};

export default connect(null, mapDispatchToProps)(Logout);
