import React from "react";
import { withStyles } from "@material-ui/styles";
import { colors } from "../../utils";

const RequestPaymentItem = (props) => {
  return (
    <tr style={{ textAlign: "center" }}>
      <td>
        <div
          style={{
            cursor: "pointer",
            textAlign: "center",
          }}
          onClick={() => props.deleteRequest(props.request.id)}
        >
          <i className="fas fa-trash" />
        </div>
      </td>
      <td style={{ textAlign: "center" }}>{props.request.invoiceNumber}</td>
      <td style={{ textAlign: "center" }}>{props.request.name}</td>
      <td style={{ textAlign: "center" }}>{props.request.email}</td>
      <td style={{ textAlign: "center" }}>{props.request.requestDate}</td>
      <td style={{ textAlign: "center" }}>{props.request.amount}</td>
      <td style={{ textAlign: "center" }}>
        {props.request.status == 0 ? "Not Paid" : "Paid"}
      </td>
      <td style={{ textAlign: "center" }}>{props.request.paidDate ? props.request.paidDate : "-"}</td>
      <td>
        <div
          className={props.classes.sendInvitation}
          style={{ textAlign: "center" }}
          onClick={() => props.paymentLink(props.request.id)}
        >
          Payment Link
        </div>
      </td>
    </tr>
  );
};

const styles = (theme) => ({
  sendInvitation: {
    color: "#808080",
    background: "#f2f2f2",
    borderRadius: "2px",
    lineHeight: "15px",
    padding: "7px",
    cursor: "pointer",
    "&:active": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      backgroundColor: colors.PRIMARY,
      color: colors.WHITE,
    },
  },
});

export default withStyles(styles)(RequestPaymentItem);
