import {Container} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import React from "react";
import {withStyles} from "@material-ui/styles";
import {connect} from "react-redux";
import {colors} from "../../utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";
import {
  getOwnerDetails,
  getOwnerProperties,
  updateOwnerDetails,
  deleteOwner,
} from "../../store/actions";
import Grid from "@material-ui/core/Grid";
import PropertyCard from "../../components/PropertyCard/PropertyCard";
import Pagination from "../../components/Pagination/Pagination";
import TextInput from "../../components/TextInput/TextInput";
import YesNoDialog from "../../components/YesNoDialog/YesNoDialog";

const queryString = require("query-string");

class OwnerDetails extends React.Component {
  state = {
    loading: true,
    params: null,
    owner: null,
    properties: null,
    currentPage: 1,
    isEditClicked: false,
    deleteOwnerLoading: false,
  };

  componentDidMount = async () => {
    let params = queryString.parse(this.props.location.search);
    params = {
      ownerId: Buffer.from(params.o, "base64").toString(),
    };
    this.setState((prevState) => ({
      ...prevState,
      params,
    }));
    this.startLoading();
    const response = await this.props.getOwnerDetails(params.ownerId);
    this.setState((prevState) => ({
      ...prevState,
      owner: response?.owner,
    }));
    const properties = await this.props.getOwnerProperties(params.ownerId);
    console.log(properties);
    this.setState((prevState) => ({
      ...prevState,
      properties,
    }));
    this.stopLoading();
  };

  startLoading = () => {
    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
  };

  stopLoading = () => {
    this.setState((prevState) => ({
      ...prevState,
      loading: false,
    }));
  };

  paginate = async (pageNumber) => {
    this.setState((prevState) => ({
      ...prevState,
      currentPage: pageNumber,
    }));
  };

  editHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      isEditClicked: true,
    }));
  };

  saveHandler = async () => {
    if (
      this.state.owner.website?.substr(0, 4) !== "http" &&
      this.state.owner.website !== ""
    ) {
      console.log("SUBSTR");
      console.log(this.state.owner.website?.substr(0, 3));
      this.setOwnerProperty("website", "http://" + this.state.owner.website);
    }
    const response = await this.props.updateOwnerDetails(this.state.owner);
    console.log(response);
    this.setState((prevState) => ({
      ...prevState,
      isEditClicked: false,
    }));
  };
  cancelHandler = async () => {
    const response = await this.props.getOwnerDetails(
      this.state.params.ownerId,
    );
    this.setState((prevState) => ({
      ...prevState,
      owner: response?.owner,
    }));
    this.setState((prevState) => ({
      ...prevState,
      isEditClicked: false,
    }));
  };

  setOwnerProperty = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      owner: {
        ...prevState.owner,
        [name]: value,
      },
    }));
  };

  showDeleteDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDeleteDialog: true,
    }));
  };

  closeDeleteDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDeleteDialog: false,
    }));
  };

  deleteOwner = async () => {
    try {
      this.setState((prevState) => ({
        ...prevState,
        deleteOwnerLoading: true,
      }));

      await this.props.deleteOwner(this.state.params.ownerId);

      this.setState((prevState) => ({
        ...prevState,
        deleteOwnerLoading: false,
        showDeleteDialog: false,
      }));

      this.props.history.push("owners");
    } catch (err) {
      console.log(err);
    }
  };

  navigateToEditProperty = (id) => (event) => {
    let matchingProperties = this.state.properties;

    let propertyIds = matchingProperties.map((property, index) => property.id);
    this.props.history.push({
      pathname:
        "/editproperty?id=" +
        Buffer.from(id.toString()).toString("base64") +
        "&page=" +
        Buffer.from("properties").toString("base64"),
      state: {propertyIds},
    });
    this.props.history.go(0);
  };

  render() {
    const {classes} = this.props;
    let properties = [];
    let totalPosts = 0;
    if (this.state.properties?.length > 0) {
      totalPosts = this.state.properties.length;
      const indexOfLastProperty = this.state.currentPage * 8;
      const indexOfFirstProperty = indexOfLastProperty - 8;
      properties = this.state.properties.slice(
        indexOfFirstProperty,
        indexOfLastProperty,
      );
      properties = properties.map((property, index) => (
        <Grid key={index} item>
          <PropertyCard
            property={property}
            role={"admin"}
            navigateToEditProperty={this.navigateToEditProperty}
          />
        </Grid>
      ));
    } else {
      properties = (
        <span style={{marginLeft: "2.5rem", color: colors.GREY}}>
          No properties found
        </span>
      );
    }
    return (
      <Container className={classes.root}>
        <YesNoDialog
          visible={this.state.showDeleteDialog}
          onClose={this.closeDeleteDialog}
          title="Are You sure?"
          text="Are you sure you want to delete owner?"
          onAccept={this.deleteOwner}
          onReject={this.closeDeleteDialog}
          deleteLoading={this.deleteOwnerLoading}
        />
        {this.state.owner != null ? (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                width: "100%",
              }}>
              <DefaultButton
                text="Delete"
                style={{height: "42px", marginLeft: "10px"}}
                onClick={this.showDeleteDialog}
              />
            </div>
            <div className={classes.topSection}>
              <div className={classes.avatarContainer}>
                <div className={classes.image} />
                <h5 className={classes.name}>
                  <span>{this.state.owner.name}</span>
                </h5>
              </div>
              <div className={classes.infoContainer}>
                <div>
                  <div className={classes.header}>About</div>
                  <div
                    style={{
                      display: this.state.isEditClicked ? "none" : "flex",
                    }}
                    className={classes.info}>
                    <span>
                      <b className={classes.bold}> Email :</b>{" "}
                      {this.state.owner.email ? this.state.owner.email : "-"}
                    </span>
                    <span>
                      <b className={classes.bold}> Phone :</b>{" "}
                      {this.state.owner.phone ? this.state.owner.phone : "-"}
                    </span>
                    <span>
                      <b className={classes.bold}> Legal Name :</b>{" "}
                      {this.state.owner.legalName
                        ? this.state.owner.legalName
                        : "-"}
                    </span>
                    <span>
                      <b className={classes.bold}> City :</b>{" "}
                      {this.state.owner.city ? this.state.owner.city : "-"}
                    </span>
                    <span>
                      <b className={classes.bold}> Address :</b>{" "}
                      {this.state.owner.address
                        ? this.state.owner.address
                        : "-"}
                    </span>
                    <span>
                      <b className={classes.bold}> Company Number :</b>{" "}
                      {this.state.owner.companyIdNumber
                        ? this.state.owner.companyIdNumber
                        : "-"}
                    </span>
                    <span>
                      <b className={classes.bold}> Language :</b>{" "}
                      {this.state.owner.language
                        ? this.state.owner.language
                        : "-"}
                    </span>
                    <span>
                      <b className={classes.bold}> Website :</b>{" "}
                      {this.state.owner.website ? (
                        <a
                          className={classes.websiteLink}
                          href={this.state.owner.website}
                          target="_blank">
                          {this.state.owner.website}
                        </a>
                      ) : (
                        "-"
                      )}
                    </span>
                    <span>
                      <b className={classes.bold}> Destination :</b>{" "}
                      {this.state.owner.destination
                        ? this.state.owner.destination
                        : "-"}
                    </span>
                  </div>
                  <div
                    style={{
                      display: this.state.isEditClicked ? "flex" : "none",
                    }}
                    className={classes.editInfoContainer}>
                    <div className={classes.inputRow}>
                      <TextInput
                        //width="150%"
                        id="name"
                        type="text"
                        label="Name"
                        value={this.state.owner.name}
                        onChange={(event) =>
                          this.setOwnerProperty("name", event.target.value)
                        }
                        fontSize="1rem"
                      />
                      <TextInput
                        width="20rem"
                        id="email"
                        type="email"
                        label="Email"
                        value={this.state.owner.email}
                        onChange={(event) =>
                          this.setOwnerProperty("email", event.target.value)
                        }
                        fontSize="1rem"
                      />
                      <TextInput
                        //width="150%"
                        id="phone"
                        type="text"
                        label="Phone"
                        value={this.state.owner.phone}
                        onChange={(event) =>
                          this.setOwnerProperty("phone", event.target.value)
                        }
                        fontSize="1rem"
                      />
                    </div>
                    <div className={classes.inputRow}>
                      <TextInput
                        //width="150%"
                        id="leganName"
                        type="text"
                        label="Legal Name"
                        value={this.state.owner.legalName}
                        onChange={(event) =>
                          this.setOwnerProperty("legalName", event.target.value)
                        }
                        fontSize="1rem"
                      />
                      <TextInput
                        //width="150%"
                        id="city"
                        type="text"
                        label="City"
                        value={this.state.owner.city}
                        onChange={(event) =>
                          this.setOwnerProperty("city", event.target.value)
                        }
                        fontSize="1rem"
                      />
                      <TextInput
                        //width="150%"
                        id="address"
                        type="text"
                        label="Address"
                        value={this.state.owner.address}
                        onChange={(event) =>
                          this.setOwnerProperty("address", event.target.value)
                        }
                        fontSize="1rem"
                      />
                    </div>
                    <div className={classes.inputRow}>
                      <TextInput
                        //width="150%"
                        id="companyIdNumber"
                        type="text"
                        label="Company ID Number"
                        value={this.state.owner.companyIdNumber}
                        onChange={(event) =>
                          this.setOwnerProperty(
                            "companyIdNumber",
                            event.target.value,
                          )
                        }
                        fontSize="1rem"
                      />
                      <TextInput
                        //width="150%"
                        id="language"
                        type="text"
                        label="Language"
                        value={this.state.owner.language}
                        onChange={(event) =>
                          this.setOwnerProperty("language", event.target.value)
                        }
                        fontSize="1rem"
                      />
                      <TextInput
                        //width="150%"
                        id="website"
                        type="text"
                        label="Website"
                        value={this.state.owner.website}
                        onChange={(event) =>
                          this.setOwnerProperty("website", event.target.value)
                        }
                        fontSize="1rem"
                      />
                    </div>
                    <div className={classes.inputRow}>
                      <TextInput
                        //width="150%"
                        id="destination"
                        type="text"
                        label="Destination"
                        value={this.state.owner.destination}
                        onChange={(event) =>
                          this.setOwnerProperty(
                            "destination",
                            event.target.value,
                          )
                        }
                        fontSize="1rem"
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}>
                  <div>
                    {this.state.isEditClicked ? (
                      <button
                        className={classes.edit}
                        onClick={this.cancelHandler}>
                        Cancel
                      </button>
                    ) : (
                      <button
                        className={classes.edit}
                        onClick={this.editHandler}>
                        {"Edit"} &nbsp;
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={15}
                          height={15}
                          viewBox="0 0 24 24">
                          <path
                            className="meta-icon"
                            fill={colors.PRIMARY}
                            d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                  <button
                    style={{
                      display: this.state.isEditClicked ? "block" : "none",
                    }}
                    className={classes.save}
                    onClick={this.saveHandler}>
                    {"Save"}
                  </button>
                </div>
              </div>
            </div>
            <div className={classes.proeprtiesContainer}>
              <span className={classes.header}>Related Properties</span>
              <Grid container spacing={3}>
                {properties}
              </Grid>

              <div style={{marginTop: "2rem"}}>
                <Pagination
                  postsPerPage={8}
                  totalPosts={totalPosts}
                  paginate={this.paginate}
                  currentPage={this.state.currentPage}
                />
              </div>
            </div>
          </div>
        ) : (
          <CircularProgress className={classes.circularProgress} />
        )}
      </Container>
    );
  }
}
const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    marginLeft: "11rem",
    minWidth: "1200px",
    paddingTop: 50,
  },
  topSection: {
    display: "flex",
  },
  circularProgress: {
    color: colors.PRIMARY,
    position: "absolute",
    left: "50%",
    top: "50%",
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "1rem",
  },
  image: {
    width: "12rem",
    height: "12rem",
    backgroundImage: `linear-gradient(rgba(203,170,92,0.3), rgba(0,0,0,0)),url("https://berealibiza-properties-images.s3.eu-central-1.amazonaws.com/Logos/avatar.jpg")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    transition: "all 0.4s",
  },
  name: {
    color: colors.BLACK,
    textTransform: "uppercase",
    letterSpacing: "2px",
    fontSize: "1rem",
    fontWeight: "500",
    boxSizing: "content-box",
    width: "12rem",
    textAlign: "center",
    marginTop: "1.5rem",
    zIndex: 1000,
  },
  infoContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "59rem",
    height: "20rem",
    marginLeft: "2rem",
    padding: "1.5rem",
    border: "1px solid #DEDFDF",
    boxShadow: "0px 0px 2px #d7d7d7",
    borderRadius: "2px",
    boxSizing: "border-box",
    letterSpacing: "1px",
    color: colors.DARK_LABEL_GREY,
    fontSize: "0.9rem",
  },
  editInfoContainer: {
    height: "12rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  inputRow: {
    display: "flex",
    width: "50rem",
    justifyContent: "space-between",
  },
  header: {
    fontSize: "1.2rem",
    fontWeight: "500",
    color: colors.DARK_LABEL_GREY,
    marginBottom: "2rem",
    alignSelf: "flex-start",
    letterSpacing: "1px",
  },
  edit: {
    color: colors.PRIMARY,
    backgroundColor: "transparent",
    marginBottom: 0,
    marginRight: "1rem",
    cursor: "pointer",
    textTransform: "uppercase",
    letterSpacing: "1px",
    fontSize: "1rem",
    "&:focus": {
      outline: "none",
    },
  },
  info: {
    display: "flex",
    flexDirection: "column",
  },
  bold: {
    fontWeight: "500",
  },
  proeprtiesContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "73rem",
    margin: "1rem",
    marginTop: "2rem",
    padding: "2rem",
    border: "1px solid #DEDFDF",
    boxShadow: "0px 0px 2px #d7d7d7",
    borderRadius: "2px",
    boxSizing: "border-box",
  },
  websiteLink: {
    color: colors.PRIMARY,
    transition: "all 1s",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
      color: colors.PRIMARY,
    },
    "&:focus": {
      backgroundColor: "transparent",
      textDecoration: "underline",
      color: colors.PRIMARY,
    },
  },
  save: {
    textTransform: "uppercase",
    padding: "5px 20px 5px 20px",
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    borderRadius: "3px",
    "&:focus": {
      outline: "none",
    },
  },
});

const mapStateToProps = (state) => ({
  //   isAuthenticated: state.auth.token
});

const mapDispatchToProps = {
  getOwnerDetails,
  getOwnerProperties,
  updateOwnerDetails,
  deleteOwner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(OwnerDetails)));
