import api from "../../services/api";
import {
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,
  AUTH_LOGOUT,
  AUTH_SET_TOKEN,
  AUTH_REMOVE_TOKEN
} from "./actionTypes";

export const authStart = () => {
  return {
    type: AUTH_START
  };
};

export const authSuccess = (token, authType, user) => {
  api.setAuthToken(token);
  return {
    type: AUTH_SUCCESS,
    token,
    authType,
    user
  };
};

export const authFail = error => {
  return {
    type: AUTH_FAIL,
    error
  };
};

export const auth = authData => async dispatch => {
  dispatch(authStart());
  try {
    const response = await api.signIn(authData);
    dispatch(authSuccess(response.data.token));
    dispatch(checkAuthTimeout(response.data.expiresIn));
  } catch (err) {
    console.log(err);
    dispatch(authFail(err));
  }
};

export const signIn = authData => async dispatch => {
  try {
    let response;

    if (authData.password) response = await api.signIn(authData);
    else response = await api.signInOwner(authData);
    // const response = await api.signIn(authData);

    if (!response.data.token) {
      return response.data;
    } else {
      dispatch(
        authSuccess(
          response.data.token,
          authData.password ? "admin" : "owner",
          response.data.user
        )
      );
      console.log(response.data);
      const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000); // response.data.expiresIn
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("expirationDate", expirationDate);
      localStorage.setItem("authType", authData.password ? "admin" : "owner");
      localStorage.setItem("user", JSON.stringify(response.data.user));
      return { status: "success" };
    }
  } catch (err) {
    console.log(err);
    return {
      status: "error",
      message: "Authentication failed, please try again!"
    };
  }
};

export const authSetToken = token => {
  api.setAuthToken(token);
  return {
    type: AUTH_SET_TOKEN,
    token: token
  };
};

export const authLogout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("authType");
  localStorage.removeItem("user");
  return {
    type: AUTH_LOGOUT
  };
};

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(authLogout());
    }, expirationTime * 1000);
  };
};

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem("token");
    const authType = localStorage.getItem("authType");
    const user = localStorage.getItem("user");
    if (!token) {
      dispatch(authLogout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(authLogout());
      } else {
        dispatch(authSuccess(token, authType, user));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};
