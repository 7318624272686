import {Container} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import React from "react";
import {withStyles} from "@material-ui/styles";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {
  getRenters,
  getProperties,
  sendProperties,
  approveProperties,
} from "../../store/actions";
import PropertiesList from "../../components/PropertiesList/PropertiesList";
import Dropdown from "../../components/Dropdown/Dropdown";
import InputRequest from "../../components/InputRequest/InputRequest";
import {insertRequest} from "../../store/actions";
import {Alert} from "reactstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";
import {colors} from "../../utils";
const styles = (theme) => ({
  container: {},
});

class OldClients extends React.Component {
  state = {
    clients: [],
    request: {
      id: "",
      name: "",
      email: "",
      fromDate: new Date().toISOString().split("T")[0],
      toDate: new Date().toISOString().split("T")[0],
      propertyType: "",
      numberOfPeople: "",
      bedrooms: "",
      budget: "",
      serviceType: [],
      phone: "",
      preferredLocation: "",
      visitedBefore: "",
      paymentMethod: "",
      renterId: null,
    },
    selectedClient: {
      id: "",
    },
    properties: [],
    currentPage: 1,
    propertiesPerPage: 10,
    isSearchAlertVisible: false,
    isSendAlertVisible: false,
    showRequiredMessage: {
      name: false,
      email: false,
      phone: false,
      propertyType: false,
      bedrooms: false,
      budget: false,
      numberOfPeople: false,
    },
    searchLoading: false,
    sendPropertiesLoading: false,
    isPropertiesTableVisible: false,
  };

  componentDidMount = async () => {
    var response = await this.props.getRenters();
    try {
      response = response.map((obj) => ({...obj, isSelected: false}));
      this.setState({
        clients: response,
      });
    } catch (e) {}
  };

  selectClientHandler = async (event) => {
    const selectedId = parseInt(event.target.value);
    var selectedClient = this.state.clients.find(
      (client) => client.id === selectedId,
    );
    let request = this.state.request;
    request.name = selectedClient.name;
    request.email = selectedClient.email;
    request.phone = selectedClient.phone;
    request.preferredLocation = selectedClient.preferred_location;
    request.visitedBefore = selectedClient.visited_ibiza;
    request.serviceType = selectedClient.interested_services.split("|");
    request.renterId = selectedClient.id;
    this.setState((prevState) => ({
      ...prevState,
      request: request,
      selectedClient: selectedClient,
    }));
  };

  setRequestProperty = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      request: {
        ...prevState.request,
        [name]: value,
      },
    }));
  };

  setShowRequiredMessageProperty = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      showRequiredMessage: {
        ...prevState.showRequiredMessage,
        [name]: value,
      },
    }));
  };

  paginate = async (pageNumber) => {
    this.setState({
      currentPage: pageNumber,
    });
  };

  propertyCheckBoxHandler = (event) => {
    const value = event.target.checked;
    const name = event.target.name;
    this.setState((prevState) => ({
      ...prevState,
      properties: prevState.properties.map((obj) =>
        obj.id == name ? {...obj, isSelected: value} : obj,
      ),
    }));
  };

  resetState = () => {
    this.setState({
      request: {
        id: "",
        name: "",
        email: "",
        fromDate: new Date(),
        toDate: new Date(),
        propertyType: "",
        numberOfPeople: "",
        bedrooms: "",
        budget: "",
        serviceType: [],
        phone: "",
        preferredLocation: "",
        visitedBefore: "",
        paymentMethod: "",
        renterId: null,
      },
      selectedClient: {
        id: "",
      },
      properties: [],
      currentPage: 1,
      propertiesPerPage: 10,
      isSearchAlertVisible: false,
      showRequiredMessage: {
        name: false,
        email: false,
        phone: false,
        propertyType: false,
        bedrooms: false,
        budget: false,
        numberOfPeople: false,
      },
      searchLoading: false,
      sendPropertiesLoading: false,
      isPropertiesTableVisible: false,
    });
  };
  sendPropertiesHandler = async () => {
    if (this.state.properties.length > 0) {
      this.startSendPropertiesoading();
      var selectedProperties = this.state.properties.filter(
        (property) => property.isSelected === true,
      );
      selectedProperties = selectedProperties.map((obj) => obj.id);
      const info = {
        propertyIds: selectedProperties,
        requestId: this.state.request.id,
      };
      let response = await this.props.sendProperties(info);
      if (response === "success") {
        this.showSendAlert();
        this.resetState();
      }
      this.stopSendPropertiesLoading();
      this.setState((prevState) => ({
        ...prevState,
        isPropertiesTableVisible: false,
      }));
    }
  };

  searchHandler = async () => {
    if (
      this.state.request.numberOfPeople != "" &&
      this.state.request.bedrooms != "" &&
      this.state.request.budget != "" &&
      this.state.request.propertyType != "" &&
      this.state.request.name != "" &&
      this.state.request.email != "" &&
      this.state.request.phone != ""
    ) {
      this.startSearchLoading();
      const info = {
        ...this.state.request,
        payment: null,
      };
      let response = await this.props.insertRequest(info);
      this.setRequestProperty("id", response.insertId);
      let requestedProperties = await this.props.getProperties(
        this.state.request.id,
        this.state.request.fromDate,
        this.state.request.toDate,
      );
      requestedProperties = requestedProperties.map((obj) => ({
        ...obj,
        isSelected: false,
      }));
      this.setState((prevState) => ({
        ...prevState,
        properties: requestedProperties,
        currentPage: 1,
      }));
      this.setState((prevState) => ({
        ...prevState,
        isPropertiesTableVisible: true,
      }));
      this.showSearchAlert();
      this.setShowRequiredMessageProperty("numberOfPeople", false);
      this.setShowRequiredMessageProperty("bedrooms", false);
      this.setShowRequiredMessageProperty("budget", false);
      this.setShowRequiredMessageProperty("propertyType", false);
      this.setShowRequiredMessageProperty("name", false);
      this.setShowRequiredMessageProperty("email", false);
      this.setShowRequiredMessageProperty("phone", false);
      this.stopSearchLoading();
    } else {
      if (this.state.request.numberOfPeople == "") {
        this.setShowRequiredMessageProperty("numberOfPeople", true);
      } else {
        this.setShowRequiredMessageProperty("numberOfPeople", false);
      }
      if (this.state.request.bedrooms == "") {
        this.setShowRequiredMessageProperty("bedrooms", true);
      } else {
        this.setShowRequiredMessageProperty("bedrooms", false);
      }
      if (this.state.request.budget == "") {
        this.setShowRequiredMessageProperty("budget", true);
      } else {
        this.setShowRequiredMessageProperty("budget", false);
      }
      if (this.state.request.propertyType == "") {
        this.setShowRequiredMessageProperty("propertyType", true);
      } else {
        this.setShowRequiredMessageProperty("propertyType", false);
      }
      if (this.state.request.name == "") {
        this.setShowRequiredMessageProperty("name", true);
      } else {
        this.setShowRequiredMessageProperty("name", false);
      }
      if (this.state.request.email == "") {
        this.setShowRequiredMessageProperty("email", true);
      } else {
        this.setShowRequiredMessageProperty("email", false);
      }
      if (this.state.request.phone == "") {
        this.setShowRequiredMessageProperty("phone", true);
      } else {
        this.setShowRequiredMessageProperty("phone", false);
      }
    }
  };

  showSearchAlert = () => {
    this.setState({isSearchAlertVisible: true});
    setTimeout(
      function () {
        this.setState({isSearchAlertVisible: false});
      }.bind(this),
      3000,
    );
  };

  showSendAlert = () => {
    this.setState({isSendAlertVisible: true});
    setTimeout(
      function () {
        this.setState({isSendAlertVisible: false});
      }.bind(this),
      3000,
    );
  };

  startSearchLoading = () => {
    this.setState((prevState) => ({
      ...prevState,
      searchLoading: true,
    }));
  };

  stopSearchLoading = () => {
    this.setState((prevState) => ({
      ...prevState,
      searchLoading: false,
    }));
  };

  startSendPropertiesoading = () => {
    this.setState((prevState) => ({
      ...prevState,
      sendPropertiesLoading: true,
    }));
  };

  stopSendPropertiesLoading = () => {
    this.setState((prevState) => ({
      ...prevState,
      sendPropertiesLoading: false,
    }));
  };

  resetCurrentPageHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
  };

  propertyDetails = (id, page) => {
    this.props.history.push(
      "editproperty?id=" +
        Buffer.from(id).toString("base64") +
        "&page=" +
        Buffer.from(page).toString("base64"),
    );
  };

  render() {
    let clientsOptions = [];
    let clientsOptionsValues = [];
    if (this.state.clients.length > 0) {
      clientsOptions = this.state.clients.map((client) => client.name);
      clientsOptionsValues = this.state.clients.map((client) => client.id);
    }
    let isClientSelected = this.state.selectedClient.id === "" ? 0 : 1;
    let isAnyPropertySelected = this.state.properties.filter(
      (property) => property.isSelected === true,
    ).length;
    let propertiesPending = this.state.properties.some(
      (property) => property.clientChoice === "Pending",
    );
    return !this.props.isAuthenticated ? (
      <Redirect to="/login" />
    ) : (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          minWidth: 1200,
          padding: 50,
          margin: 0,
          paddingLeft: 216,
        }}>
        <div>
          <div className="column">
            <div className="col-md-12">
              <h3 className="title-5 m-b-35">Past Clients</h3>
              <div
                className="table-data__tool"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}>
                <div className="table-data__tool-left">
                  <Dropdown
                    value={
                      this.state.selectedClient
                        ? this.state.selectedClient.id
                        : ""
                    }
                    onSelect={this.selectClientHandler}
                    label="Select Client"
                    options={clientsOptions}
                    values={clientsOptionsValues}
                  />
                </div>
                <div className="table-responsive table-responsive-data2 remove-scrollbar">
                  <div className="tr-shadow">
                    <InputRequest
                      client={this.state.selectedClient}
                      request={this.state.request}
                      setRequestProperty={this.setRequestProperty}
                      searchHandler={this.searchHandler}
                      isSearchAlertVisible={this.state.isSearchAlertVisible}
                      showRequiredMessage={this.state.showRequiredMessage}
                      // isClientSelected={isClientSelected}
                      loading={this.state.searchLoading}
                    />
                  </div>
                </div>
                <Alert
                  isOpen={this.state.isSendAlertVisible}
                  color="success"
                  style={{
                    zIndex: "100",
                    position: "fixed",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "15px",
                    width: "300px",
                    height: "50px",
                    textAlign: "center",
                  }}>
                  Properties sent successfully!
                </Alert>
                <div
                  style={{
                    transition: "all 0.5s",
                    opacity: this.state.isPropertiesTableVisible ? "1" : "0",
                  }}
                  className="table-responsive table-responsive-data2 remove-scrollbar">
                  <div className="tr-shadow">
                    <div className="row m-t-30">
                      <div className="col-md-12">
                        <div className="table-responsive m-b-40"></div>
                      </div>
                    </div>
                    <PropertiesList
                      page={"addrequest"}
                      properties={this.state.properties}
                      propertiesPerPage={this.state.propertiesPerPage}
                      propertyCheckBoxHandler={this.propertyCheckBoxHandler}
                      currentPage={this.state.currentPage}
                      paginate={this.paginate}
                      resetCurrentPage={this.resetCurrentPageHandler}
                      propertyDetails={this.propertyDetails}
                    />
                  </div>
                  <div className="row user-data__footer">
                    {/* <button className="au-btn au-btn-load">load more</button> */}
                    <div className="col-md-12">
                      <div style={{display: "flex", justifyContent: "center"}}>
                        {this.state.sendPropertiesLoading ? (
                          <CircularProgress style={{color: "#DCAC38"}} />
                        ) : (
                          <DefaultButton
                            text="Send Properties to Client"
                            disabled={
                              !(isAnyPropertySelected && !propertiesPending)
                            }
                            onClick={this.sendPropertiesHandler}
                            style={{
                              background:
                                isAnyPropertySelected && !propertiesPending
                                  ? null
                                  : "#808080",
                              marginBottom: "20px",
                              borderColor:
                                isAnyPropertySelected && !propertiesPending
                                  ? null
                                  : "#808080",
                              color:
                                isAnyPropertySelected && !propertiesPending
                                  ? null
                                  : colors.WHITE,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.token,
});
const mapDispatchToProps = {
  getRenters,
  getProperties,
  sendProperties,
  approveProperties,
  insertRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(OldClients)));
