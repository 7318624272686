import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/`,
});

const setAuthToken = async (token) => {
  axiosInstance.defaults.headers.common.authorization = token;
  return true;
};

const signIn = async (signInInfo) =>
  axiosInstance.post("signin", {
    id: signInInfo.id,
    password: signInInfo.password,
  });

const signInOwner = async (signInInfo) =>
  axiosInstance.post("owner/signin", {
    id: signInInfo.id,
  });

const getStartingSeason = async () => axiosInstance.get("admin/getstartseason");

const getRequestPayments = async (invoiceNumber) =>
  axiosInstance.get("admin/getrequests?", {
    params: {
      invoiceNumber,
    },
  });

const deleteRequest = async (id) =>
  axiosInstance.post("admin/deleterequest", {
    id,
  });

const deleteRenterRequest = async (id) =>
  axiosInstance.post("user/deleterequest", {
    id,
  });

const createRequest = async (requestInfo) =>
  axiosInstance.post("admin/createrequest", {
    requestInfo,
  });

const getInvoices = async () => axiosInstance.get("admin/getinvoices");

const deleteInvoice = async (invoiceNumber) =>
  axiosInstance.post("admin/deleteinvoice", {
    invoiceNumber,
  });

const updateStartingSeason = async (startingSeason) =>
  axiosInstance.post("admin/updatestartingseason", {
    startingSeason,
  });

const changeDates = async (type) =>
  axiosInstance.post("admin/changeDates", {
    type,
  });

const duplicatePrices = async (propertyId) =>
  axiosInstance.post("admin/duplicatePrices", {
    propertyId,
  });

const createInvoice = async (invoice, clientInfo, clientType) =>
  axiosInstance.post("admin/createinvoice", {
    invoice,
    clientInfo,
    clientType,
  });

const updateOwnerDetails = async (owner) =>
  axiosInstance.post("admin/updateownerdetails", {
    owner,
  });

const getRequests = async () => axiosInstance.get("user/getrequests");

const getCalendarRequests = async () =>
  axiosInstance.get("user/getcalendarrequests");

const getOwners = async () => axiosInstance.get("user/getowners");

const getRenters = async () => axiosInstance.get("user/getrenters");

const getProperties = async (id, from, to) =>
  axiosInstance.post("user/getproperties", {id, from, to});

const getAllProperties = async () => axiosInstance.get("user/getallproperties");

const addProperty = async (propertyInfo) =>
  axiosInstance.post("owner/addproperty", {
    propertyInfo,
  });

const saveRequestInfo = async (data) =>
  axiosInstance.post("user/saverequestinfo", {
    requests: data.requests,
    renters: data.renters,
  });

const sendProperties = async (info) =>
  axiosInstance.post("user/sendproperties", {
    propertyIds: info.propertyIds,
    requestId: info.requestId,
  });

const approveProperties = async (info) =>
  axiosInstance.post("user/approveproperties", {
    propertyIds: info.propertyIds,
    requestId: info.requestId,
  });

const updatePaymentMethod = async (info) =>
  axiosInstance.post("user/updatepaymentmethod", {
    paymentMethod: info.paymentMethod,
    requestId: info.requestId,
  });
const sendOwnersEmail = async (owners, subject, body) =>
  axiosInstance.post("user/sendownersemail", {
    owners: owners,
    subject: subject,
    body: body,
  });

const sendClientsEmail = async (clients, subject, body) =>
  axiosInstance.post("user/sendclientsemail", {
    clients: clients,
    subject: subject,
    body: body,
  });

const getPropertyDetails = async (propertyId, requestId) =>
  axiosInstance.get(`user/propertydetails?`, {
    params: {
      propertyId,
      requestId,
    },
  });

const getOwnerPropertyDetails = async (propertyId) => {
  return await axiosInstance.post(`owner/ownerpropertydetails`, {
    propertyId: propertyId,
  });
};

const getCalendarEvents = async (propertyId) => {
  return await axiosInstance.post(`owner/getcalendarevents`, {
    propertyId: propertyId,
  });
};

const updateAvailabiliyEvents = async (updateAvailabiliy) => {
  return await axiosInstance.post(`owner/updateavailability`, {
    eventIds: updateAvailabiliy.eventIds,
    eventsUpdate: updateAvailabiliy.eventsUpdate,
    eventInfo: updateAvailabiliy.eventInfo,
  });
};

const updatePriceEvents = async (updatePrice) => {
  return await axiosInstance.post(`owner/updateprice`, {
    eventIds: updatePrice.eventIds,
    eventsUpdate: updatePrice.eventsUpdate,
    eventInfo: updatePrice.eventInfo,
  });
};

const updateProperty = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === "files" && data[key].length > 0)
      data[key].forEach((file) => formData.append(`files`, file));
    else formData.append(key, data[key]);
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  return axiosInstance.post(`owner/updateproperty`, formData, config);
};

const updatePropertyLocation = async (long, lat, propertyId) =>
  axiosInstance.post("owner/updatepropertylocation", {
    longitude: long,
    latitude: lat,
    propertyId: propertyId,
  });

const selectProperty = async (info) =>
  axiosInstance.post("user/selectproperty", info);

const insertRequest = async (info) => {
  const response = await axiosInstance.post("user/insertrequest", {
    ...info,
  });
  return response.data;
};

const removePropertyPhoto = (propertyId, imageId) =>
  axiosInstance.post("owner/deletepropertyimage", {
    propertyId,
    imageId,
  });

const getOwnerProperties = async (ownerId) => {
  const response = await axiosInstance.get("owner/getproperties", {
    params: {
      ownerId,
    },
  });
  return response.data.properties;
};

const getOwnerDetails = async (ownerId) => {
  const response = await axiosInstance.get("admin/getownerdetails", {
    params: {
      ownerId,
    },
  });
  return response.data;
};

const sendOwnersPropertiesLink = async (owners) =>
  axiosInstance.post("admin/sendownerspropertieslink", {
    owners,
  });

const updateClientServices = (info) =>
  axiosInstance.post("user/updateclientservices", {
    ...info,
  });

const updateRating = (info) =>
  axiosInstance.post("user/updaterating", {
    ...info,
  });

const getPropertyImagesAdmin = (propertyId) =>
  axiosInstance.get("admin/propertyimages", {
    params: {
      propertyId,
    },
  });

const getPropertyImagesOwner = (propertyId) =>
  axiosInstance.get("owner/propertyimages", {
    params: {
      propertyId,
    },
  });

const updatePropertyImagesAdmin = (info) =>
  axiosInstance.post("admin/propertyimagesorder", info);

const updatePropertyImagesOwner = (info) =>
  axiosInstance.post("owner/propertyimagesorder", info);

const updatePropertyDefaultImageAdmin = (info) =>
  axiosInstance.post("admin/updatepropertydefaultimage", info);

const updatePropertyDefaultImageOwner = (info) =>
  axiosInstance.post("owner/updatepropertydefaultimage", info);

const getRequestIsRated = async (r) =>
  axiosInstance.get(`user/getrequestisrated?`, {
    params: {
      r,
    },
  });

const getAllYachts = () => axiosInstance.get("user/allyachts");

const getAllEvents = () => axiosInstance.get("user/allevents");

const getEventDetails = (eventId) =>
  axiosInstance.get("admin/eventdetails", {
    params: {
      eventId,
    },
  });

const updateEvent = async (event) =>
  axiosInstance.post("admin/updateevent", event);

const deleteEvent = (eventId) =>
  axiosInstance.post("admin/deleteevent", {
    eventId,
  });

const getYachtDetails = (yachtId) =>
  axiosInstance.get("admin/yachtdetails", {
    params: {
      yachtId,
    },
  });

const updateYacht = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === "files" && data[key].length > 0)
      data[key].forEach((file) => formData.append(`files`, file));
    else formData.append(key, data[key]);
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  return axiosInstance.post(`admin/updateyacht`, formData, config);
};

const removeYachtPhoto = (yachtId, imageId) =>
  axiosInstance.post("admin/removeyachtphoto", {
    yachtId,
    imageId,
  });

const deleteYacht = (yachtId) =>
  axiosInstance.post("admin/deleteyacht", {
    yachtId,
  });

const deleteProperty = (refName) =>
  axiosInstance.post("admin/deleteproperty", {
    refName,
  });

const deleteOwner = (ownerId) =>
  axiosInstance.post("admin/deleteowner", {
    ownerId,
  });

const updateYachtDefaultImage = (info) =>
  axiosInstance.post("admin/updateyachtdefaultimage", info);

const addOwner = (owner) => axiosInstance.post("admin/addowner", owner);

export default {
  setAuthToken,
  signIn,
  getStartingSeason,
  updateStartingSeason,
  changeDates,
  duplicatePrices,
  getRequests,
  getProperties,
  sendProperties,
  approveProperties,
  updatePaymentMethod,
  saveRequestInfo,
  getOwners,
  getRenters,
  sendOwnersEmail,
  sendClientsEmail,
  getPropertyDetails,
  selectProperty,
  insertRequest,
  getOwnerPropertyDetails,
  updateProperty,
  updatePropertyLocation,
  signInOwner,
  removePropertyPhoto,
  getOwnerProperties,
  sendOwnersPropertiesLink,
  getCalendarEvents,
  getCalendarRequests,
  updateAvailabiliyEvents,
  updatePriceEvents,
  getAllProperties,
  updateClientServices,
  updateRating,
  getPropertyImagesAdmin,
  getPropertyImagesOwner,
  updatePropertyImagesAdmin,
  updatePropertyImagesOwner,
  updatePropertyDefaultImageAdmin,
  updatePropertyDefaultImageOwner,
  getRequestIsRated,
  addProperty,
  getOwnerDetails,
  updateOwnerDetails,
  getAllYachts,
  getAllEvents,
  getEventDetails,
  updateEvent,
  getYachtDetails,
  updateYacht,
  removeYachtPhoto,
  updateYachtDefaultImage,
  createInvoice,
  getInvoices,
  deleteInvoice,
  getRequestPayments,
  deleteRequest,
  deleteRenterRequest,
  createRequest,
  deleteYacht,
  deleteEvent,
  addOwner,
  deleteOwner,
  deleteProperty,
};
