import {Container, CardMedia} from "@material-ui/core";
import {Link, withRouter, Redirect} from "react-router-dom";
import React, {createRef} from "react";
import {withStyles} from "@material-ui/styles";
import {connect} from "react-redux";
import {colors, getImageBase64} from "../../utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextInput from "../../components/TextInput/TextInput";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";
import {
  getOwnerPropertyDetails,
  updateProperty,
  removePropertyPhoto,
  deleteProperty,
  duplicatePrices,
} from "../../store/actions";
import "./EditProperty.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import Dropdown from "../../components/Dropdown/Dropdown";
import CheckBoxDropdown from "../../components/CheckBoxDropdown/CheckBoxDropdown";
import {Alert} from "reactstrap";
import DropFiles from "../../components/DropFiles/DropFiles";
import YesNoDialog from "../../components/YesNoDialog/YesNoDialog";
import Pagination from "../../components/Pagination/Pagination";
import TextField from "@material-ui/core/TextField";
import {Map, GoogleApiWrapper} from "google-maps-react";
const queryString = require("query-string");

const CssTextField = withStyles({
  root: {
    "input:-internal-autofill-selected": {
      backgroundColor: "#fff",
    },
    "& label.Mui-focused": {
      color: "#e6b74d",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#BBB",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBB",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e6b74d",
      },
    },
  },
})(TextField);

const styles = (theme) => ({
  circularProgress: {
    color: colors.PRIMARY,
    position: "absolute",
    left: "50%",
    top: "50%",
  },
  buttonLoading: {
    color: colors.PRIMARY,
  },
});

class EditProperty extends React.Component {
  state = {
    loading: false,
    propertyId: "",
    requestId: "",
    renterId: "",
    property: null,
    images: [],
    index: 0,
    direction: 0,
    showAlert: false,
    alertStatus: "",
    alertMessage: "",
    showDragAndDrop: false,
    showDialog: false,
    files: [],
    propertyPage: "",
    reference: null,
    isGeneratePDFClicked: false,
    deleteLoading: false,
    deletePropertyLoading: false,
    firstIndex: false,
    lastIndex: false,
  };

  componentDidMount = async () => {
    let role = localStorage.getItem("authType");
    let params = queryString.parse(this.props.location.search);
    let propertyId = Buffer.from(params.id, "base64").toString();
    let propertyPage = Buffer.from(params.page, "base64").toString();
    let propertyIds = this.props.location.state.propertyIds;
    let index = propertyIds.indexOf(Number(propertyId));

    this.setState((prevState) => ({
      ...prevState,
      propertyId,
      propertyPage,
      role,
      propertyIds,
      firstIndex: index === 0,
      lastIndex: index === propertyIds.length - 1,
    }));

    const response = await this.props.getOwnerPropertyDetails(propertyId);

    if (response.status && response.status == "error") {
      console.log(response.data.message);
    } else {
      console.log("No. of Images: ", response.images?.length);
      let owners = response.owners.map((owner) => owner.name);
      let ownersValues = response.owners.map((owner) => owner.id);
      this.setState((prevState) => ({
        ...prevState,
        property: {
          ...response.property,
          features: response.property.features
            ? response.property.features.split(",")
            : [],
        },
        owners: owners,
        ownersValues: ownersValues,
      }));
    }
  };

  handleSelect = (selectedIndex, e) => {
    this.setState((prevState) => ({
      ...prevState,
      index: selectedIndex,
      direction: e.direction,
    }));
  };

  updatePropertyHandler = async () => {
    try {
      this.setState((prevState) => ({
        ...prevState,
        loading: true,
        property: {
          ...prevState.property,
          description: prevState.property.description.trim(),
          layout: prevState.property.layout.trim(),
        },
      }));

      const response = await this.props.updateProperty({
        ...this.state.property,
        propertyId: this.state.propertyId,
        files: this.state.files,
      });

      this.setState((prevState) => ({
        ...prevState,
        loading: false,
        showDragAndDrop: false,
        files: [],
        property: {
          ...prevState.property,
          images: response.images ? response.images : [],
        },
      }));
    } catch (err) {
      this.setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  setProperty = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        [name]: value,
      },
    }));
  };

  featureCheckBoxHandler = (event) => {
    const value = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        features: value,
      },
    }));
  };

  toggleDragAndDrop = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDragAndDrop: !prevState.showDragAndDrop,
      ...(prevState.showDragAndDrop ? {files: []} : {}),
    }));
  };

  onDrop = (files) => {
    this.setState((prevState) => ({
      ...prevState,
      files,
    }));
  };

  showDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDialog: true,
    }));
  };

  closeDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDialog: false,
    }));
  };

  removePhoto = async () => {
    try {
      this.setState((prevState) => ({
        ...prevState,
        deleteLoading: true,
      }));

      let imageId = this.state.property.images[this.state.index];

      const response = await this.props.removePropertyPhoto(
        this.state.propertyId,
        imageId,
      );

      this.setState((prevState) => ({
        ...prevState,
        showDialog: false,
        deleteLoading: false,
        property: {
          ...prevState.property,
          images: response.images ? response.images : [],
        },
        ...(response.images && prevState.index === response.images.length
          ? {index: response.images.length - 1}
          : {}),
      }));
    } catch (err) {
      console.log(err);
    }
  };

  paginate = async (pageNumber) => {
    this.setState((prevState) => ({
      ...prevState,
      index: pageNumber - 1,
    }));
  };

  generateBrandedPDF = async () => {
    var queryString =
      "p=" +
      Buffer.from(this.state.propertyId).toString("base64") +
      `&view=false&branded=true`;
    const generatePdfLink = "generatepropertypdf?";

    window.open(generatePdfLink + queryString);
  };

  generateUnbrandedPDF = async () => {
    var queryString =
      "p=" +
      Buffer.from(this.state.propertyId).toString("base64") +
      `&view=false&branded=false`;
    const generatePdfLink = "generatepropertypdf?";

    window.open(generatePdfLink + queryString);
  };

  previewProperty = async (event) => {
    var queryString = "villa=" + this.state.property.refName;

    const previewLink = process.env.REACT_APP_SERVER_URL + "/propertysingle?";
    window.open(previewLink + queryString);
  };

  navigateMaps = async () => {
    this.props.history.push({
      pathname: this.state.property
        ? this.state.role == "admin"
          ? "/map?id=" +
            Buffer.from(this.state.propertyId.toString()).toString("base64") +
            `&page=${Buffer.from(this.state.propertyPage).toString("base64")}` +
            `&lat=${Buffer.from(
              this.state.property.latitude
                ? this.state.property.latitude.toString()
                : "",
            ).toString("base64")}` +
            `&long=${Buffer.from(
              this.state.property.longitude
                ? this.state.property.longitude.toString()
                : "",
            ).toString("base64")}`
          : "/owner/map?id=" +
            Buffer.from(this.state.propertyId.toString()).toString("base64") +
            `&page=${Buffer.from(this.state.propertyPage).toString("base64")}` +
            `&lat=${Buffer.from(
              this.state.property.latitude
                ? this.state.property.latitude.toString()
                : "",
            ).toString("base64")}` +
            `&long=${Buffer.from(
              this.state.property.longitude
                ? this.state.property.longitude.toString()
                : "",
            ).toString("base64")}`
        : "",
      state: {propertyIds: this.state.propertyIds},
    });
    this.props.history.go(0);
  };

  navigateSetPrice = async () => {
    this.props.history.push({
      pathname:
        this.state.role == "admin"
          ? "/propertycalendar?id=" +
            Buffer.from(this.state.propertyId.toString()).toString("base64") +
            `&page=${Buffer.from(this.state.propertyPage).toString("base64")}`
          : "/owner/propertycalendar?id=" +
            Buffer.from(this.state.propertyId.toString()).toString("base64") +
            `&page=${Buffer.from(this.state.propertyPage).toString("base64")}`,
      state: {propertyIds: this.state.propertyIds},
    });
    this.props.history.go(0);
  };

  previousProperty = async (event) => {
    let index = this.state.propertyIds.indexOf(Number(this.state.propertyId));
    let id = this.state.propertyIds[index - 1];
    this.props.history.push({
      pathname:
        this.state.role == "admin"
          ? "/editproperty?id=" +
            Buffer.from(id.toString()).toString("base64") +
            "&page=" +
            Buffer.from("properties").toString("base64")
          : "/owner/editproperty?id=" +
            Buffer.from(id.toString()).toString("base64") +
            "&page=" +
            Buffer.from("owner/properties").toString("base64"),
      state: {propertyIds: this.state.propertyIds},
    });
    this.props.history.go(0);
  };

  nextProperty = async (event) => {
    let index = this.state.propertyIds.indexOf(Number(this.state.propertyId));
    let id = this.state.propertyIds[index + 1];
    this.props.history.push({
      pathname:
        this.state.role == "admin"
          ? "/editproperty?id=" +
            Buffer.from(id.toString()).toString("base64") +
            "&page=" +
            Buffer.from("properties").toString("base64")
          : "/owner/editproperty?id=" +
            Buffer.from(id.toString()).toString("base64") +
            "&page=" +
            Buffer.from("owner/properties").toString("base64"),
      state: {propertyIds: this.state.propertyIds},
    });
    this.props.history.go(0);
  };

  duplicatePrices = async (propertyId) => {
    const status = await this.props.duplicatePrices(propertyId);
    if (status.status == "fail") {
      //alert
    }
  };

  showDeleteDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDeleteDialog: true,
    }));
  };

  closeDeleteDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDeleteDialog: false,
    }));
  };

  deleteProperty = async () => {
    try {
      this.setState((prevState) => ({
        ...prevState,
        deletePropertyLoading: true,
      }));

      await this.props.deleteProperty(this.state.property.refName);

      this.setState((prevState) => ({
        ...prevState,
        deletePropertyLoading: false,
        showDeleteDialog: false,
      }));

      this.props.history.push("properties");
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const {classes} = this.props;
    let carouselItems = [];
    var imgSources = [];
    for (var i = 0; i < 9; i++) {
      imgSources[i] = "";
    }
    if (this.state.property?.images?.length) {
      carouselItems = this.state.property.images.map((image, index) => {
        if (index <= 8) {
          imgSources[index] = `${process.env.REACT_APP_AWS_BUCKET_URL}/${
            this.state.property.refName
          }/${image}?t=${Date.now()}`;
        }
        return (
          <Carousel.Item key={`carousel-${index}`}>
            <img
              className="d-block w-100 img"
              src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${
                this.state.property.refName
              }/${image}?t=${Date.now()}`}
            />
          </Carousel.Item>
        );
      });
    }
    this.state.reference = createRef(null);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minWidth: 1200,
          paddingTop: 50,
          margin: 0,
          paddingLeft: this.state.role == "admin" ? 216 : 0,
          //   padding: 50
        }}>
        <YesNoDialog
          visible={this.state.showDeleteDialog}
          onClose={this.closeDeleteDialog}
          title="Are You sure?"
          text="Are you sure you want to delete property?"
          onAccept={this.deleteProperty}
          onReject={this.closeDeleteDialog}
          deleteLoading={this.deletePropertyLoading}
        />
        <YesNoDialog
          visible={this.state.showDialog}
          onClose={this.closeDialog}
          title="Are you sure?"
          text="Are you sure you want to delete this photo?"
          onAccept={this.removePhoto}
          onReject={this.closeDialog}
          deleteLoading={this.state.deleteLoading}
        />
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}>
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                color: colors.PRIMARY,
                zIndex: 1000,
                marginTop: this.state.role == "admin" ? "1rem" : "30px",
              }}
              onClick={this.navigateMaps}>
              <div className="s-property-content">
                <p
                  style={{
                    color: colors.PRIMARY,
                  }}>
                  Maps
                </p>
              </div>
              <i
                className="fas fa-map-marker-alt"
                style={{marginLeft: "5px", marginTop: "4px"}}></i>
            </div>
            <div>
              <DefaultButton
                text="Preview"
                style={{height: "42px", marginTop: 0, marginBottom: 0}}
                onClick={this.previewProperty}
              />
              {this.state.role === "admin" ? (
                <DefaultButton
                  text="Delete"
                  style={{
                    height: "42px",
                    marginLeft: "10px",
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                  onClick={this.showDeleteDialog}
                />
              ) : null}
              {this.state.role === "owner" ? (
                <DefaultButton
                  text="Back"
                  style={{
                    height: "42px",
                    marginLeft: "10px",
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                  onClick={() => this.props.history.push("/owner/properties")}
                />
              ) : null}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}>
            <DefaultButton
              text="Previous"
              style={{
                height: "42px",
                marginTop: 0,
                marginBottom: 0,
                background: this.state.firstIndex ? "#808080" : null,
                borderColor: this.state.firstIndex ? "#808080" : null,
                color: this.state.firstIndex ? "#FFF" : null,
              }}
              onClick={this.previousProperty}
              disabled={this.state.firstIndex}
            />
            <div style={{display: "flex", flexDirection: "row"}}>
              <DefaultButton
                text="Duplicate Prices"
                style={{
                  height: "42px",
                  marginLeft: "10px",
                  marginTop: 0,
                  marginBottom: 0,
                }}
                onClick={() => {
                  this.duplicatePrices(this.state.propertyId);
                }}
              />
              <DefaultButton
                text="Next"
                style={{
                  height: "42px",
                  marginLeft: "10px",
                  marginTop: 0,
                  marginBottom: 0,
                  background: this.state.lastIndex ? "#808080" : null,
                  borderColor: this.state.lastIndex ? "#808080" : null,
                  color: this.state.lastIndex ? "#FFF" : null,
                }}
                onClick={this.nextProperty}
                disabled={this.state.lastIndex}
              />
            </div>
          </div>
          {this.state.property != null ? (
            // <div>
            <div
              className="content-area single-property single-property-wrapper single-property-header"
              style={{
                backgroundColor: "#FCFCFC",
                width: "100%",
                padding: 20,
                fontWeight: 300,
              }}>
              {this.state.property?.images?.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between", //flex-start
                    alignItems: "flex-end",
                    padding: 0,
                  }}>
                  <div />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                      padding: 0,
                    }}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          height: "20px",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>
                        <div
                          style={{cursor: "pointer", marginBottom: 15}}
                          onClick={() =>
                            this.props.history.push(
                              `${
                                this.state.role === "owner" ? "/owner" : ""
                              }/manageimages?pid=${Buffer.from(
                                this.state.propertyId,
                              ).toString("base64")}&page=${Buffer.from(
                                this.state.propertyPage,
                              ).toString("base64")}`,
                            )
                          }>
                          <div style={{display: "flex", flexDirection: "row"}}>
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                color: colors.PRIMARY,
                                marginRight: 5,
                                marginTop: 2,
                              }}>
                              Reorder
                            </p>
                            <span className="property-info-icon icon-reorder">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24">
                                <path
                                  className="meta-icon"
                                  fill={colors.PRIMARY}
                                  d="M3 15h18v-2H3v2zm0 4h18v-2H3v2zm0-8h18V9H3v2zm0-6v2h18V5H3"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                        <p>{`${this.state.index + 1} / ${
                          this.state.property.images.length
                        }`}</p>
                        <div
                          className="s-property-content"
                          style={{cursor: "pointer"}}
                          onClick={this.showDialog}>
                          <p
                            style={{
                              padding: 0,
                              color: "red",
                            }}>
                            Remove
                          </p>
                        </div>
                      </div>
                      <Carousel
                        activeIndex={this.state.index}
                        direction={this.state.direction}
                        onSelect={this.handleSelect}
                        interval={null}
                        style={{
                          height: "340px",
                          width: "500px",
                          backgroundColor: colors.BLACK,
                          marginBottom: 10,
                        }}>
                        {carouselItems}
                      </Carousel>
                      <Pagination
                        postsPerPage={1}
                        totalPosts={this.state.property.images.length}
                        paginate={this.paginate}
                        currentPage={this.state.index + 1}
                      />
                    </div>
                  </div>
                  <div
                    style={{marginRight: 10, cursor: "pointer"}}
                    onClick={this.toggleDragAndDrop}>
                    {this.state.showDragAndDrop ? (
                      <div className="s-property-content">
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            color: colors.PRIMARY,
                          }}>
                          Cancel
                        </p>
                      </div>
                    ) : (
                      <div style={{display: "flex", flexDirection: "row"}}>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            color: colors.PRIMARY,
                            marginRight: 5,
                            marginTop: 2,
                          }}>
                          Add image
                        </p>
                        <span className="property-info-icon icon-add-to-photos">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24">
                            <path
                              className="meta-icon"
                              fill={colors.PRIMARY}
                              d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2z"
                            />
                          </svg>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
              {this.state.showDragAndDrop ||
              this.state.property?.images == null ? (
                <div style={{marginTop: 15}}>
                  <DropFiles onDrop={this.onDrop} files={this.state.files} />
                </div>
              ) : null}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "4%",
                }}>
                <TextInput
                  id="assetName"
                  label="Property Name"
                  type="text"
                  value={this.state.property.assetName}
                  onChange={(event) =>
                    this.setProperty("assetName", event.target.value)
                  }
                />
                <TextInput
                  id="price"
                  label="Price"
                  type="number"
                  value={this.state.property.price}
                  onChange={(event) =>
                    this.setProperty("price", event.target.value)
                  }
                />
                <TextInput
                  //width="150%"
                  id="licenseNumber"
                  type="text"
                  label="License Number"
                  value={this.state.property.licenseNumber}
                  onChange={(event) =>
                    this.setProperty("licenseNumber", event.target.value)
                  }
                />
                <TextInput
                  id="location"
                  label="Location"
                  type="text"
                  value={this.state.property.locationName}
                  onChange={(event) =>
                    this.setProperty("locationName", event.target.value)
                  }
                />
                <TextInput
                  id="area"
                  label="Area"
                  type="text"
                  value={this.state.property.area}
                  onChange={(event) =>
                    this.setProperty("area", event.target.value)
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "4%",
                  marginBottom: "2%",
                }}>
                <TextInput
                  id="longitude"
                  label="Longitude"
                  type="number"
                  value={this.state.property.longitude}
                  onChange={(event) =>
                    this.setProperty("longitude", event.target.value)
                  }
                />
                <TextInput
                  id="latitude"
                  label="Latitude"
                  type="number"
                  value={this.state.property.latitude}
                  onChange={(event) =>
                    this.setProperty("latitude", event.target.value)
                  }
                />
                <TextInput
                  id="bedrooms"
                  label="Bedrooms"
                  type="number"
                  value={this.state.property.noOfBedrooms}
                  onChange={(event) =>
                    this.setProperty("noOfBedrooms", event.target.value)
                  }
                />
                <TextInput
                  id="bathrooms"
                  label="Bathrooms"
                  type="number"
                  value={this.state.property.noOfBathrooms}
                  onChange={(event) =>
                    this.setProperty("noOfBathrooms", event.target.value)
                  }
                />
                <TextInput
                  id="destination"
                  label="Destination"
                  type="text"
                  value={this.state.property.destination}
                  onChange={(event) =>
                    this.setProperty("destination", event.target.value)
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}>
                <div className="section">
                  <h4 className="s-property-title">Type</h4>
                  <div className="s-property-content">
                    <Dropdown
                      value={this.state.property.viewType}
                      onSelect={(event) =>
                        this.setProperty("viewType", event.target.value)
                      }
                      //label="Select type"
                      options={[
                        "Countryside",
                        "Panoramic Nature",
                        "Panoramic Sea View",
                        "Partial Sea View",
                        "Sea View",
                      ]}
                    />
                  </div>
                </div>
                <div
                  className="section property-features"
                  style={{
                    color: "#929FB2",
                    fontFamily: "Open Sans, sans-serif",
                  }}>
                  <h4 className="s-property-title">Features</h4>
                  <CheckBoxDropdown
                    value={"-"}
                    onSelect={this.featureCheckBoxHandler}
                    values={this.state.property.features}
                    //label="Select type"
                    options={[
                      "Heating",
                      "Air conditioning",
                      "Barbeque",
                      "Beach towels provided",
                      "Central heating",
                      "Coffee Machine",
                      "Dishwasher",
                      "DVD player",
                      "Fully equipped kitchen",
                      "Garden",
                      "Gym",
                      "High - Speed Internet",
                      "Infinity Pool",
                      "Internet access",
                      "Jacuzzi",
                      "Linen provided",
                      "Microwave oven",
                      "Music system",
                      "Open fire",
                      "Private pool",
                      "Room fans",
                      "Telephone",
                      "Tennis court",
                      "Terrace / balcony",
                      "Toaster",
                      "Towels provided",
                      "TV",
                      "TV Satalite",
                      "Underfloor Heating",
                      "Washing machine",
                      "Wifi",
                      "Car not essential",
                      "Car recommended",
                      "Child friendly",
                      "Close to airport",
                      "Close to golf course",
                      "Close to historic sites",
                      "Close to shops",
                      "Close to subway",
                      "Close to train station",
                      "Electronic security",
                      "Highchair available",
                      "House swap available",
                      "Long term let available",
                      "Maid service available",
                      "Near to beach",
                      "No security deposit",
                      "Off road parking",
                      "Panoramic views",
                      "Parking",
                      "Pet friendly",
                      "SeaView",
                      "Secure complex",
                      "Smoking permitted",
                      "Wheelchair friendly",
                    ]}
                  />
                </div>
                <div className="section">
                  <h4 className="s-property-title">Security Deposit</h4>
                  <div className="s-property-content">
                    <Dropdown
                      value={this.state.property.securityDeposit}
                      onSelect={(event) =>
                        this.setProperty("securityDeposit", event.target.value)
                      }
                      //label="Select type"
                      options={["1st Payment", "2nd Payment"]}
                    />
                  </div>
                </div>
                <div className="section">
                  <h4 className="s-property-title">Active</h4>
                  <div className="s-property-content">
                    <Dropdown
                      value={this.state.property.active}
                      onSelect={(event) =>
                        this.setProperty("active", event.target.value)
                      }
                      //label="Select type"
                      options={["Yes", "No", "PDF"]}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}>
                <CssTextField
                  style={{borderColor: "#cd943c"}}
                  id="standard-multiline-static"
                  label={
                    "Description " +
                    this.state.property.description.length +
                    "/1200"
                  }
                  multiline
                  rows="6"
                  value={this.state.property.description}
                  onChange={(event) =>
                    this.setProperty("description", event.target.value)
                  } //{this.bodyHandler}
                  fullWidth
                  variant="outlined"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "2%",
                }}>
                <CssTextField
                  style={{borderColor: "#cd943c"}}
                  id="standard-multiline-static"
                  label={
                    "Layout " + this.state.property.layout.length + "/1200"
                  }
                  multiline
                  rows="6"
                  value={this.state.property.layout}
                  onChange={(event) =>
                    this.setProperty("layout", event.target.value)
                  } //{this.bodyHandler}
                  fullWidth
                  variant="outlined"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  marginTop: "2%",
                }}>
                {this.state.role == "admin" ? (
                  <div className="section">
                    <h4 className="s-property-title">Owner</h4>
                    <div className="s-property-content">
                      {/* <p>{this.state.property.ownerName}</p> */}
                      <Dropdown
                        value={this.state.property.ownerId}
                        onSelect={(event) => {
                          this.setProperty("ownerId", event.target.value);
                        }}
                        values={this.state.ownersValues}
                        label=""
                        options={this.state.owners}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="section">
                    <h4 className="s-property-title">Owner</h4>
                    <div className="s-property-content">
                      <p>{this.state.property.ownerName}</p>
                    </div>
                  </div>
                )}
                <div className="section">
                  <h4 className="s-property-title">Email</h4>
                  <div className="s-property-content">
                    <p>{this.state.property.ownerEmail}</p>
                  </div>
                </div>
                <div className="section">
                  <h4 className="s-property-title">Status</h4>
                  <div className="s-property-content">
                    <p>
                      {this.state.property.reserved != "0"
                        ? "Reserved"
                        : "Available"}
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}>
                <DefaultButton
                  text="Set Price/Availability"
                  onClick={this.navigateSetPrice}
                />
                <DefaultButton
                  text="Branded PDF"
                  onClick={this.generateBrandedPDF}
                />
                <DefaultButton
                  text="Unbranded PDF"
                  onClick={this.generateUnbrandedPDF}
                />
                {this.state.loading ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "5px",
                      justifyContent: "center",
                    }}>
                    <CircularProgress className={classes.buttonLoading} />
                  </div>
                ) : (
                  <DefaultButton
                    text="Update Property"
                    onClick={this.updatePropertyHandler}
                  />
                )}
              </div>
            </div>
          ) : (
            <CircularProgress className={classes.circularProgress} />
          )}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  getOwnerPropertyDetails,
  updateProperty,
  removePropertyPhoto,
  deleteProperty,
  duplicatePrices,
};

const WrappedContainer = GoogleApiWrapper({
  apiKey: "AIzaSyCNUWGEGi67QJiT2ANzwAALc9ZCCwiyUhM",
})(EditProperty);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(WrappedContainer)));
