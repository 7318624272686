import React from "react";
import { withStyles } from "@material-ui/styles";
import { colors } from "../../utils";
const yachtCard = (props) => {
  const { classes } = props;
  return (
    <a
      href={
        "/portal/edityacht?id=" +
        Buffer.from(props.yacht.id.toString()).toString("base64") +
        "&page=" +
        Buffer.from("yachts").toString("base64")
      }
      className={classes.card}
    >
      <div
        style={{
          backgroundImage: props.yacht.defaultImage
            ? `url(${process.env.REACT_APP_AWS_BUCKET_URL}/yachts/${
                props.yacht.id
              }/${props.yacht.defaultImage.replace(" ", "%20")}?t=${Date.now()})`
            : `url(${process.env.PUBLIC_URL}/assets/images/bri-logo.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className={classes.imageContainer}
      />
      <div className={classes.infoContainer}>
        <h5 className={classes.header}>
          <span className={classes.anchor}>{props.yacht.name}</span>
        </h5>
        <div className={classes.context}>
          <span>
            <b> Capacity :</b> {props.yacht.capacity || "N/A"}
          </span>
        </div>
        <div className={classes.context}>
          <span>
            <b> Length :</b> {props.yacht.length || "N/A"}
          </span>
        </div>
        <div className={classes.context}>
          <span>
            <b> Price :</b>
            {props.yacht.price ? "€" + props.yacht.price : "N/A"}
          </span>
        </div>
        <div className={classes.context}>
          <span>
            <b> Fuel :</b> {props.yacht.fuel || "N/A"}
          </span>
        </div>
      </div>
    </a>
  );
};

const styles = (theme) => ({
  card: {
    width: "16rem",
    height: "24rem",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    color: "#717171",
    fontSize: "20px",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0rem 0rem 0.5rem rgba(0,0,0,0.2)",
    borderRadius: "2px",
    cursor: "pointer",
    opacity: "0.9",
    transition: "all 0.4s",
    "&:hover": {
      opacity: "0.7",
      color: colors.PRIMARY,
      textDecoration: "none",
      backgroundColor: colors.WHITE,
    },
  },
  imageContainer: {
    width: "100%",
    height: "62%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    transition: "all 0.4s",
  },
  infoContainer: {
    width: "100%",
    boxSizing: "border-box",
    padding: "0.5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    height: "51%",
  },
  header: {
    alignSelf: "flex-start",
    color: "black",
    textTransform: "uppercase",
    letterSpacing: "2px",
    fontSize: "0.9rem",
    fontWeight: "700",
    boxSizing: "content-box",
    paddingBottom: "0.5rem",
    borderBottom: "1px dotted #CECBC7",
    width: "100%",
  },
  anchor: {},
  context: {
    display: "flex",
    fontSize: "0.9rem",
    justifyContent: "space-between",
    width: "100%",
  },
});

export default withStyles(styles)(yachtCard);
