import { Container } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import React from "react";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { colors } from "../../utils";
import { Redirect } from "react-router-dom";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    '&::-webkit-scrollbar' : {
      display: 'none'
    }
  },
  textField: {
    width: 400,
    "& label.Mui-focused": {
      color: colors.PRIMARY
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.PRIMARY
    }
  },
  welcome: {
    marginTop: 100,
    color: "#717171",
    fontSize: "20px"
  },
  circularProgress: { color: colors.PRIMARY }
});

class Home extends React.Component {
  state = {
    loading: false
  };

  //   onIdChanged = e => {
  //     const value = e.target.value;
  //     this.setState(prevState => ({
  //       ...prevState,
  //       id: value
  //     }));
  //     if (value !== "" && this.state.controls.username.error === "Empty")
  //       this.usernameErrorHandler("");
  //   };

  startLoading = () => {
    this.setState(prevState => ({
      ...prevState,
      loading: true
    }));
  };

  stopLoading = () => {
    this.setState(prevState => ({
      ...prevState,
      loading: false
    }));
  };

  render() {
    const { classes } = this.props;

    return !this.props.isAuthenticated ? (
      <Redirect to="/login" />
    ) : (
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 50
        }}
      >
        <p className={classes.welcome}>Welcome!</p>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.token
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(Home)));
