//export const serverUrl = "http://localhost:8000";
// export const serverUrl = "http://prod-env.qkkfe58z2x.us-east-1.elasticbeanstalk.com";
export const serverURL = process.env.SERVER_URL;

export const addMonths = (date, months) => {
  var d = date.getDate();
  date.setMonth(date.getMonth() + months);
  if (date.getDate() != d) {
    date.setDate(0);
  }
  date = date.toISOString();
  return date;
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getImageBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

export const colors = {
  WHITE: 'white',
  BLACK: 'black',
  PRIMARY: '#CBAA5C',
  LIGHT_PRIMARY: '#f6b74d',
  NAVY: '#07164A',
  SKY_BLUE: '#1BADF2',
  ORANGE: '#E64F22',
  RED: '#ff5151',
  // GREEN: "#00FF00",
  GREEN: '#00AD5F',
  SECONDARY_GREEN: '#1EB954',
  DARK_LABEL_GREY: '#606060',
  BACKGROUND_GREY: '#EEE',
  DISABLED_GREY: '#AAA',
  YELLOW: '#FF0',

  // #757575
  GREY: '#929FB2',
  LIGHT_GREY: '#F2F3F4',
  HOVER_GREY: '#E9ECEF',
  TEXT_GREY: '#3F3F3F',
  TEXT_BLACK: '#1C1616',
  OFF_WHITE: '#F6F7FB',
  TRANSPARENT: 'transparent',
};

export const errorHandler = (message) => {
  return {
    status: 'error',
    message,
  };
};

export const incr_date = (date_str) => {
  var parts = date_str.split('-');
  var dt = new Date(
    parseInt(parts[0], 10), // year
    parseInt(parts[1], 10) - 1, // month (starts with 0)
    parseInt(parts[2], 10), // date
  );
  dt.setDate(dt.getDate() + 1);
  parts[0] = '' + dt.getFullYear();
  parts[1] = '' + (dt.getMonth() + 1);
  if (parts[1].length < 2) {
    parts[1] = '0' + parts[1];
  }
  parts[2] = '' + dt.getDate();
  if (parts[2].length < 2) {
    parts[2] = '0' + parts[2];
  }
  return parts.join('-');
};

export const getFirstWeekDayDate = (weekDay, month, year) => {
  let tempDate = new Date();
  tempDate.setHours(0, 0, 0, 0);
  tempDate.setMonth(month);
  tempDate.setYear(year);
  tempDate.setDate(1);
  let day = tempDate.getDay();
  let toNextWeekDay = (day > weekDay ? 7 : 0) + weekDay - day;
  tempDate.setDate(tempDate.getDate() + toNextWeekDay);
  var date = new Date(tempDate),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    dd = ('0' + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, dd].join('-');
  //return tempDate.toDateString();
};

export const decr_date = (date_str) => {
  var parts = date_str.split('-');
  var dt = new Date(
    parseInt(parts[0], 10), // year
    parseInt(parts[1], 10) - 1, // month (starts with 0)
    parseInt(parts[2], 10), // date
  );
  dt.setDate(dt.getDate() - 1);
  parts[0] = '' + dt.getFullYear();
  parts[1] = '' + (dt.getMonth() + 1);
  if (parts[1].length < 2) {
    parts[1] = '0' + parts[1];
  }
  parts[2] = '' + dt.getDate();
  if (parts[2].length < 2) {
    parts[2] = '0' + parts[2];
  }
  return parts.join('-');
};

export const isValidDate = (date) => {
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!date.match(regEx)) return false; // Invalid format
  var d = new Date(date);
  var dNum = d.getTime();
  if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === date;
};
