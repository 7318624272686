import React from "react";
import {withStyles} from "@material-ui/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import {colors} from "../../utils";

const Dropdown = (props) => {
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);
  const {classes} = props;
  let menuItems = props.options.map((option, index) => (
    <MenuItem
      key={index}
      className={classes.menuItem}
      value={props.values ? props.values[index] : option}
      disabled={props.type == "interestedServices" ? true : false}>
      {option}
    </MenuItem>
  ));
  let selectProps = {};
  if (props.value != "-") {
    selectProps.value = props.value;
  }
  return props.tableDropdown ? (
    <CssTableFormControl
      variant="outlined"
      className={classes.select}
      style={props.styles}
      error={props.error ? true : false}>
      <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
        {props.label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={props.value === "-" ? "" : props.value}
        // {...selectProps}
        onChange={props.onSelect}
        labelWidth={labelWidth}>
        {props.defaultValue ? (
          <MenuItem
            disabled
            className={classes.menuItem}
            value={props.defaultValue}>
            <em>{props.defaultValue}</em>
          </MenuItem>
        ) : null}
        {menuItems}
      </Select>
      <FormHelperText>{props.error ? props.helperText : false}</FormHelperText>
    </CssTableFormControl>
  ) : (
    <CssFormControl
      variant="outlined"
      className={classes.select}
      style={props.styles}
      error={props.error ? true : false}>
      <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
        {props.label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={props.value === "-" ? "" : props.value}
        // {...selectProps}
        onChange={props.onSelect}
        labelWidth={labelWidth}>
        {props.defaultValue ? (
          <MenuItem
            disabled
            className={classes.menuItem}
            value={props.defaultValue}>
            <em>{props.defaultValue}</em>
          </MenuItem>
        ) : null}
        {menuItems}
      </Select>
      <FormHelperText>{props.error ? props.helperText : false}</FormHelperText>
    </CssFormControl>
  );
};

const styles = (theme) => ({
  select: {
    marginBottom: "20px",
    width: "12rem",
  },
  menuItem: {
    "&:hover": {
      backgroundColor: colors.PRIMARY,
      color: colors.WHITE,
    },
  },
});
const formItemStyle = {
  root: {
    "& label.Mui-focused": {
      color: colors.PRIMARY,
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#999",
        borderWidth: "1px",
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.PRIMARY,
        borderWidth: "1px",
      },
    },
  },
};

const formTableItemStyle = {
  root: {
    "& label.Mui-focused": {
      color: colors.PRIMARY,
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#999",
        borderWidth: "1px",
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.PRIMARY,
        borderWidth: "1px",
      },
    },
    // "& .MuiSelect-outlined.MuiSelect-outlined": {
    //   padding: 0,
    // },
    // "& .MuiSelect-select.MuiSelect-select": {
    //   padding: 0,
    // },
    "& .MuiOutlinedInput-input": {
      padding: 8,
    },
  },
};
const CssFormControl = withStyles(formItemStyle)(FormControl);
const CssTableFormControl = withStyles(formTableItemStyle)(FormControl);

export default withStyles(styles)(Dropdown);
