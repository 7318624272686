import { Container, CardMedia } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import React from "react";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { colors } from "../../utils";
import { Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getPropertyDetails, selectProperty } from "../../store/actions/user";
import "./PropertyDetails.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import { Alert } from "reactstrap";
const queryString = require("query-string");

const styles = theme => ({
  circularProgress: {
    color: colors.PRIMARY,
    position: "absolute",
    left: "50%",
    top: "50%"
  },
  buttonLoading: {
    color: colors.PRIMARY
  }
});

class PropertyDetails extends React.Component {
  state = {
    loading: false,
    propertyId: "",
    requestId: "",
    renterId: "",
    property: null,
    // property: {
    //   assetName: "",
    //   price: "",
    //   reserved: "",
    //   locationName: "",
    //   assetName: "",
    //   area: "",
    //   noOfBedrooms: "",
    //   noOfBathrooms: "",
    //   viewType: ""
    // },
    images: [],
    index: 0,
    direction: 0,
    showAlert: false,
    alertStatus: "",
    alertMessage: ""
  };

  componentDidMount = async () => {
    let params = queryString.parse(this.props.location.search);
    params = {
      propertyId: Buffer.from(params.p, "base64").toString(),
      requestId: Buffer.from(params.rq, "base64").toString(),
      renterId: Buffer.from(params.r, "base64").toString()
    };
    this.setState(prevState => ({
      ...prevState,
      ...params
    }));
    const response = await this.props.getPropertyDetails(
      params.propertyId,
      params.requestId
    );

    console.log(response);

    if (response.status && response.status == "error") {
      // this.showAlert(
      //   "success",
      //   "An error occured while processing your request. Please contact our support team."
      // );
      console.log(response.data.message);
    } else {
      this.setState(prevState => ({
        ...prevState,
        property: response
      }));
    }
  };

  startLoading = () => {
    this.setState(prevState => ({
      ...prevState,
      loading: true
    }));
  };

  stopLoading = () => {
    this.setState(prevState => ({
      ...prevState,
      loading: false
    }));
  };

  handleSelect = (selectedIndex, e) => {
    this.setState(prevState => ({
      ...prevState,
      index: selectedIndex,
      direction: e.direction
    }));
  };

  showAlert = (alertStatus, alertMessage) => {
    this.setState({ showAlert: true, alertStatus, alertMessage });
    setTimeout(
      function() {
        this.setState({ showAlert: false, alertStatus: "", alertMessage: "" });
      }.bind(this),
      3000
    );
  };

  selectPropertyHandler = async () => {
    try {
      this.setState(prevState => ({
        ...prevState,
        loading: true
      }));

      const response = await this.props.selectProperty({
        requestId: this.state.requestId,
        propertyId: this.state.propertyId
      });
      if (response == "success") {
        this.setState(prevState => ({
          ...prevState,
          property: { ...prevState.property, status: 1 }
        }));
      } else {
        // this.showAlert(
        //   "success",
        //   "An error occured while processing your request. Please contact our support team."
        // );
      }
      this.setState(prevState => ({
        ...prevState,
        loading: false
      }));
    } catch (err) {
      this.setState(prevState => ({
        ...prevState,
        loading: false
      }));
    }
  };

  render() {
    const { classes } = this.props;

    let features = [];
    if (this.state.property != null)
      features = this.state.property.features
        .split(",")
        .map((feature, index) => (
          <li key={`li-feature-${index}`}>
            <a>{feature.trim()}</a>
          </li>
        ));

    let carouselItems = [];
    if (this.state.property?.images?.length) {
      carouselItems = this.state.property.images.map((image, index) => (
        <Carousel.Item key={`carousel-${index}`}>
          <img
            className="d-block w-100 img"
            src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${this.state.property.refName}/${image}?t=${Date.now()}`}
          />
        </Carousel.Item>
      ));
    }

    return (
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
          //   padding: 50
        }}
      >
        <Alert
          isOpen={this.state.showAlert}
          color={this.state.alertStatus}
          style={{
            position: "absolute",
            left: "50%",
            // transform: "translate(-50%, -50%)",
            fontSize: "15px",
            width: "300px",
            height: "50px",
            textAlign: "center"
          }}
        >
          {this.state.alertMessage}
        </Alert>
        {/* <ImageSwipe images={this.state.images} /> */}
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%"
            // padding: 50
          }}
        >
          {/* ############### received ############## */}
          {/* <div>
        <div style={{marginTop: '100px', color: '#cd943c', fontFamily: '"Abril Fatface", "Palatino Linotype", "Book Antiqua", Palatino, serif', lineHeight: '1.2', paddingTop: '10px', paddingRight: '10px', paddingBottom: '5px', paddingLeft: '10px'}}>
          <div style={{fontSize: '12px', lineHeight: '1.2', fontFamily: '"Abril Fatface", "Palatino Linotype", "Book Antiqua", Palatino, serif', color: '#cd943c', msoLineHeightAlt: '14px'}}>
            <p style={{fontSize: '28px', lineHeight: '1.2', textAlign: 'center', wordBreak: 'break-word', fontFamily: 'Abril Fatface, Palatino Linotype, Book Antiqua, Palatino, serif', msoLineHeightAlt: '34px', margin: 0}}>
              <span style={{fontSize: '28px'}}>We received your request sucessfully!</span></p>
          </div>
        </div>
        <div style={{color: '#555555', fontFamily: '"Chivo", Arial, Helvetica, sans-serif', lineHeight: '1.5', paddingTop: '5px', paddingRight: '10px', paddingBottom: '10px', paddingLeft: '10px'}}>
          <div style={{fontSize: '12px', lineHeight: '1.5', fontFamily: '"Chivo", Arial, Helvetica, sans-serif', color: '#555555', msoLineHeightAlt: '18px'}}>
            <p style={{fontSize: '14px', lineHeight: '1.5', textAlign: 'center', wordBreak: 'break-word', fontFamily: 'Chivo, Arial, Helvetica, sans-serif', msoLineHeightAlt: '21px', margin: 0}}>
              Your request is being taken care of. Thank you for choosing Be Real Ibiza.</p>
          </div>
        </div>
      </div> */}
          {/* ############### received ############## */}
          {this.state.property != null ? (
            // <div>
            <div
              className="content-area single-property single-property-wrapper single-property-header"
              style={{
                backgroundColor: "#FCFCFC",
                width: "100%",
                padding: 20,

                // fontFamily: "Open Sans, sans-serif",
                fontWeight: 300
                // height:100%;
                // padding:0;
                // margin:0;
                // font-size:14px;
                // line-height: 28px;
                // color: #777;
                // background: #fff;
                // position: relative;
              }}
            >
              {/* <ImageSwipe images={this.state.images} /> */}

              {this.state.property?.images?.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 0
                  }}
                >
                  <Carousel
                    activeIndex={this.state.index}
                    direction={this.state.direction}
                    onSelect={this.handleSelect}
                    style={{
                      height: "340px",
                      width: "500px",
                      backgroundColor: colors.BLACK
                    }}
                  >
                    {carouselItems}
                  </Carousel>
                </div>
              ) : null}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between"
                  //   width: "32%"
                }}
              >
                {/* <div
                    className="clearfix padding-top-40"
                    style={{ width: "100vw" }}
                  >
                    <div
                      className="col-md-8 single-property-content"
                      style={{ width: "100vw" }}
                    >
                      <div
                        className="single-property-wrapper"
                        style={{ width: "100vw" }}
                      >
                        <div
                          className="single-property-header"
                          style={{ width: "100vw" }}
                        > */}
                <div>
                  <h1
                    className="property-title pull-left"
                    style={{ marginRight: 100 }}
                  >
                    {this.state.property.assetName}
                  </h1>
                  <h6
                    className="property-title pull-left"
                    style={{ 
                      marginRight: 100 ,
                      fontSize: "1.6rem",
                      color: this.state.property.active === "Yes" ? colors.SECONDARY_GREEN : colors.RED  
                    }}
                  >
                    {this.state.property.active === "Yes" ? "Active" : "Inactive"}
                  </h6>
                </div>
                <span
                  style={{ height: "100%" }}
                  className="property-price pull-right"
                >
                  {`${
                    this.state.property.price != ""
                      ? `$${this.state.property.price}`
                      : ""
                  }`}
                </span>
              </div>
              <div
                className="row property-meta entry-meta clearfix"
                //   style={{
                // width: "100vw",
                // display: "flex",
                // flexDirection: "row",
                // justifyContent: "space-between"
                //   }}
              >
                <div className="col-xs-3 col-sm-3 col-md-3 p-b-15">
                  <span className="property-info-icon icon-tag">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={30}
                      height={30}
                      viewBox="0 0 48 48"
                    >
                      <path
                        className="meta-icon"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        fill={colors.PRIMARY}
                        d="M47.199 24.176l-23.552-23.392c-.504-.502-1.174-.778-1.897-.778l-19.087.09c-.236.003-.469.038-.696.1l-.251.1-.166.069c-.319.152-.564.321-.766.529-.497.502-.781 1.196-.778 1.907l.092 19.124c.003.711.283 1.385.795 1.901l23.549 23.389c.221.218.482.393.779.523l.224.092c.26.092.519.145.78.155l.121.009h.012c.239-.003.476-.037.693-.098l.195-.076.2-.084c.315-.145.573-.319.791-.539l18.976-19.214c.507-.511.785-1.188.781-1.908-.003-.72-.287-1.394-.795-1.899zm-35.198-9.17c-1.657 0-3-1.345-3-3 0-1.657 1.343-3 3-3 1.656 0 2.999 1.343 2.999 3 0 1.656-1.343 3-2.999 3z"
                      />
                    </svg>
                  </span>
                  <span className="property-info-entry">
                    <span
                      className="property-info-label"
                      style={{ marginTop: 10 }}
                    >
                      Status
                    </span>
                    <span className="property-info-value">
                      {this.state.property.reserved != "0"
                        ? "Reserved"
                        : "Available"}
                    </span>
                  </span>
                </div>
                <div className="col-xs-3 col-sm-3 col-md-3 p-b-15">
                  <span className="property-info icon-area">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={30}
                      height={30}
                      viewBox="0 0 48 48"
                    >
                      <path
                        className="meta-icon"
                        fill={colors.PRIMARY}
                        d="M46 16v-12c0-1.104-.896-2.001-2-2.001h-12c0-1.103-.896-1.999-2.002-1.999h-11.997c-1.105 0-2.001.896-2.001 1.999h-12c-1.104 0-2 .897-2 2.001v12c-1.104 0-2 .896-2 2v11.999c0 1.104.896 2 2 2v12.001c0 1.104.896 2 2 2h12c0 1.104.896 2 2.001 2h11.997c1.106 0 2.002-.896 2.002-2h12c1.104 0 2-.896 2-2v-12.001c1.104 0 2-.896 2-2v-11.999c0-1.104-.896-2-2-2zm-4.002 23.998c0 1.105-.895 2.002-2 2.002h-31.998c-1.105 0-2-.896-2-2.002v-31.999c0-1.104.895-1.999 2-1.999h31.998c1.105 0 2 .895 2 1.999v31.999zm-5.623-28.908c-.123-.051-.256-.078-.387-.078h-11.39c-.563 0-1.019.453-1.019 1.016 0 .562.456 1.017 1.019 1.017h8.935l-20.5 20.473v-8.926c0-.562-.455-1.017-1.018-1.017-.564 0-1.02.455-1.02 1.017v11.381c0 .562.455 1.016 1.02 1.016h11.39c.562 0 1.017-.454 1.017-1.016 0-.563-.455-1.019-1.017-1.019h-8.933l20.499-20.471v8.924c0 .563.452 1.018 1.018 1.018.561 0 1.016-.455 1.016-1.018v-11.379c0-.132-.025-.264-.076-.387-.107-.249-.304-.448-.554-.551z"
                      />
                    </svg>
                  </span>
                  <span className="property-info-entry">
                    <span
                      className="property-info-label"
                      style={{ marginTop: 10 }}
                    >
                      Location
                    </span>
                    <span className="property-info-value">
                      {this.state.property.locationName}
                      {/* <b className="property-info-unit">
                        {this.state.property.locationName}
                      </b> */}
                    </span>
                  </span>
                </div>
                <div className="col-xs-3 col-sm-3 col-md-3 p-b-15">
                  <span className="property-info-icon icon-bed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={30}
                      height={30}
                      viewBox="0 0 48 48"
                    >
                      <path
                        className="meta-icon"
                        fill={colors.PRIMARY}
                        d="M21 48.001h-19c-1.104 0-2-.896-2-2v-31c0-1.104.896-2 2-2h19c1.106 0 2 .896 2 2v31c0 1.104-.895 2-2 2zm0-37.001h-19c-1.104 0-2-.895-2-1.999v-7.001c0-1.104.896-2 2-2h19c1.106 0 2 .896 2 2v7.001c0 1.104-.895 1.999-2 1.999zm25 37.001h-19c-1.104 0-2-.896-2-2v-31c0-1.104.896-2 2-2h19c1.104 0 2 .896 2 2v31c0 1.104-.896 2-2 2zm0-37.001h-19c-1.104 0-2-.895-2-1.999v-7.001c0-1.104.896-2 2-2h19c1.104 0 2 .896 2 2v7.001c0 1.104-.896 1.999-2 1.999z"
                      />
                    </svg>
                  </span>
                  <span className="property-info-entry">
                    <span
                      className="property-info-label"
                      style={{ marginTop: 10 }}
                    >
                      Bedrooms
                    </span>
                    <span className="property-info-value">
                      {this.state.property.noOfBedrooms}
                    </span>
                  </span>
                </div>
                <div className="col-xs-3 col-sm-3 col-md-3 p-b-15">
                  <span className="property-info-icon icon-bath">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={30}
                      height={30}
                      viewBox="0 0 48 48"
                    >
                      <path
                        className="meta-icon"
                        fill={colors.PRIMARY}
                        d="M37.003 48.016h-4v-3.002h-18v3.002h-4.001v-3.699c-4.66-1.65-8.002-6.083-8.002-11.305v-4.003h-3v-3h48.006v3h-3.001v4.003c0 5.223-3.343 9.655-8.002 11.305v3.699zm-30.002-24.008h-4.001v-17.005s0-7.003 8.001-7.003h1.004c.236 0 7.995.061 7.995 8.003l5.001 4h-14l5-4-.001.01.001-.009s.938-4.001-3.999-4.001h-1s-4 0-4 3v17.005000000000003h-.001z"
                      />
                    </svg>
                  </span>
                  <span className="property-info-entry">
                    <span
                      className="property-info-label"
                      style={{ marginTop: 10 }}
                    >
                      Bathrooms
                    </span>
                    <span className="property-info-value">
                      {this.state.property.noOfBathrooms}
                    </span>
                  </span>
                </div>
              </div>
              {/* .property-meta */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%"
                }}
              >
                <div className="section">
                  <h4 className="s-property-title">Description</h4>
                  <div className="s-property-content">
                    <p>{this.state.property.description}</p>
                  </div>
                </div>

                {/* End description area  */}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%"
                }}
              >
                <div
                  className="section property-features"
                  style={{
                    color: "#929FB2",
                    fontFamily: "Open Sans, sans-serif"
                  }}
                >
                  <h4 className="s-property-title">Features</h4>
                  <ul>{features}</ul>
                </div>
                <div className="section">
                  <h4 className="s-property-title">Type</h4>
                  <div className="s-property-content">
                    <p>{this.state.property.viewType}</p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-end"
                }}
              >
                <div className="section" style={{ padding: 0, margin: 0 }}>
                  <h4 className="s-property-title">License Number</h4>
                  <div className="s-property-content">
                    <p style={{ padding: 0, margin: 0 }}>
                      {this.state.property.licenseNumber}
                    </p>
                    {/* <p>{this.state.property.ownerEmail}</p>
                  <p>{this.state.property.ownerPhone}</p>
                  <p>{`Name: ${this.state.property.ownerName}`}</p>
                  <p>{`Email: ${this.state.property.ownerEmail}`}</p>
                  <p>{`Phone: ${this.state.property.ownerPhone}`}</p> */}
                  </div>
                </div>
                {this.state.loading ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "20%"
                    }}
                  >
                    <CircularProgress className={classes.buttonLoading} />
                  </div>
                ) : (
                  <button
                    className="au-btn au-btn-load"
                    onClick={this.selectPropertyHandler}
                    disabled={this.state.property.status}
                    style={{
                      background: this.state.property.status
                        ? colors.WHITE
                        : colors.PRIMARY, // "#FFA600",
                      color: this.state.property.status
                        ? colors.PRIMARY
                        : colors.WHITE,
                      border: "none"
                      // alignSelf: "flex-start"
                    }}
                  >
                    {this.state.property.status
                      ? "Liked!"
                      : "Like this Property!"}
                  </button>
                )}
              </div>
              {/* End features area  */}
            </div>
          ) : (
            //   </div>
            //       </div>
            //     </div>
            //   </div>
            //   Footer area
            // </div>
            <CircularProgress className={classes.circularProgress} />
          )}
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  //   isAuthenticated: state.auth.token
});

const mapDispatchToProps = {
  getPropertyDetails,
  selectProperty
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(PropertyDetails)));
