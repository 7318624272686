import {Container} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import React from "react";
import {withStyles} from "@material-ui/styles";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {
  getOwners,
  sendOwnersEmail,
  sendOwnersPropertiesLink,
} from "../../store/actions";
import Pagination from "../../components/Pagination/Pagination";
import TextField from "@material-ui/core/TextField";
import {Alert} from "reactstrap";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";
import {colors} from "../../utils";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#e6b74d",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#BBB",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBB",
      },
      "&:hover fieldset": {
        borderColor: "#e6b74d",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e6b74d",
      },
    },
  },
})(TextField);

const styles = (theme) => ({
  container: {},
});

class OwnersBulkEmail extends React.Component {
  state = {
    owners: [],
    currentPage: 1,
    ownersPerPage: 10,
    allOwners: false,
    body: "",
    subject: "",
    visible: false,
    searchName: "",
    searchDestination: "",
  };

  componentDidMount = async () => {
    var response = await this.props.getOwners();
    response = response.map((obj) => ({...obj, isSelected: false}));
    this.setState({
      owners: response,
    });
  };

  paginate = async (pageNumber) => {
    this.setState({
      currentPage: pageNumber,
    });
  };

  ownerCheckBoxHandler = (event) => {
    const value = event.target.checked;
    const name = event.target.name;
    this.setState((prevState) => ({
      ...prevState,
      owners: prevState.owners.map((obj) =>
        obj.id == name ? {...obj, isSelected: value} : obj,
      ),
    }));
  };

  allOwnerCheckBoxHandler = (event) => {
    const value = event.target.checked;
    this.setState((prevState) => ({
      ...prevState,
      allOwners: value,
      owners: prevState.owners.map((obj) => ({...obj, isSelected: value})),
    }));
  };

  bodyHandler = (event) => {
    const value = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      body: value,
    }));
  };

  subjectHandler = (event) => {
    const value = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      subject: value,
    }));
  };

  sendEmailsHandler = async () => {
    var selectedOwners = this.state.owners.filter(
      (owner) => owner.isSelected === true,
    );
    console.log(selectedOwners);
    const result = await this.props.sendOwnersEmail(
      selectedOwners,
      this.state.subject,
      this.state.body,
    );
    if (result == "success") {
      this.onShowAlert();
      this.resetState();
    }
  };

  sendPropertiesLinkHandler = async () => {
    var selectedOwners = this.state.owners.filter(
      (owner) => owner.isSelected === true,
    );
    console.log(selectedOwners);
    const result = await this.props.sendOwnersPropertiesLink(selectedOwners);
    if (result == "success") {
      this.onShowAlert();
      this.resetState();
    }
  };

  onShowAlert = () => {
    this.setState({visible: true});
    setTimeout(
      function () {
        this.setState({visible: false});
      }.bind(this),
      3000,
    );
  };

  resetState = () => {
    this.setState((prevState) => ({
      ...prevState,
      owners: prevState.owners.map((obj) => ({...obj, isSelected: false})),
      currentPage: 1,
      allOwners: false,
      body: "",
      subject: "",
    }));
  };

  searchTermChangedHandler = (name, event) => {
    this.setState((prevState) => ({
      ...prevState,
      [name]: event.target.value,
      currentPage: 1,
    }));
  };

  render() {
    let matchingOwners = this.state.owners.filter((owner) => {
      if (
        owner.name
          .toLowerCase()
          .includes(this.state.searchName.toLowerCase()) &&
        (owner.destination || this.state.searchDestination == "") &&
        (this.state.searchDestination == "" ||
          owner.destination
            .toLowerCase()
            .includes(this.state.searchDestination.toLowerCase()))
      ) {
        return owner;
      }
      return;
    });
    const indexOfLastOwner = this.state.currentPage * this.state.ownersPerPage;
    const indexOfFirstOwner = indexOfLastOwner - this.state.ownersPerPage;
    const currentOwners = matchingOwners.slice(
      indexOfFirstOwner,
      indexOfLastOwner,
    );
    let ownersList = [];
    if (currentOwners.length > 0) {
      ownersList = currentOwners.map((owner, index) => (
        <tr key={index}>
          <td style={{textAlign: "center"}}>
            <label className="au-checkbox">
              <input
                name={owner.id}
                type="checkbox"
                checked={owner.isSelected}
                onChange={this.ownerCheckBoxHandler}
              />
              <span className="au-checkmark" />
            </label>
          </td>
          <td>{owner.name}</td>
          <td>{owner.email}</td>
          <td>{owner.phone}</td>
          <td>{owner.destination}</td>
          <td>{owner.last_email_sent}</td>
        </tr>
      ));
    }
    let isAnyOwnerSelected = matchingOwners.filter(
      (owner) => owner.isSelected === true,
    ).length;

    const {classes} = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          minWidth: 1200,
          padding: 50,
          margin: 0,
          paddingLeft: 216,
        }}>
        <div
          style={{
            textAlign: "center",
            width: "100%",
          }}>
          <div>
            <div className="section__content section__content--p30">
              <div className="container-fluid">
                <div className="row">
                  <label className="au-checkbox" style={{color: "#717171"}}>
                    Email Message
                  </label>
                </div>
                <div
                  className="row"
                  style={{
                    marginBottom: "10px",
                  }}>
                  <CssTextField
                    id="standard-basic"
                    label="Subject"
                    value={this.state.subject}
                    onChange={this.subjectHandler}
                    fullWidth
                    variant="outlined"
                    style={{background: "#fff"}}
                  />
                </div>
                <div className="row">
                  <CssTextField
                    id="standard-multiline-static"
                    label="Body"
                    multiline
                    rows="8"
                    value={this.state.body}
                    onChange={this.bodyHandler}
                    fullWidth
                    variant="outlined"
                  />
                </div>
                <div className="row">
                  <Alert
                    isOpen={this.state.visible}
                    color="success"
                    style={{
                      zIndex: "100",
                      position: "fixed",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "15px",
                      width: "300px",
                      height: "50px",
                      textAlign: "center",
                    }}>
                    Email Sent Successfully!
                  </Alert>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive table-responsive-data2 remove-scrollbar">
                      <div className="tr-shadow">
                        <div className="row m-t-30">
                          <div className="col-md-12">
                            <div className="table-responsive m-b-40"></div>
                          </div>
                        </div>
                        <table className="table table-borderless table-data3">
                          <thead>
                            <tr>
                              <th>
                                <label
                                  className="au-checkbox"
                                  style={{
                                    marginLeft: "25px",
                                    marginBottom: "20px",
                                  }}>
                                  <input
                                    name="allOwners"
                                    type="checkbox"
                                    checked={this.state.allOwners}
                                    onChange={this.allOwnerCheckBoxHandler}
                                  />
                                  <span className="au-checkmark" />
                                </label>
                              </th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th>Destination</th>
                              <th>Last email sent</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td></td>
                              <td
                                colSpan={1}
                                style={{
                                  padding: "12px 0px",
                                  textAlign: "center",
                                }}>
                                <div className="rs-select2--light rs-select2--sm">
                                  <input
                                    style={{
                                      padding: "5px 15px",
                                      margin: "3px 0",
                                      border: "1px solid #DCDCDC",
                                      borderRadius: "4px",
                                      width: "100%",
                                    }}
                                    type="text"
                                    placeholder="Search"
                                    className="js-select2"
                                    name="name"
                                    onChange={(event) => {
                                      event.persist();
                                      this.searchTermChangedHandler(
                                        "searchName",
                                        event,
                                      );
                                    }}
                                  />
                                </div>
                              </td>
                              <td></td>
                              <td></td>
                              <td
                                colSpan={1}
                                style={{
                                  padding: "12px 0px",
                                  textAlign: "center",
                                }}>
                                <div className="rs-select2--light rs-select2--sm">
                                  <input
                                    style={{
                                      padding: "5px 15px",
                                      margin: "3px 0",
                                      border: "1px solid #DCDCDC",
                                      borderRadius: "4px",
                                      width: "100%",
                                    }}
                                    placeholder="Search"
                                    className="js-select2"
                                    name="destination"
                                    onChange={(event) => {
                                      event.persist();
                                      this.searchTermChangedHandler(
                                        "searchDestination",
                                        event,
                                      );
                                    }}
                                  />
                                </div>
                              </td>
                              <td></td>
                            </tr>
                            {ownersList}
                          </tbody>
                        </table>
                        <Pagination
                          postsPerPage={this.state.ownersPerPage}
                          totalPosts={matchingOwners.length}
                          paginate={this.paginate}
                          currentPage={this.state.currentPage}
                        />
                      </div>
                    </div>
                    <div className="row user-data__footer">
                      {/* <button className="au-btn au-btn-load">load more</button> */}
                      {/* <div className="col-6"> */}
                      <DefaultButton
                        text="Send Email to Owners"
                        disabled={!isAnyOwnerSelected}
                        onClick={this.sendEmailsHandler}
                        style={{
                          background: isAnyOwnerSelected ? null : "#808080",
                          marginBottom: "20px",
                          borderColor: isAnyOwnerSelected ? null : "#808080",
                          color: isAnyOwnerSelected ? null : colors.WHITE,
                          margin: "auto",
                        }}
                      />
                      <DefaultButton
                        text=" Send Properties Link"
                        disabled={!isAnyOwnerSelected}
                        onClick={this.sendPropertiesLinkHandler}
                        style={{
                          background: isAnyOwnerSelected ? null : "#808080",
                          marginBottom: "20px",
                          borderColor: isAnyOwnerSelected ? null : "#808080",
                          color: isAnyOwnerSelected ? null : colors.WHITE,
                          margin: "auto",
                        }}
                      />
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12"></div>
                  </div>
                </div>
              </div>
              <div className="section__content section__content--p30">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* END MAIN CONTENT*/}
            {/* END PAGE CONTAINER*/}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.token,
});

const mapDispatchToProps = {
  getOwners,
  sendOwnersEmail,
  sendOwnersPropertiesLink,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(OwnersBulkEmail)));
