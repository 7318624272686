import React from "react";
import {withStyles} from "@material-ui/styles";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../utils";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";

const DateInput = (props) => {
  // const classes = useStyles();
  // const {classes} = props;
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
          <KeyboardDatePicker
            margin="normal"
            id={props.id}
            label={props.label}
            format="dd/MM/yyyy"
            value={props.value}
            onChange={props.onChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

const theme = createMuiTheme({
  palette: {
    primary: {
      // primary color
      contrastText: "#FFFFFF",
      dark: "#CBAA5C",
      main: "#CBAA5C", // black
      light: "#CBAA5C",
    },
  },
  typography: {
    fontSize: 13,
  },
});

export default DateInput;
