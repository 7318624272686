import React, {useEffect, createRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router";
import html2pdf from "html2pdf.js";
import SHADOWS from "../../assets/images/shadows.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import {getOwnerPropertyDetails} from "../../store/actions";

const queryString = require("query-string");

function GeneratePropertyPDF(props) {
  const dispatch = useDispatch();
  const [property, setProperty] = useState(null);
  const [viewOnly, setViewOnly] = useState("false");
  const [branded, setBranded] = useState(false);
  let defaultImage = "";
  var location = useLocation();

  var reference = createRef(null);
  var opt = {
    margin: 1,
    filename: "",
    image: {type: "jpeg", quality: 0.75},
    html2canvas: {
      scale: 2,
      letterRendering: true,
      useCORS: true,
    },
    jsPDF: {unit: "pt", format: "letter", orientation: "landscape"},
  };
  useEffect(() => {
    async function generatePDF() {
      window.scrollTo(0, 0);
      let params = queryString.parse(location.search);
      setViewOnly(params.view);
      if (params.branded === "true") setBranded(true);

      console.log(params);
      const response = await dispatch(
        getOwnerPropertyDetails(Buffer.from(params.p, "base64").toString()),
      );
      console.log("response");
      console.log(response.property);
      if (property === null) {
        setProperty(response.property);
      }
      if (property) {
        window.scrollTo(0, 0);
        opt.filename = `${property.refName}.pdf`;
        console.log("view");
        console.log(viewOnly);
        if (viewOnly === "false") {
          console.log("GENERATING");
          await html2pdf().set(opt).from(reference.current).save();
          // html2pdf()
          //   .from(reference.current)
          //   .toPdf()
          //   .get("pdf")
          //   .then(function (pdf) {
          //     window.open(pdf.output("blob"), '_blank');
          //   });
          // window.close();
        }
      }
    }
    generatePDF();
  }, [property]);

  if (property) {
    let images = Array(24).fill(<div />);
    let brandedHeight = branded ? 3 : 2;
    let pdfHeight = brandedHeight * 813;
    console.log(images);
    if (property.images) {
      if (property.images.length > 0) {
        pdfHeight = (brandedHeight + 1) * 813;
      }
      if (property.images.length > 4) {
        pdfHeight = (brandedHeight + 2) * 813;
      }
      if (property.images.length > 8) {
        pdfHeight = (brandedHeight + 3) * 813;
      }
      if (property.images.length > 12) {
        pdfHeight = (brandedHeight + 4) * 813;
      }
      if (property.images.length > 16) {
        pdfHeight = (brandedHeight + 5) * 813;
      }
      if (property.images.length > 20) {
        pdfHeight = (brandedHeight + 6) * 813;
      }
      defaultImage = `${process.env.REACT_APP_AWS_BUCKET_URL}/${
        property.refName
      }/${property.images[2]}?t=${Date.now()}`;
      // defaultImage = `${process.env.PUBLIC_URL}/assets/images/icons/bed-orange.png`;
      property.images.forEach((image, index) => {
        images[index] = (
          <img
            src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${
              property.refName
            }/${image}?t=${Date.now()}`}
            crossOrigin="anonymous"
            width="500px"
            height="334px"
          />
        );
      });
      var features = [];
      property.features.split(",").forEach((feature, index) => {
        if (index < 22) {
          features.push(
            <span
              style={{
                display: "inline-block",
                paddingLeft: "10px",
                paddingRight: "10px",
                width: "210px",
                fontSize: "14px",
              }}>
              • {feature}
            </span>,
          );
        }
      });
    }
    return (
      // Page height in landscape is 813px
      <div>
        {/* Loading Overlay */}
        {viewOnly === "true" ? (
          <div
            style={{
              height: "100vh",
              width: "100vw",
              backgroundColor: "rgba(0,0,0, 0.9)",
              position: "fixed",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#DCAC38",
              fontSize: "26px",
              zIndex: "10000",
            }}>
            GENERATING PDF &emsp;
            <CircularProgress style={{color: "#DCAC38"}} />
          </div>
        ) : null}
        <div
          style={{
            width: "100%",
            minWidth: "1200px",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#555",
            height: `${pdfHeight + 200}px`,
            paddingTop: "100px",
          }}>
          <div
            ref={reference}
            style={{
              height: `${pdfHeight}px`,
              width: "1055px",
              minWidth: "1055px",
              backgroundColor: "white",
            }}
            id="element-to-print">
            {/* FIRST PAGE */}
            <div
              style={{
                width: "1055px",
                height: "813px",
                backgroundColor: "black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              {branded ? (
                <img
                  width="1052"
                  src={`https://berealibiza-properties-images.s3.eu-central-1.amazonaws.com/Logos/BRI_Cover.png?t=${Date.now()}`}
                  crossOrigin="anonymous"
                />
              ) : (
                <img
                  width="1060"
                  src={`https://berealibiza-properties-images.s3.eu-central-1.amazonaws.com/Logos/BRI_Cover_Unbranded.png?t=${Date.now()}`}
                  crossOrigin="anonymous"
                />
              )}
            </div>
            {/* SECOND PAGE */}
            <div style={{height: "813px", width: "1050px"}}>
              {branded ? (
                <img
                  width="140"
                  style={{marginTop: "30px", marginLeft: "45px"}}
                  src={`https://berealibiza-properties-images.s3.eu-central-1.amazonaws.com/Logos/BRI_logo.pngt=${Date.now()}`}
                  crossOrigin="anonymous"
                />
              ) : null}
              <div
                style={{
                  marginTop: "10px",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  paddingTop: "40px",
                  color: "#444",
                  boxSizing: "border-box",
                  width: "100%",
                }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                  }}>
                  <div
                    style={{
                      fontSize: "28px",
                      textTransform: "uppercase",
                      letterSpacing: "3px",
                      fontWight: "500",
                      alignSelf: "flex-start",
                    }}>
                    Villa {property.refName}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "700px",
                        marginTop: "30px",
                      }}>
                      <div
                        style={{
                          fontSize: "22px",
                          fontWight: "300",
                          marginBottom: "10px",
                          alignSelf: "flex-start",
                        }}>
                        Description
                      </div>
                      <div
                        style={{
                          color: "#444",
                          fontWight: "200",
                          fontSize: "15px",
                          height: "430px",
                          boxSizing: "border-box",
                          textAlign: "justify",
                          textJustify: "inter-word",
                          marginRight: "10px",
                        }}>
                        {property.description.substring(0, 1200)}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "500px",
                          marginBottom: "20px",
                        }}>
                        <div
                          style={{
                            fontSize: "18px",
                            fontWight: "300",
                            width: "50%",
                          }}>
                          {property.noOfBedrooms} Bedrooms
                        </div>
                        <div
                          style={{
                            fontSize: "18px",
                            fontWight: "300",
                            width: "50%",
                          }}>
                          {property.noOfBathrooms} Bathrooms
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "18px",
                          fontWight: "300",
                          marginBottom: "10px",
                          alignSelf: "flex-start",
                        }}>
                        <b>License Number:</b> {property.licenseNumber}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "600px",
                        height: "500px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}>
                      <div
                        style={{
                          fontWeight: "500",
                          fontSize: "22px",
                          textTransform: "uppercase",
                          marginTop: "-38px",
                          marginBottom: "35px",
                        }}>
                        Features and Amenities
                      </div>
                      <div>{features}</div>
                      {property.latitude && property.longitude ? (
                        <div
                          style={{
                            color: "#999",
                            fontWight: "300",
                            textAlign: "center",
                            marginTop: "20px",
                          }}>
                          <img
                            src={`https://maps.googleapis.com/maps/api/staticmap?center=${property.latitude},${property.longitude}
                        &zoom=10&size=400x300&sensor=false&maptype=roadmap
                        &markers=color:red%7Clabel:${property.assetName}%7C${property.latitude},${property.longitude}
                        &key=AIzaSyCNUWGEGi67QJiT2ANzwAALc9ZCCwiyUhM`}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Third PAGE */}
            {images[0].type === "div" ? (
              <div />
            ) : (
              <div style={{height: "813px", width: "1050px"}}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                    boxSizing: "border-box",
                    marginLeft: "8px",
                    marginTop: "20px",
                  }}>
                  <div
                    style={{
                      color: "#444",
                      boxSizing: "border-box",
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                    }}>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[0].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[0]}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[1].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[1]}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      color: "#444",
                      boxSizing: "border-box",
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                    }}>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[2].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[2]}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[3].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[3]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* END PAGE 3*/}
            {/* FOURTH PAGE */}
            {images[4].type === "div" ? (
              <div />
            ) : (
              <div style={{height: "770px", width: "1050px"}}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    marginTop: "40px",
                    boxSizing: "border-box",
                    marginLeft: "8px",
                  }}>
                  <div
                    style={{
                      color: "#444",
                      boxSizing: "border-box",
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                    }}>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[4].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[4]}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[5].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[5]}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      color: "#444",
                      boxSizing: "border-box",
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                    }}>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[6].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[6]}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[7].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[7]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* END PAGE 4 */}
            {/* FIFTH PAGE */}
            {images[8].type === "div" ? (
              <div />
            ) : (
              <div style={{height: "770px", width: "1050px"}}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    marginTop: "40px",
                    boxSizing: "border-box",
                    marginLeft: "8px",
                  }}>
                  <div
                    style={{
                      color: "#444",
                      boxSizing: "border-box",
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                    }}>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[8].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[8]}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[9].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[9]}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      color: "#444",
                      boxSizing: "border-box",
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                    }}>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[10].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[10]}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[11].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[11]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* END PAGE 5 */}
            {/* SIXTH PAGE */}
            {images[12].type === "div" ? (
              <div />
            ) : (
              <div style={{height: "770px", width: "1050px"}}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    marginTop: "40px",
                    boxSizing: "border-box",
                    marginLeft: "8px",
                  }}>
                  <div
                    style={{
                      color: "#444",
                      boxSizing: "border-box",
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                    }}>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[12].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[12]}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[13].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[13]}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      color: "#444",
                      boxSizing: "border-box",
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                    }}>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[14].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[14]}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[15].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[15]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* END PAGE 6 */}
            {/* START PAGE 7 */}

            {images[16].type === "div" ? (
              <div />
            ) : (
              <div style={{height: "770px", width: "1050px"}}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    marginTop: "40px",
                    boxSizing: "border-box",
                    marginLeft: "8px",
                  }}>
                  <div
                    style={{
                      color: "#444",
                      boxSizing: "border-box",
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                    }}>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[16].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[16]}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[17].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[17]}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      color: "#444",
                      boxSizing: "border-box",
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                    }}>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[18].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[18]}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[19].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[19]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* END PAGE 7 */}
            {/* START PAGE 8 */}
            {images[20].type === "div" ? (
              <div />
            ) : (
              <div style={{height: "770px", width: "1050px"}}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    marginTop: "40px",
                    boxSizing: "border-box",
                    marginLeft: "8px",
                  }}>
                  <div
                    style={{
                      color: "#444",
                      boxSizing: "border-box",
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                    }}>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[20].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[20]}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[21].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[21]}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      color: "#444",
                      boxSizing: "border-box",
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                    }}>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[22].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[22]}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "518px",
                        height: "359px",
                        background: `white ${
                          images[23].type === "div" ? "" : `url(${SHADOWS})`
                        } no-repeat right bottom`,
                        backgroundSize: "518px 359px",
                      }}>
                      <div
                        style={{
                          width: "500px",
                          height: "334px",
                          overflow: "hidden",
                        }}>
                        {images[23]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* END PAGE 8 */}
            {/* START PAGE 9 */}
            {branded ? (
              <div style={{height: "813px", width: "1050px"}}>
                <div
                  style={{
                    display: "block",
                    position: "relative",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                  }}>
                  <img
                    style={{
                      position: "absolute",
                      left: "50%",
                      marginLeft: "-100px",
                      top: "48%",
                      marginTop: "-27.5px",
                    }}
                    width="200"
                    src={`https://berealibiza-properties-images.s3.eu-central-1.amazonaws.com/Logos/BRI_logo.pngt=${Date.now()}`}
                    crossOrigin="anonymous"
                  />
                  <div
                    style={{
                      position: "absolute",
                      marginLeft: "auto",
                      marginRight: "auto",
                      bottom: "3%",
                      display: "flex",
                      flexDirection: "column",
                      height: "72px",
                      textAlign: "center",
                      width: "100%",
                      justifyContent: "space-between",
                    }}>
                    <a
                      href="http://www.berealibiza.com"
                      target="_blank"
                      style={{color: "rgb(189, 138, 12)"}}>
                      www.berealibiza.com
                    </a>
                    <span>+34 646 184 112</span>
                    <span>info@berealibiza.com</span>
                  </div>
                </div>
              </div>
            ) : null}
            {/* END PAGE 9 */}
          </div>
        </div>
      </div>
    );
  } else return <div />;
}

export default GeneratePropertyPDF;
