import {
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,
  AUTH_LOGOUT,
  AUTH_SET_TOKEN,
  AUTH_REMOVE_TOKEN
} from "../actions/actionTypes";

const initialState = { token: null, authType: null, user: null, error: null };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_START:
      return {
        ...state,
        error: null
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        token: action.token,
        authType: action.authType,
        user: action.user,
        error: null
      };
    case AUTH_FAIL:
      return {
        ...state,
        error: action.error
      };
    case AUTH_LOGOUT:
      return {
        ...state,
        token: null,
        authType: action.authType,
        user: action.user,
        error: null
      };
    case AUTH_SET_TOKEN:
      return {
        ...state,
        token: action.token
      };
    case AUTH_REMOVE_TOKEN:
      return {
        ...state,
        token: null
      };
    default:
      return state;
  }
};

export default reducer;
