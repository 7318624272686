import React from "react";
import Dropdown from "../../components/Dropdown/Dropdown";
import {withStyles} from "@material-ui/styles";
import DateInput from "../../components/DateInput/DateInput";
import TextInput from "../../components/TextInput/TextInput";
import {Alert} from "reactstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";
import {colors} from "../../utils";
import CheckBoxDropdown from "../../components/CheckBoxDropdown/CheckBoxDropdown";

const InputRequest = (props) => {
  const {classes} = props;
  return (
    <div
      className="content-area single-property single-property-wrapper single-property-header"
      style={{
        backgroundColor: "#FCFCFC",
        width: "100%",
        padding: 20,
        fontWeight: 300,
      }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "2%",
        }}>
        <TextInput
          id="name"
          label="Name"
          type="text"
          value={props.request.name}
          onChange={(event) => {
            event.persist();
            props.setRequestProperty("name", event.target.value);
          }}
          error={props.showRequiredMessage.name}
          helperText={"* Enter the client name"}
          required={true}
        />
        <TextInput
          id="email"
          label="Email"
          type="text"
          value={props.request.email}
          onChange={(event) => {
            event.persist();
            props.setRequestProperty("email", event.target.value);
          }}
          error={props.showRequiredMessage.email}
          helperText={"* Enter the client email"}
          required={true}
        />
        <TextInput
          id="phone"
          label="Phone"
          type="text"
          value={props.request.phone}
          onChange={(event) => {
            event.persist();
            props.setRequestProperty("phone", event.target.value);
          }}
          error={props.showRequiredMessage.phone}
          helperText={"* Enter the client phone number"}
          required={true}
        />
        <TextInput
          id="numberOfPeople"
          label="Number of people"
          type="number"
          value={props.request.numberOfPeople}
          onChange={(event) => {
            event.persist();
            props.setRequestProperty("numberOfPeople", event.target.value);
          }}
          error={props.showRequiredMessage.numberOfPeople}
          helperText={"* Enter the number of people"}
          required={true}
          inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
        />
        <TextInput
          id="bedrooms"
          label="Bedrooms"
          type="number"
          value={props.request.bedrooms}
          onChange={(event) => {
            console.log("NUMBER OF BEDROOMS!");
            console.log(event.target.value);
            props.setRequestProperty("bedrooms", event.target.value);
          }}
          error={props.showRequiredMessage.bedrooms}
          helperText={"* Enter the number of bedrooms"}
          required={true}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "2%",
        }}>
        <div style={{width: "20%"}}>
          <TextInput
            id="budget"
            label="Budget"
            error={props.showRequiredMessage.budget}
            helperText={"* Enter the budget"}
            required={true}
            type="number"
            value={props.request.budget}
            onChange={(event) =>
              props.setRequestProperty("budget", event.target.value)
            }
          />
        </div>
        <div style={{width: "20%"}}>
          <TextInput
            id="preferred location"
            label="Preferred Location"
            type="text"
            error={props.showRequiredMessage.preferredLocation}
            helperText={"* Enter the client preferred location"}
            value={props.request.preferredLocation}
            onChange={(event) =>
              props.setRequestProperty("preferredLocation", event.target.value)
            }
          />
        </div>
        <div style={{width: "20%"}}>
          <DateInput
            id="fromDate"
            label="From Date"
            value={props.request.fromDate}
            onChange={(event) => props.setRequestProperty("fromDate", event)}
          />
        </div>
        <div style={{width: "20%"}}>
          <DateInput
            id="toDate"
            label="To Date"
            value={props.request.toDate}
            onChange={(event) => props.setRequestProperty("toDate", event)}
          />
        </div>
        <Dropdown
          values={[0, 1]}
          error={props.showRequiredMessage.visitedBefore}
          helperText={"* Select visited before"}
          required={true}
          value={props.request.visitedBefore}
          onSelect={(event) =>
            props.setRequestProperty("visitedBefore", event.target.value)
          }
          label="Visited Before"
          options={["No", "Yes"]}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "2%",
        }}>
        <Dropdown
          error={props.showRequiredMessage.propertyType}
          helperText={"* Select the property type"}
          required={true}
          value={props.request.propertyType}
          onSelect={(event) =>
            props.setRequestProperty("propertyType", event.target.value)
          }
          label="Select property type"
          options={[
            "Countryside",
            "Panoramic Nature",
            "Panoramic Sea View",
            "Partial Sea View",
            "Sea View",
          ]}
        />

        <Dropdown
          error={props.showRequiredMessage.paymentMethod}
          helperText={"* Select payment method"}
          required={true}
          value={props.request.paymentMethod}
          onSelect={(event) =>
            props.setRequestProperty("paymentMethod", event.target.value)
          }
          label="Payment Method"
          options={["Bank Transfer", "Credit Card", "Other"]}
        />
        <CheckBoxDropdown
          value={"-"}
          error={props.showRequiredMessage.serviceType}
          helperText={"* Select service type"}
          onSelect={(event) =>
            props.setRequestProperty("serviceType", event.target.value)
          }
          values={props.request.serviceType}
          label="Select service type"
          options={[
            "Airport Transfer",
            "Car rental/ Car and driver",
            "Cleaning",
            "Fridge stock/ Shopping",
            "Reservations (Concierge)",
            "Staff - Chef, Waiter, Masseuse...",
            "Yacht rental",
          ]}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "40%",
            height: "80px",
          }}>
          {props.loading ? (
            <CircularProgress style={{color: "#DCAC38"}} />
          ) : (
            <DefaultButton
              text="Add Request"
              // disabled={!props.isClientSelected}
              onClick={props.searchHandler}
              style={{
                padding: "0.5rem 2rem",
                // background: props.isClientSelected > 0 ? null : "#808080",
                marginBottom: "20px",
                // borderColor: props.isClientSelected > 0 ? null : "#808080",
                // color: props.isClientSelected > 0 ? null : colors.WHITE,
              }}
            />
          )}
        </div>
        <Alert
          isOpen={props.isSearchAlertVisible}
          color="success"
          style={{
            zIndex: "100",
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "15px",
            width: "300px",
            height: "50px",
            textAlign: "center",
          }}>
          Request saved successfully!
        </Alert>
      </div>
    </div>
  );
};

const styles = (theme) => ({
  input: {
    fontSize: "3rem",
  },
  reqMessage: {
    alignSelf: "flex-start",
    color: "#ff5151",
    fontSize: "12px",
    opacity: "0",
    transition: "all 0.3s",
  },
});

export default withStyles(styles)(InputRequest);
