import {
  Container,
  AppBar,
  Toolbar,
  TextField,
  FormControl,
} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import React from 'react';
import {withStyles} from '@material-ui/styles';
import {connect} from 'react-redux';
import {selectProperty} from '../../store/actions';

const queryString = require('query-string');

class SelectPropertyPage extends React.Component {
  componentDidMount = async () => {
    let params = queryString.parse(this.props.location.search);
    this.setState((prevState) => ({
      ...prevState,
      params,
    }));
    const response = await this.props.selectProperty({
      requestId: Buffer.from(params.r, 'base64').toString(),
      propertyId: Buffer.from(params.p, 'base64').toString(),
    });
  };

  render() {
    const {classes} = this.props;

    return (
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <AppBar className={classes.navbar}>
          <Toolbar>
            <div>
              <div style={{fontSize: '1px', lineHeight: '5px'}}>&nbsp;</div>
              <img
                align='center'
                alt='BRI-icon'
                src='https://berealibiza-properties-images.s3.eu-central-1.amazonaws.com/Logos/BRI_logo.png'
                title='BRI-icon'
                width={140}
              />
            </div>
          </Toolbar>
        </AppBar>
        <div className={classes.card}>
          <span className={classes.title}>Property selected successfully!</span>
          <span className={classes.title}>
            You can get back to email and select another property
          </span>
        </div>
      </Container>
    );
  }
}

const styles = (theme) => ({
  navbar: {
    backgroundColor: '#fff', //'#cd943c'
    boxShadow: '0px 1px rgba(138, 138, 138, 0.2)',
  },
  card: {
    marginTop: '10rem',
    width: '27rem',
    height: '22rem',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: '20px',
    color: '#717171',
    fontSize: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0rem 0rem 1rem rgba(0,0,0,0.2)',
    borderRadius: '2px',
  },
  title: {
    marginBottom: '2.5rem',
    letterSpacing: '1px',
    color: '#cd943c',
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    opacity: '1',
    transition: 'all 1s',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  formItem: {
    marginBottom: '20px',
    width: '20rem',
  },
  submit: {
    width: '10rem',
    height: '2.5rem',
    marginTop: '4rem',
    borderWidth: '0px',
    backgroundColor: '#cbaa5c',
    color: '#fff',
    fontWeight: '400',
    fontSize: '16px',
    textTransform: 'uppercase',
    borderRadius: '100px',
    transition: 'all 0.3s',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: '#fff',
      color: '#cbaa5c',
      borderColor: '#cbaa5c',
      borderWidth: '1px',
    },
  },
  text: {
    letterSpacing: '1px',
    color: '#cd943c',
    textAlign: 'center',
    marginBottom: '0',
    textTransform: 'uppercase',
    transition: 'all 1s',
  },
  reqMessage: {
    alignSelf: 'flex-start',
    color: '#ff5151',
    fontSize: '12px',
    opacity: '0',
    transition: 'all 0.3s',
    margin: 'auto',
  },
});
const formItemStyle = {
  root: {
    '& label.Mui-focused': {
      color: '#e6b74d',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#BBB',
      borderWidth: '1px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#BBB',
        borderWidth: '1px',
      },
      '&:hover fieldset': {
        borderColor: '#999',
        borderWidth: '1px',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#e6b74d',
        borderWidth: '1px',
      },
    },
  },
};
const CssTextField = withStyles(formItemStyle)(TextField);
const CssFormControl = withStyles(formItemStyle)(FormControl);

const mapDispatchToProps = {
  selectProperty,
};
const mapStateToProps = (state) => ({
  //   isAuthenticated: state.auth.token
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(SelectPropertyPage)));
