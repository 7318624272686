import { withRouter } from "react-router-dom";
import React from "react";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import arrayMove from "array-move";
import SortableImages from "../../components/SortableImages/SortableImages";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { colors } from "../../utils";
import { Alert } from "reactstrap";
import {
  getPropertyImagesAdmin,
  getPropertyImagesOwner,
  updatePropertyImagesAdmin,
  updatePropertyImagesOwner,
  updatePropertyDefaultImageAdmin,
  updatePropertyDefaultImageOwner,
  removePropertyPhoto,
} from "../../store/actions";
import YesNoDialog from "../../components/YesNoDialog/YesNoDialog";
const queryString = require("query-string");

class PropertyImages extends React.Component {
  state = {
    items: [1, 2, 3, 4, 5].map((val, index) => ({
      title: "Item " + index,
      index: index,
      imageSrc: `https://picsum.photos/180/180?random=${
        Math.floor(Math.random() * 400) + 1
        }`,
    })),
    buttonLoading: false,
    loading: false,
    isAlertVisible: false,
    deleteImage: "",
    showDialog: false,
    deleteLoading: false,
  };

  componentDidMount = async () => {
    const role = localStorage.getItem("authType");
    let params = queryString.parse(this.props.location.search);
    let propertyId = Buffer.from(params.pid, "base64").toString();
    let propertyPage = Buffer.from(params.page, "base64").toString();
    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    let request =
      role === "admin"
        ? this.props.getPropertyImagesAdmin(propertyId)
        : this.props.getPropertyImagesOwner(propertyId);
    const response = await request;

    if (response.refName) {
      console.log("No. of Images: ", response.images?.length);
      this.setState((prevState) => ({
        ...prevState,
        property: response,
        items: response.images,
        propertyId,
        propertyPage,
        role,
        loading: false,
      }));
    } else {
      console.log("An error occured");
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
  };

  onShowAlert = () => {
    this.setState({ isAlertVisible: true });
    setTimeout(
      function () {
        this.setState({ isAlertVisible: false });
      }.bind(this),
      5000
    );
  };
  updatePropertyImagesOrder = async () => {
    this.setState((prevState) => ({
      ...prevState,
      buttonLoading: true,
    }));

    let info = {
      propertyId: this.state.propertyId,
      images: this.state.items,
    };

    let request =
      this.state.role === "admin"
        ? this.props.updatePropertyImagesAdmin(info)
        : this.props.updatePropertyImagesOwner(info);
    const response = await request;
    this.onShowAlert();
    this.setState((prevState) => ({
      ...prevState,
      buttonLoading: false,
    }));
  };

  updatePropertyDefaultImage = async (defaultImage) => {
    let info = {
      propertyId: this.state.propertyId,
      defaultImage,
    };

    let request =
      this.state.role === "admin"
        ? this.props.updatePropertyDefaultImageAdmin(info)
        : this.props.updatePropertyDefaultImageOwner(info);
    const response = await request;

    this.setState((prevState) => ({
      ...prevState,
      property: { ...prevState.property, defaultImage },
    }));
  };

  showDialog = async (image) => {
    await this.setState((prevState) => ({
      ...prevState,
      deleteImage: image,
      showDialog: true,
    }));
    //this.removePhoto();
  };

  closeDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      deleteImage: "",
      showDialog: false,
    }));
  };
  goBack = () => {
    this.props.history.goBack();
  };
  removePhoto = async () => {
    try {
      this.setState((prevState) => ({
        ...prevState,
        deleteLoading: true,
      }));
      const response = await this.props.removePropertyPhoto(
        this.state.propertyId,
        this.state.deleteImage
      );
      console.log("After api call: " + response);
      // setTimeout(() => {
      this.setState((prevState) => ({
        ...prevState,
        showDialog: false,
        deleteLoading: false,
        property: {
          ...prevState.property,
          images: response.images ? response.images : [],
        },
        items: response.images ? response.images : [],
      }));
      // }, 1500);
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { classes } = this.props;
    return this.state.loading ? (
      <CircularProgress className={classes.circularProgress} />
    ) : (
        <div
          className={classes.root}
          style={this.state.role === "owner" ? {} : { paddingLeft: "210px" }}
        >
          <YesNoDialog
            visible={this.state.showDialog}
            onClose={this.closeDialog}
            title="Are you sure?"
            text="Are you sure you want to delete this photo?"
            onAccept={this.removePhoto}
            onReject={this.closeDialog}
            deleteLoading={this.state.deleteLoading}
          />
          <Alert
            isOpen={this.state.isAlertVisible}
            color="success"
            style={{
              zIndex: "1000",
              position: "fixed",
              left: "55%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              fontSize: "15px",
              width: "300px",
              height: "50px",
              textAlign: "center",
            }}
          >
            Images Updated Successfully!
        </Alert>
          {this.state.property?.images?.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // marginLeft: this.state.role === "owner" ? "0px" : "150px",
              }}
            >

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {this.state.buttonLoading ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress className={classes.buttonLoading} />
                  </div>
                ) : (
                    <div
                      style={{
                        // width: "33%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <DefaultButton
                        text="Update"
                        onClick={this.updatePropertyImagesOrder}
                      />
                    </div>
                  )}
                <div
                  style={{
                    // width: "33%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <DefaultButton text="Back" onClick={this.goBack} />
                </div>
              </div>

              <SortableImages
                items={this.state.items}
                refName={this.state.property.refName}
                onSortEnd={this.onSortEnd}
                defaultImage={this.state.property.defaultImage}
                onSetDefaultImage={this.updatePropertyDefaultImage}
                onDeleteImage={this.showDialog}
              />
              {/* {this.state.buttonLoading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "2rem",
                  justifyContent: "center",
                }}
              >
                <CircularProgress className={classes.buttonLoading} />
              </div>
            ) : (
              <DefaultButton
                text="Update"
                onClick={this.updatePropertyImagesOrder}
              />
            )} */}
            </div>
          ) : null}
        </div>
      );
  }
}

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "1rem",
    minWidth: "1440px",
    paddingTop: "50px",
    margin: "0px",
  },
  propertiesSection: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2rem",
  },
  gridContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "56rem",
    margin: "1rem",
    padding: "2rem",
    border: "1px solid #f7f7f7",
    borderRadius: "2px",
    boxSizing: "border-box",
  },
  circularProgress: {
    color: colors.PRIMARY,
    position: "absolute",
    left: "50%",
    top: "50%",
  },
  buttonLoading: {
    color: colors.PRIMARY,
  },
});

const mapDispatchToProps = {
  getPropertyImagesAdmin,
  getPropertyImagesOwner,
  updatePropertyImagesAdmin,
  updatePropertyImagesOwner,
  updatePropertyDefaultImageAdmin,
  updatePropertyDefaultImageOwner,
  removePropertyPhoto,
};
const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(PropertyImages)));
