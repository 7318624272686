import React from "react";
import { withStyles } from "@material-ui/styles";

import { colors } from "../../../utils";

const defaultButton = (props) => {
  return (
    <button
      className={props.classes.button}
      onClick={props.onClick}
      style={{
        ...props.style,
        
      }}
      disabled={props.disabled}
    >
      {props.text}
    </button>
  );
};

const styles = (theme) => ({
  button: {
    display: "inline-block",
    textTransform: "uppercase",
    textDecoration: "none",
    borderRadius: "10rem",
    padding: "0.5rem 3rem",
    transition: "all 0.2s",
    fontSize: "1rem",
    fontWeight: "400px",
    color: colors.WHITE,
    borderColor: colors.PRIMARY,
    borderWidth: 1,
    borderStyle: "solid",
    backgroundColor: colors.PRIMARY,
    borderBottomColor: colors.PRIMARY,
    marginBottom: "1rem",
    marginTop: "1rem",
    "&:active": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      backgroundColor: colors.WHITE,
      color: colors.PRIMARY,
    },
  },
});

export default withStyles(styles)(defaultButton);
