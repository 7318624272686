import React, {Component} from 'react';
import {Map, GoogleApiWrapper, Marker} from 'google-maps-react';
import {colors} from '../../utils';
import {withStyles} from '@material-ui/styles';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {updatePropertyLocation} from '../../store/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import DefaultButton from '../../components/Buttons/DefaultButton/DefaultButton';
const queryString = require('query-string');

const mapStyles = {
  height: '550px',
  width: '95%',
  // marginLeft: "auto",
  // marginRight: "auto",
};

export class MapContainer extends React.Component {
  state = {
    loading: '',
    propertyId: '',
    propertyPage: '',
    role: '',
    latitude: '',
    longitude: '',
    zoom: 11,
    center: {
      lat: 38.97,
      lng: 1.41,
    },
    markerPosition: {
      lat: 38.97,
      lng: 1.41,
    },
  };
  componentDidMount = async () => {
    let role = localStorage.getItem('authType');
    let params = queryString.parse(this.props.location.search);
    let propertyId = Buffer.from(params.id, 'base64').toString();
    let propertyPage = Buffer.from(params.page, 'base64').toString();
    let latitude = Buffer.from(params.lat, 'base64').toString();
    let longitude = Buffer.from(params.long, 'base64').toString();
    let propertyIds = this.props.location.state.propertyIds;
    console.log('propertyPage');
    this.setState((prevState) => ({
      ...prevState,
      propertyId,
      propertyPage,
      role,
      latitude,
      longitude,
      markerPosition: {
        lat: latitude,
        lng: longitude,
      },
      center: {
        lat: latitude ? latitude : 38.97,
        lng: longitude ? longitude : 1.41,
      },
      zoom: latitude && longitude ? 11 : 3,
      propertyIds,
    }));
  };

  saveLocation = async () => {
    try {
      this.setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      const response = await this.props.updatePropertyLocation(
        this.state.longitude,
        this.state.latitude,
        this.state.propertyId,
      );
      this.setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    } catch (err) {
      this.setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  onMarkerClick = (props, marker, e) => {
    this.setState({
      center: props.position,
      zoom: this.state.zoom + 1,
    });
  };

  onMapClicked = (mapProps, map, clickEvent) => {
    this.setState({
      center: {
        lat: clickEvent.latLng.lat().toFixed(9),
        lng: clickEvent.latLng.lng().toFixed(9),
      },
      markerPosition: {
        lat: clickEvent.latLng.lat().toFixed(9),
        lng: clickEvent.latLng.lng().toFixed(9),
      },
      latitude: clickEvent.latLng.lat().toFixed(9),
      longitude: clickEvent.latLng.lng().toFixed(9),
    });
  };

  navigateBack = () => {
    this.props.history.push({
      pathname:
        this.state.role == 'admin'
          ? '/editproperty?id=' +
            Buffer.from(this.state.propertyId.toString()).toString('base64') +
            `&page=${Buffer.from(this.state.propertyPage).toString('base64')}`
          : '/owner/editproperty?id=' +
            Buffer.from(this.state.propertyId.toString()).toString('base64') +
            `&page=${Buffer.from(this.state.propertyPage).toString('base64')}`,

      state: {propertyIds: this.state.propertyIds},
    });
    this.props.history.go(0);
  };
  render() {
    return (
      <div
        className='page-container'
        //style={{ paddingLeft: this.state.role == "admin" ? "165px" : "100px" }}
        style={{
          paddingLeft: this.state.role == 'admin' ? '210px' : '40px',
          paddingTop: this.state.role == 'admin' ? '0px' : '30px',
          minWidth: 1200,
        }}>
        <div className='section__content section__content--p30'>
          <div className='container-fluid'>
            <div style={{marginLeft: 'auto', marginRight: 'auto'}}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '1%',
                }}>
                <span
                  style={{
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    paddingBottom: '0.5rem',
                    paddingTop: '0.5rem',
                  }}>
                  <b style={{fontWeight: '500'}}> Latitude: </b>
                  {this.state.latitude ? this.state.latitude : '-'}
                </span>
                <span
                  style={{
                    paddingLeft: '20px',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    paddingBottom: '0.5rem',
                    paddingTop: '0.5rem',
                  }}>
                  <b style={{fontWeight: '500'}}> Longitude: </b>
                  {this.state.longitude ? this.state.longitude : '-'}
                </span>
                <div style={{marginLeft: 'auto'}}>
                  {this.state.loading ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '5px',
                        justifyContent: 'center',
                      }}>
                      <CircularProgress
                        style={{
                          color: colors.PRIMARY,
                          position: 'absolute',
                          left: '65%',
                        }}
                      />
                    </div>
                  ) : (
                    <DefaultButton
                      text='Save Location'
                      onClick={this.saveLocation}
                      style={{marginRight: '10px'}}
                    />
                  )}

                  <DefaultButton
                    text='Navigate'
                    style={{marginRight: '10px'}}
                    onClick={() => {
                      window.open(
                        `https://www.google.com/maps?q=${this.state.latitude},${this.state.longitude}`,
                      );
                    }}
                  />
                  {/* <button
                    className="au-btn au-btn-load"
                    style={{
                      background: colors.PRIMARY, // "#FFA600",
                      color: colors.WHITE,
                      border: "none",
                      marginRight: "10px",
                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      window.open(
                        `https://www.google.com/maps?q=${this.state.latitude},${this.state.longitude}`
                      );
                    }}
                  >
                    {"Navigate"}
                  </button> */}
                  <DefaultButton text='Back' onClick={this.navigateBack} />
                </div>
              </div>
              <p>Click on the map to set property location.</p>
              <Map
                google={this.props.google}
                zoom={this.state.zoom}
                style={mapStyles}
                initialCenter={this.state.center}
                center={this.state.center}
                onClick={this.onMapClicked}>
                <Marker
                  name={'Property'}
                  position={this.state.markerPosition}
                  onClick={this.onMarkerClick}
                />
              </Map>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyCNUWGEGi67QJiT2ANzwAALc9ZCCwiyUhM",
// })(MapContainer);

const mapStateToProps = (state) => ({
  //   isAuthenticated: state.auth.token
});

const mapDispatchToProps = {
  updatePropertyLocation,
};

const WrappedContainer = GoogleApiWrapper({
  apiKey: 'AIzaSyCNUWGEGi67QJiT2ANzwAALc9ZCCwiyUhM',
})(MapContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(WrappedContainer));
