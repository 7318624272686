import { Container, CardMedia } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import React from "react";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { colors, getImageBase64 } from "../../utils";
import { Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextInput from "../../components/TextInput/TextInput";
import { getOwners, addProperty } from "../../store/actions";
import "./AddProperty.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "../../components/Dropdown/Dropdown";
import CheckBoxDropdown from "../../components/CheckBoxDropdown/CheckBoxDropdown";
import TextField from "@material-ui/core/TextField";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";

const CssTextField = withStyles({
  root: {
    "input:-internal-autofill-selected": {
      backgroundColor: "#fff",
    },
    "& label.Mui-focused": {
      color: "#e6b74d",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#BBB",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBB",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e6b74d",
      },
    },
  },
})(TextField);

const styles = (theme) => ({
  circularProgress: {
    color: colors.PRIMARY,
    position: "absolute",
    left: "50%",
    top: "50%",
  },
  buttonLoading: {
    color: colors.PRIMARY,
  },
  reqMessage: {
    alignSelf: "flex-start",
    color: "#ff5151",
    fontSize: "12px",
    opacity: "0",
    transition: "all 0.3s",
  },
});

class AddProperty extends React.Component {
  state = {
    loading: false,
    propertyId: "",
    requestId: "",
    renterId: "",
    property: {
      assetName: "",
      refName: "",
      locationName: "",
      area: "",
      destination: "",
      noOfBathrooms: "",
      noOfBedrooms: "",
      price: "",
      description: "",
      layout: "",
      viewType: "",
      features: [],
      securityDeposit: "",
      ownerId: "",
      licenseNumber: "",
    },
    owners: [],
    ownersValues: [],
    showRequiredMessage: {
      assetName: false,
      refName: false,
      locationName: false,
      area: false,
      destination: false,
      noOfBathrooms: false,
      noOfBedrooms: false,
      price: false,
      description: false,
      layout: false,
      viewType: false,
      features: false,
      securityDeposit: false,
      ownerId: false,
      licenseNumber: false,
    },
  };

  componentDidMount = async () => {
    let role = localStorage.getItem("authType");
    this.setState((prevState) => ({
      ...prevState,
      role,
    }));

    const response = await this.props.getOwners();
    let owners = response.map((owner) => owner.name);
    let ownersValues = response.map((owner) => owner.id);
    if (response.status && response.status == "error") {
      console.log(response.data.message);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        owners: owners,
        ownersValues: ownersValues,
      }));
    }
  };
  setShowRequiredMessageProperty = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      showRequiredMessage: {
        ...prevState.showRequiredMessage,
        [name]: value,
      },
    }));
  };

  addPropertyHandler = async () => {
    if (
      this.state.property.viewType != "" &&
      this.state.property.features.length > 0 &&
      this.state.property.securityDeposit != "" &&
      this.state.property.ownerId != "" &&
      this.state.property.assetName != "" &&
      this.state.property.locationName != "" &&
      this.state.property.area != "" &&
      this.state.property.destination != "" &&
      this.state.property.noOfBathrooms != "" &&
      this.state.property.noOfBedrooms != "" &&
      this.state.property.price != "" &&
      this.state.property.description != "" &&
      this.state.property.layout != "" &&
      this.state.property.refName != "" &&
      this.state.property.licenseNumber != ""
    ) {
      try {
        this.setState((prevState) => ({
          ...prevState,
          loading: true,
          property: {
            ...prevState.property,
            description: prevState.property.description.trim(),
            layout: prevState.property.layout.trim(),
          },
        }));
        const response = await this.props.addProperty(this.state.property);

        //this.props.history.push("/properties");
        this.setState((prevState) => ({
          ...prevState,
          loading: false,
          property: {
            assetName: "",
            refName: "",
            locationName: "",
            area: "",
            destination: "",
            noOfBathrooms: "",
            noOfBedrooms: "",
            price: "",
            description: "",
            layout: "",
            viewType: "",
            features: [],
            securityDeposit: "",
            ownerId: "",
            licenseNumber: "",
          },
        }));
        this.setShowRequiredMessageProperty("viewType", false);
        this.setShowRequiredMessageProperty("features", false);
        this.setShowRequiredMessageProperty("securityDeposit", false);
        this.setShowRequiredMessageProperty("ownerId", false);
        this.setShowRequiredMessageProperty("assetName", false);
        this.setShowRequiredMessageProperty("locationName", false);
        this.setShowRequiredMessageProperty("area", false);
        this.setShowRequiredMessageProperty("destination", false);
        this.setShowRequiredMessageProperty("noOfBathrooms", false);
        this.setShowRequiredMessageProperty("noOfBedrooms", false);
        this.setShowRequiredMessageProperty("price", false);
        this.setShowRequiredMessageProperty("description", false);
        this.setShowRequiredMessageProperty("layout", false);
        this.setShowRequiredMessageProperty("refName", false);
        this.setShowRequiredMessageProperty("licenseNumber", false);
      } catch (err) {
        this.setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    } else {
      if (this.state.property.viewType == "") {
        this.setShowRequiredMessageProperty("viewType", true);
      } else {
        this.setShowRequiredMessageProperty("viewType", false);
      }
      if (this.state.property.features.length < 1) {
        this.setShowRequiredMessageProperty("features", true);
      } else {
        this.setShowRequiredMessageProperty("features", false);
      }
      if (this.state.property.securityDeposit == "") {
        this.setShowRequiredMessageProperty("securityDeposit", true);
      } else {
        this.setShowRequiredMessageProperty("securityDeposit", false);
      }
      if (this.state.property.ownerId == "") {
        this.setShowRequiredMessageProperty("ownerId", true);
      } else {
        this.setShowRequiredMessageProperty("ownerId", false);
      }
      if (this.state.property.assetName == "") {
        this.setShowRequiredMessageProperty("assetName", true);
      } else {
        this.setShowRequiredMessageProperty("assetName", false);
      }
      if (this.state.property.locationName == "") {
        this.setShowRequiredMessageProperty("locationName", true);
      } else {
        this.setShowRequiredMessageProperty("locationName", false);
      }
      if (this.state.property.area == "") {
        this.setShowRequiredMessageProperty("area", true);
      } else {
        this.setShowRequiredMessageProperty("area", false);
      }
      if (this.state.property.destination == "") {
        this.setShowRequiredMessageProperty("destination", true);
      } else {
        this.setShowRequiredMessageProperty("destination", false);
      }
      if (this.state.property.noOfBathrooms == "") {
        this.setShowRequiredMessageProperty("noOfBathrooms", true);
      } else {
        this.setShowRequiredMessageProperty("noOfBathrooms", false);
      }
      if (this.state.property.noOfBedrooms == "") {
        this.setShowRequiredMessageProperty("noOfBedrooms", true);
      } else {
        this.setShowRequiredMessageProperty("noOfBedrooms", false);
      }
      if (this.state.property.price == "") {
        this.setShowRequiredMessageProperty("price", true);
      } else {
        this.setShowRequiredMessageProperty("price", false);
      }
      if (this.state.property.description == "") {
        this.setShowRequiredMessageProperty("description", true);
      } else {
        this.setShowRequiredMessageProperty("description", false);
      }
      if (this.state.property.layout == "") {
        this.setShowRequiredMessageProperty("layout", true);
      } else {
        this.setShowRequiredMessageProperty("layout", false);
      }
      if (this.state.property.refName == "") {
        this.setShowRequiredMessageProperty("refName", true);
      } else {
        this.setShowRequiredMessageProperty("refName", false);
      }
      if (this.state.property.licenseNumber == "") {
        this.setShowRequiredMessageProperty("licenseNumber", true);
      } else {
        this.setShowRequiredMessageProperty("licenseNumber", false);
      }
    }
  };

  setProperty = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        [name]: value,
      },
    }));
  };

  featureCheckBoxHandler = (event) => {
    //const value = event.target.checked;
    const value = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        features: value,
      },
    }));
  };

  render() {
    const { classes } = this.props;

    return (
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minWidth: 1440,
          paddingTop: 50,
          margin: 0,
          paddingLeft: this.state.role == "owner" ? 0 : 210,
          //   padding: 50
        }}
      >
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <DefaultButton
            text="Back"
            onClick={() => {
              this.props.history.push("/properties");
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignSelf: "flex-end",
              marginTop: this.state.role == "owner" ? "20px" : "0px",
            }}
          />
          <div
            className="content-area single-property single-property-wrapper single-property-header"
            style={{
              backgroundColor: "#FCFCFC",
              width: "100%",
              padding: 20,
              fontWeight: 300,
            }}
            // autoComplete="off"
            // onSubmit={this.addPropertyHandler}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "2%",
              }}
            >
              <TextInput
                error={this.state.showRequiredMessage.assetName}
                helperText={"* Enter the property name"}
                required={true}
                id="assetName"
                label="Property Name"
                type="text"
                value={this.state.property.assetName}
                onChange={(event) =>
                  this.setProperty("assetName", event.target.value)
                }
              />
              <TextInput
                error={this.state.showRequiredMessage.price}
                helperText={"* Enter the price"}
                required={true}
                id="price"
                label="Price"
                type="number"
                value={this.state.property.price}
                onChange={(event) =>
                  this.setProperty("price", event.target.value)
                }
              />
              <TextInput
                error={this.state.showRequiredMessage.licenseNumber}
                helperText={"* Enter the license number"}
                required={true}
                id="licenseNumber"
                type="text"
                label="License Number"
                value={this.state.property.licenseNumber}
                onChange={(event) =>
                  this.setProperty("licenseNumber", event.target.value)
                }
              />
              <TextInput
                error={this.state.showRequiredMessage.area}
                helperText={"* Enter the area"}
                required={true}
                id="area"
                type="text"
                label="Area"
                value={this.state.property.area}
                onChange={(event) =>
                  this.setProperty("area", event.target.value)
                }
              />
              <TextInput
                error={this.state.showRequiredMessage.destination}
                helperText={"* Enter the destination"}
                required={true}
                id="destination"
                type="text"
                label="Destination"
                value={this.state.property.destination}
                onChange={(event) =>
                  this.setProperty("destination", event.target.value)
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "2%",
              }}
            >
              <TextInput
                error={this.state.showRequiredMessage.locationName}
                helperText={"* Enter the locaion"}
                required={true}
                id="location"
                label="Location"
                type="text"
                value={this.state.property.locationName}
                onChange={(event) =>
                  this.setProperty("locationName", event.target.value)
                }
              />
              <TextInput
                error={this.state.showRequiredMessage.noOfBedrooms}
                helperText={"* Enter the bedrooms"}
                required={true}
                id="bedrooms"
                label="Bedrooms"
                type="number"
                value={this.state.property.noOfBedrooms}
                onChange={(event) =>
                  this.setProperty("noOfBedrooms", event.target.value)
                }
              />
              <TextInput
                error={this.state.showRequiredMessage.noOfBathrooms}
                helperText={"* Enter the bathrooms"}
                required={true}
                id="bathrooms"
                label="Bathrooms"
                type="number"
                value={this.state.property.noOfBathrooms}
                onChange={(event) =>
                  this.setProperty("noOfBathrooms", event.target.value)
                }
              />
              <TextInput
                error={this.state.showRequiredMessage.refName}
                helperText={"* Enter the ref. name"}
                required={true}
                id="refName"
                label="Ref. Name"
                type="text"
                value={this.state.property.refName}
                onChange={(event) =>
                  this.setProperty("refName", event.target.value)
                }
              />
            </div>
            {/* .property-meta */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <CssTextField
                error={this.state.showRequiredMessage.description}
                helperText={
                  this.state.showRequiredMessage.description
                    ? "* Enter the description"
                    : ""
                }
                style={{ borderColor: "#cd943c", marginTop: "1.5rem" }}
                id="standard-multiline-static"
                label={
                  "Description " +
                  this.state.property.description.length +
                  "/1200"
                }
                multiline
                rows="6"
                value={this.state.property.description}
                onChange={(event) =>
                  this.setProperty("description", event.target.value)
                } //{this.bodyHandler}
                fullWidth
                variant="outlined"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <CssTextField
                error={this.state.showRequiredMessage.layout}
                helperText={
                  this.state.showRequiredMessage.layout
                    ? "* Enter the layout"
                    : ""
                }
                style={{ borderColor: "#cd943c", marginTop: "1.5rem" }}
                id="standard-multiline-static"
                label={
                  "Layout " +
                  this.state.property.layout.length +
                  "/1200"
                }
                multiline
                rows="6"
                value={this.state.property.layout}
                onChange={(event) =>
                  this.setProperty("layout", event.target.value)
                } //{this.bodyHandler}
                fullWidth
                variant="outlined"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="section">
                <h4
                  className="s-property-title"
                  style={{ marginTop: "0.5rem" }}
                >
                  Type
                </h4>
                <div className="s-property-content">
                  <Dropdown
                    error={this.state.showRequiredMessage.viewType}
                    helperText={"* Select the view type"}
                    value={this.state.property.viewType}
                    onSelect={(event) =>
                      this.setProperty("viewType", event.target.value)
                    }
                    //label="Select type"
                    options={[                        
                      'Countryside',
                      'Panoramic Nature',
                      'Panoramic Sea View',
                      'Partial Sea View',
                      'Sea View',
                    ]}
                  />
                </div>
              </div>
              <div className="section">
                <h4
                  className="s-property-title"
                  style={{ marginTop: "0.5rem" }}
                >
                  Features
                </h4>
                <CheckBoxDropdown
                  error={this.state.showRequiredMessage.features}
                  helperText={"* Select the features"}
                  value={"-"}
                  onSelect={this.featureCheckBoxHandler}
                  values={this.state.property.features}
                  //label="Select type"
                  options={[
                    "Heating",
                    "Air conditioning",
                    "Barbeque",
                    "Beach towels provided",
                    "Central heating",
                    "Coffee Machine",
                    "Dishwasher",
                    "DVD player",
                    "Fully equipped kitchen",
                    "Garden",
                    "Gym",
                    "High - Speed Internet",
                    "Infinity Pool",
                    "Internet access",
                    "Jacuzzi",
                    "Linen provided",
                    "Microwave oven",
                    "Music system",
                    "Open fire",
                    "Private pool",
                    "Room fans",
                    "Telephone",
                    "Tennis court",
                    "Terrace / balcony",
                    "Toaster",
                    "Towels provided",
                    "TV",
                    "TV Satalite",
                    "Underfloor Heating",
                    "Washing machine",
                    "Wifi",
                    "Car not essential",
                    "Car recommended",
                    "Child friendly",
                    "Close to airport",
                    "Close to golf course",
                    "Close to historic sites",
                    "Close to shops",
                    "Close to subway",
                    "Close to train station",
                    "Electronic security",
                    "Highchair available",
                    "House swap available",
                    "Long term let available",
                    "Maid service available",
                    "Near to beach",
                    "No security deposit",
                    "Off road parking",
                    "Panoramic views",
                    "Parking",
                    "Pet friendly",
                    "SeaView",
                    "Secure complex",
                    "Smoking permitted",
                    "Wheelchair friendly",
                  ]}
                />
              </div>
              <div className="section">
                <h4
                  className="s-property-title"
                  style={{ marginTop: "0.5rem" }}
                >
                  Security Deposit
                </h4>
                <div className="s-property-content">
                  <Dropdown
                    error={this.state.showRequiredMessage.securityDeposit}
                    helperText={"* Select the security deposit"}
                    value={this.state.property.securityDeposit}
                    onSelect={(event) =>
                      this.setProperty("securityDeposit", event.target.value)
                    }
                    //label="Select type"
                    options={["1st Payment", "2nd Payment"]}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <div className="section" style={{ padding: 0, margin: 0 }}>
                <h4
                  className="s-property-title"
                  style={{ marginTop: "0.5rem" }}
                >
                  Owners
                </h4>
                <div className="s-property-content">
                  <Dropdown
                    error={this.state.showRequiredMessage.ownerId}
                    helperText={"* Select the owner"}
                    value={this.state.property.ownerId}
                    onSelect={(event) => {
                      this.setProperty("ownerId", event.target.value);
                    }}
                    values={this.state.ownersValues}
                    label=""
                    options={this.state.owners}
                  />
                </div>
              </div>
              {/* <div
                className="section"
                style={{ padding: 0, margin: 0, paddingRight: "4%" }}
              >
                <h4
                  className="s-property-title"
                  style={{ marginTop: "0.5rem" }}
                >
                  Commercial Status
                </h4>
                <div className="s-property-content">
                  <Dropdown
                    error={this.state.showRequiredMessage.commercialStatus}
                    helperText={"* Select the commercial status"}
                    value={this.state.property.commercialStatus}
                    onSelect={(event) =>
                      this.setProperty("commercialStatus", event.target.value)
                    }
                    //label="Select type"
                    options={["Rent", "Both"]}
                  />
                </div>
              </div> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                {this.state.loading ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignSelf: "flex-end",
                      marginBottom: "20px",
                      marginRight: "40px",
                    }}
                  >
                    <CircularProgress className={classes.buttonLoading} />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignSelf: "flex-end",
                    }}
                  >
                    <DefaultButton
                      text="Add Property"
                      onClick={this.addPropertyHandler}
                      style={{ marginBottom: "20px" }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  //   isAuthenticated: state.auth.token
});

const mapDispatchToProps = {
  getOwners,
  addProperty,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(AddProperty)));
