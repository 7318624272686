import React from "react";
import { withStyles } from "@material-ui/styles";
import { colors } from "../../utils";

const OwnerCard = props => {
  const { classes } = props;
  return (
    <a
      href={
        "/portal/ownerdetails?o=" +
        Buffer.from(props.owner.id.toString()).toString("base64")
      }
      className={classes.card}
    >
      <div className={classes.imageContainer} />
      <div className={classes.infoContainer}>
        <h5 className={classes.header}>
          <span className={classes.anchor}>{props.owner.name}</span>
        </h5>
        <div className={classes.context}>
          <span style={{ wordWrap: "break-word", width: "inherit" }}>
            <b> Email :</b> <br /> {props.owner.email}
          </span>
        </div>
      </div>
    </a>
  );
};

const styles = theme => ({
  card: {
    width: "12rem",
    height: "20rem",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    color: "#717171",
    fontSize: "20px",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0rem 0rem 0.5rem rgba(0,0,0,0.2)",
    borderRadius: "2px",
    cursor: "pointer",
    opacity: "0.9",
    transition: "all 0.4s",
    marginLeft: "1.3rem",
    marginRight: "1.3rem",
    "&:hover": {
      opacity: "0.7",
      color: colors.PRIMARY,
      textDecoration: "none",
      backgroundColor: colors.WHITE
    }
  },
  imageContainer: {
    width: "100%",
    height: "100%",
    backgroundImage: `linear-gradient(rgba(203,170,92,0.3), rgba(0,0,0,0)),url("https://berealibiza-properties-images.s3.eu-central-1.amazonaws.com/Logos/avatar.jpg")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    transition: "all 0.4s"
  },
  infoContainer: {
    width: "100%",
    boxSizing: "border-box",
    padding: "0.5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    height: "51%"
  },
  header: {
    alignSelf: "flex-start",
    color: "black",
    textTransform: "uppercase",
    letterSpacing: "2px",
    fontSize: "0.9rem",
    fontWeight: "700",
    boxSizing: "content-box",
    paddingBottom: "0.5rem",
    borderBottom: "1px dotted #CECBC7",
    width: "100%"
  },
  anchor: {},
  context: {
    display: "flex",
    fontSize: "0.9rem",
    justifyContent: "space-between",
    width: "100%"
  },
  iconContainer: {
    display: "flex",
    fontSize: "0.9rem",
    justifyContent: "space-around",
    width: "100%",
    marginTop: "0.5rem"
  },
  rating: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex"
  }
});

export default withStyles(styles)(OwnerCard);
