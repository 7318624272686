import React from 'react';
import {withStyles} from '@material-ui/styles';
import Rating from '@material-ui/lab/Rating';
import {colors} from '../../utils';
const PropertyCard = (props) => {
  const {classes} = props;
  return (
    <div
      className={classes.card}
      style={{cursor: 'pointer', marginBottom: 15}}
      onClick={props.navigateToEditProperty(props.property.id)}>
      <div className={classes.imageContainer}>
        <img
          style={{
            width: 'inherit',
            objectFit: 'cover',
            height: '100%',
          }}
          src={
            props.property.defaultImage
              ? `${process.env.REACT_APP_AWS_BUCKET_URL}/${
                  props.property.refName
                }/${props.property.defaultImage.replace(
                  ' ',
                  '%20',
                )}?t=${Date.now()}`
              : `${process.env.PUBLIC_URL}/assets/images/property/121/0e4851ba03bf40162b70448be880019973d6bcc0873ffce0249b90.jpg?_`
          }
        />
      </div>
      <div className={classes.infoContainer}>
        <h5 className={classes.header}>
          <span className={classes.anchor}>{props.property.refName}</span>
        </h5>
        <div className={classes.context}>
          <span>
            <b> Location :</b> {props.property.locationName}
          </span>
          <span> €{props.property.price}</span>
        </div>
        <div className={classes.context}>
          <span>
            <b> Last Updated On :</b> {props.property.lastUpdate}
          </span>
        </div>
        <div className={classes.context}>
          <span>
            <b> Rating:</b>{' '}
          </span>
          <div className={classes.rating}>
            <Rating
              name='read-only'
              value={props.property.rating}
              size='small'
              readOnly
            />
            <span>({props.property.ratingCount})</span>
          </div>
        </div>
        <div className={classes.iconContainer}>
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/icons/bed-orange.png`}
            />
            ({props.property.bedroom})
          </div>
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/icons/shower-orange.png`}
            />
            ({props.property.bathroom})
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = (theme) => ({
  card: {
    width: '16rem',
    height: '24rem',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    color: '#717171',
    fontSize: '20px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxShadow: '0rem 0rem 0.5rem rgba(0,0,0,0.2)',
    borderRadius: '2px',
    cursor: 'pointer',
    opacity: '0.9',
    transition: 'all 0.4s',
    '&:hover': {
      opacity: '0.7',
      color: colors.PRIMARY,
      textDecoration: 'none',
      backgroundColor: colors.WHITE,
    },
  },
  imageContainer: {
    width: '100%',
    height: '50%',
    marginBottom: '20px',
    //backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/property/121/0e4851ba03bf40162b70448be880019973d6bcc0873ffce0249b90)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    transition: 'all 0.4s',
  },
  infoContainer: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  header: {
    alignSelf: 'flex-start',
    color: 'black',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    fontSize: '0.9rem',
    fontWeight: '700',
    boxSizing: 'content-box',
    paddingBottom: '0.5rem',
    borderBottom: '1px dotted #CECBC7',
    width: '100%',
  },
  anchor: {},
  context: {
    display: 'flex',
    fontSize: '0.9rem',
    justifyContent: 'space-between',
    width: '100%',
  },
  iconContainer: {
    display: 'flex',
    fontSize: '0.9rem',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: '0.5rem',
  },
  rating: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
});

export default withStyles(styles)(PropertyCard);
