import {
  Button,
  Checkbox,
  Container,
  FormControlLabel
} from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import React from "react";
import logo from "../../../static/logo.png";
import { withStyles } from "@material-ui/styles";
import { signIn } from "../../../store/actions";
import { connect } from "react-redux";
import { colors, capitalizeFirstLetter } from "../../../utils";
import DefaultButton from "../../../components/Buttons/DefaultButton/DefaultButton";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  textField: {
    width: 300,
    "& label.Mui-focused": {
      color: colors.PRIMARY
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.PRIMARY
    }
  },
  welcome: {
    marginTop: 100,
    color: "#717171",
    fontSize: "20px"
  },
  circularProgress: { color: colors.PRIMARY }
});

class Login extends React.Component {
  state = {
    controls: {
      username: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "Username"
        },
        value: "",
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        error: ""
      },
      password: {
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "Password"
        },
        value: "",
        validation: {
          required: true,
          minLength: 6
        },
        valid: false,
        touched: false,
        error: ""
      }
    },
    id: "",
    password: "",
    showPassword: false,
    loading: false,
    userMode: "owner"
    // usernameError: "",
    // passwordError: ""
  };

  onIdChanged = e => {
    const value = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      id: value
    }));
    if (value !== "" && this.state.controls.username.error === "Empty")
      this.usernameErrorHandler("");
  };

  onPasswordChanged = e => {
    const value = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      password: value
    }));
    if (value !== "" && this.state.controls.password.error === "Empty")
      this.passwordErrorHandler("");
  };

  showPasswordHandler = () => {
    this.setState(prevState => ({
      ...prevState,
      showPassword: !prevState.showPassword
    }));
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  usernameErrorHandler = error => {
    this.setState(prevState => ({
      ...prevState,
      controls: {
        ...prevState.controls,
        username: {
          ...prevState.controls.username,
          error
        }
      }
    }));
  };

  passwordErrorHandler = error => {
    this.setState(prevState => ({
      ...prevState,
      controls: {
        ...prevState.controls,
        password: {
          ...prevState.controls.password,
          error
        }
      }
    }));
  };

  isFormValidHandler = () => {
    this.usernameErrorHandler(this.state.id === "" ? "Empty" : "");
    this.passwordErrorHandler(this.state.password === "" ? "Empty" : "");
    return this.state.id !== "" && this.state.password !== "";
  };

  isOwnerFormValidHandler = () => {
    this.usernameErrorHandler(this.state.id === "" ? "Empty" : "");
    return this.state.id !== "";
  };

  startLoading = () => {
    this.setState(prevState => ({
      ...prevState,
      loading: true
    }));
  };

  stopLoading = () => {
    this.setState(prevState => ({
      ...prevState,
      loading: false
    }));
  };

  changeUserModeHandler = () => {
    this.setState(prevState => ({
      ...prevState,
      userMode: prevState.userMode === "admin" ? "owner" : "admin"
    }));
  };

  login = async e => {
    try {
      e.preventDefault();

      if (
        (this.state.userMode === "admin" && this.isFormValidHandler()) ||
        (this.state.userMode === "owner" && this.isOwnerFormValidHandler())
      ) {
        this.startLoading();

        let authData = { id: this.state.id };

        if (this.state.userMode === "admin")
          authData.password = this.state.password;

        const response = await this.props.signIn(authData);

        // if (this.state.userMode === "admin")
        //   response = await this.props.signIn({
        //     id: this.state.id,
        //     password: this.state.password
        //   });
        // else
        //   response = await this.props.signIn({
        //     id: this.state.id,
        //     password: this.state.password
        //   });
        this.stopLoading();

        if (response.status === "success") {
          this.props.history.push("/");
        } else {
          if (response.message === "Username does not exist")
            this.usernameErrorHandler(response.message);
          else if (response.message === "Wrong password")
            this.passwordErrorHandler(response.message);
          else alert(response.message);
        }
      }
    } catch (err) {
      console.log(err.message);
      this.stopLoading();
      alert(err.message);
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 50
        }}
      >
        <p className={classes.welcome}>Welcome! Please login to your account</p>
        <form className={classes.container} noValidate autoComplete="off">
          <div>
            <TextField
              error={this.state.controls.username.error != ""}
              id="standard-basic"
              className={classes.textField}
              label={
                this.state.userMode === "admin" ? "Username" : "Email or Phone"
              }
              margin="normal"
              value={this.state.id}
              onChange={this.onIdChanged}
              helperText={this.state.controls.username.error}
            />
          </div>
          {this.state.userMode === "admin" ? (
            <div>
              <FormControl
                className={clsx(classes.margin, classes.textField)}
                error={this.state.controls.password.error != ""}
              >
                <InputLabel htmlFor="standard-adornment-password">
                  Password
                </InputLabel>
                <Input
                  id="standard-adornment-password"
                  type={this.state.showPassword ? "text" : "password"}
                  className={classes.textField}
                  value={this.state.password}
                  onChange={this.onPasswordChanged}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.showPasswordHandler}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? (
                          <Visibility />
                        ) : (
                            <VisibilityOff />
                          )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText id="component-error-text">
                  {this.state.controls.password.error}
                </FormHelperText>
              </FormControl>
              {/* <TextField
              id="standard-basic"
              className={classes.textField}
              label="Password"
              margin="normal"
              secureTextEntry={true}
              value={this.state.password}
              onChange={this.onPasswordChanged}
            /> */}
            </div>
          ) : null}
          <div style={{ width: 300 }}>
            <FormControlLabel
              style={{ marginTop: 20 }}
              control={
                <Checkbox
                  // checked={state.checkedB}
                  // onChange={handleChange("checkedB")}
                  value="remeber"
                  color="primary"
                />
              }
              label="Remember me"
            />
          </div>
          <div style={{ display: "flex", flex: 1, marginTop: 40 }}>
            {this.state.loading ? (
              <CircularProgress className={classes.circularProgress} />
            ) : (
                <DefaultButton text="Login" onClick={this.login} />
              )}
          </div>
        </form>
        <p
          style={{
            cursor: "pointer",
            color: colors.PRIMARY
          }}
          onClick={this.changeUserModeHandler}
        >
          {`Login as ${this.state.userMode === "admin" ? "Owner" : "Admin"}`}
        </p>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  authType: state.auth.authType
});

const mapDispatchToProps = {
  signIn
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(Login)));

// export default withRouter(
//   withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Login))
// );

// export default withRouter(withStyles(styles)(Login));
