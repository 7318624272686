import {withRouter} from "react-router-dom";
import React from "react";
import {withStyles} from "@material-ui/styles";
import {connect} from "react-redux";
import PropertyCard from "../../components/PropertyCard/PropertyCard";
import Grid from "@material-ui/core/Grid";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import {
  getOwnerProperties,
  getAllProperties,
  getStartingSeason,
  updateStartingSeason,
  changeDates,
} from "../../store/actions";
import Pagination from "../../components/Pagination/Pagination";
import TextField from "@material-ui/core/TextField";
import TextInput from "../../components/TextInput/TextInput";
import DateInput from "../../components/DateInput/DateInput";
import {colors} from "../../utils";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";

class Properties extends React.Component {
  state = {
    properties: [],
    owner: {},
    role: "",
    search: "",
    currentPage: 1,
    bedroomsFilter: "",
    startingSeason: "",
    open: false,
    active: "Yes",
  };

  setStartingSeason = async (event) => {
    this.setState((prevState) => ({
      ...prevState,
      startingSeason: event.toISOString().split("T")[0],
      open: false,
    }));

    const status = await this.props.updateStartingSeason(
      event.toISOString().split("T")[0],
    );
    if (status.status == "fail") {
      //alert
    }
  };

  openDialog = (event) => {
    this.setState((prevState) => ({
      ...prevState,
      open: true,
    }));
  };

  handleClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  componentDidMount = async () => {
    let role = localStorage.getItem("authType");
    console.log(role);
    if (role == "admin") {
      const properties = await this.props.getAllProperties();
      const startingSeason = await this.props.getStartingSeason();
      this.setState((prevState) => ({
        ...prevState,
        properties,
        role: "admin",
        startingSeason: startingSeason.startingSeason,
      }));
    } else {
      const owner = JSON.parse(localStorage.getItem("user"));
      this.setState((prevState) => ({
        ...prevState,
        owner,
        role: "owner",
        startingSeason: this.props.startingSeason,
      }));
      const properties = await this.props.getOwnerProperties(owner.id);
      this.setState((prevState) => ({
        ...prevState,
        properties,
      }));
    }
  };

  searchTermChangedHandler = (event) => {
    this.setState((prevState) => ({
      ...prevState,
      search: event.target.value,
      currentPage: 1,
    }));
  };

  bedroomsFilterChangedHandler = (event) => {
    if (event.target.value > 0) {
      this.setState((prevState) => ({
        ...prevState,
        bedroomsFilter: event.target.value,
        currentPage: 1,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        bedroomsFilter: "",
        currentPage: 1,
      }));
    }
  };

  paginate = async (pageNumber) => {
    this.setState((prevState) => ({
      ...prevState,
      currentPage: pageNumber,
    }));
  };

  setActiveChangeHandler = (event) => {
    this.setState((prevState) => ({
      ...prevState,
      active: event.target.value,
    }));
  };

  navigateToEditProperty = (id) => (event) => {
    let matchingProperties = [];
    if (this.state.properties?.length > 0) {
      matchingProperties = this.state.properties.filter((property) => {
        if (property.active === this.state.active) return property;
      });
    }
    let propertyIds = matchingProperties.map((property, index) => property.id);
    this.props.history.push({
      pathname:
        this.state.role == "admin"
          ? "/editproperty?id=" +
            Buffer.from(id.toString()).toString("base64") +
            "&page=" +
            Buffer.from("properties").toString("base64")
          : "/owner/editproperty?id=" +
            Buffer.from(id.toString()).toString("base64") +
            "&page=" +
            Buffer.from("owner/properties").toString("base64"),
      state: {propertyIds},
    });
    this.props.history.go(0);
  };

  changeDates = async (type) => {
    this.setState((prevState) => ({
      ...prevState,
      open: false,
    }));
    const status = await this.props.changeDates(type);
    if (status.status == "fail") {
      //alert
    }
  };

  render() {
    const {classes} = this.props;
    let properties = [];
    let matchingProperties = [];
    if (this.state.properties?.length > 0) {
      matchingProperties = this.state.properties.filter((property) => {
        if (property.active === this.state.active) return property;
      });
    }

    if (this.state.properties?.length > 0) {
      matchingProperties = matchingProperties.filter((property) => {
        if (
          property.assetName
            .toLowerCase()
            .includes(this.state.search.toLowerCase()) ||
          property.refName
            .toLowerCase()
            .includes(this.state.search.toLowerCase())
        ) {
          if (this.state.bedroomsFilter !== "") {
            if (property.bedroom === this.state.bedroomsFilter) {
              return property;
            } else {
              return;
            }
          } else {
            return property;
          }
        } else {
          return;
        }
      });

      const indexOfLastProperty = this.state.currentPage * 12;
      const indexOfFirstProperty = indexOfLastProperty - 12;
      properties = matchingProperties.slice(
        indexOfFirstProperty,
        indexOfLastProperty,
      );
      let propertyIds = properties.map((property, index) => property.id);
      properties = properties.map((property, index) => (
        <Grid key={index} item>
          <PropertyCard
            property={property}
            role={this.state.role}
            propertyIds={propertyIds}
            navigateToEditProperty={this.navigateToEditProperty}
          />
        </Grid>
      ));
    }

    return (
      <div
        style={{
          minWidth: 1440,
          paddingTop: 50,
          margin: 0,
          paddingLeft: this.state.role == "owner" ? 0 : 210,
        }}>
        {this.state.role == "owner" ? (
          <div>
            <Jumbotron
              src={`url(${process.env.PUBLIC_URL}/assets/images/page-header.jpg)`}>
              My Properties
            </Jumbotron>
          </div>
        ) : (
          <h3 className="title-5">Properties</h3>
        )}

        <div className={classes.propertiesSection}>
          {this.state.properties?.length > 0 ? (
            <div className={classes.gridContainer}>
              {this.state.role === "admin" ? (
                <div
                  style={{
                    marginRight: 10,
                    cursor: "pointer",
                    width: "30px",
                    alignSelf: "flex-end",
                    color: colors.PRIMARY,
                  }}
                  onClick={this.openDialog}>
                  <i className="fas fa-calendar-alt" />
                </div>
              ) : null}
              <Dialog
                onClose={this.handleClose}
                aria-labelledby="simple-dialog-title"
                open={this.state.open}
                fullWidth={true}
                maxWidth="xs">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 10,
                  }}>
                  <DialogTitle id="simple-dialog-title">
                    Set Starting Season Date
                  </DialogTitle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 20,
                    }}>
                    <DateInput
                      id="startingSeasonDate"
                      value={this.state.startingSeason}
                      onChange={this.setStartingSeason}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <button
                      className="au-btn au-btn-load"
                      onClick={() => {
                        this.changeDates("increment");
                      }}
                      style={{
                        background: colors.PRIMARY,
                        color: colors.WHITE,
                        border: "none",
                        textTransform: "capitalize",
                        marginRight: "20px",
                      }}>
                      {"Increment Dates"}
                    </button>
                    <button
                      className="au-btn au-btn-load"
                      onClick={() => {
                        this.changeDates("decrement");
                      }}
                      style={{
                        background: colors.PRIMARY,
                        color: colors.WHITE,
                        border: "none",
                        textTransform: "capitalize",
                      }}>
                      {"Decrement Dates"}
                    </button>
                  </div>
                </div>
              </Dialog>
              <div
                style={{
                  width: "100%",
                  margin: 15,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}>
                <TextInput
                  label="Search"
                  value={this.state.search}
                  onChange={(event) => {
                    event.persist();
                    this.searchTermChangedHandler(event);
                  }}
                  variant="outlined"
                  width="38%"
                  type="text"
                />
                {this.state.role == "admin" ? (
                  <DefaultButton
                    text="Add property"
                    onClick={() => {
                      this.props.history.push("/addproperty");
                    }}
                    style={{marginRight: 15, alignSelf: "center"}}
                  />
                ) : null}
              </div>
              <FormControl
                style={{alignSelf: "flex-start"}}
                component="fieldset">
                <RadioGroup
                  aria-label="isActive"
                  name="isActive"
                  value={this.state.active}
                  onChange={(event) => {
                    event.persist();
                    this.setActiveChangeHandler(event);
                  }}
                  style={{display: "flex", flexDirection: "row"}}>
                  <FormControlLabel
                    value="Yes"
                    control={<GoldRadio />}
                    label="ACTIVE"
                    style={{color: colors.PRIMARY}}
                  />
                  <FormControlLabel
                    value="PDF"
                    control={<GoldRadio />}
                    label="PDF"
                    style={{color: colors.PRIMARY}}
                  />
                  <FormControlLabel
                    value="No"
                    control={<GoldRadio />}
                    label="INACTIVE"
                    style={{color: colors.PRIMARY}}
                  />
                </RadioGroup>
              </FormControl>
              <Grid container spacing={3}>
                {properties}
              </Grid>

              <div style={{marginTop: "2rem"}}>
                <Pagination
                  postsPerPage={12}
                  totalPosts={matchingProperties.length}
                  paginate={this.paginate}
                  currentPage={this.state.currentPage}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    // paddingTop: "1rem"
  },
  propertiesSection: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem",
  },
  searchContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  gridContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "73rem",
    margin: "1rem",
    padding: "2rem",
    border: "1px solid #f7f7f7",
    borderRadius: "2px",
    boxSizing: "border-box",
  },
});

const GoldRadio = withStyles({
  root: {
    color: colors.PRIMARY,
    "&$checked": {
      color: colors.PRIMARY,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const mapDispatchToProps = {
  getOwnerProperties,
  getAllProperties,
  getStartingSeason,
  updateStartingSeason,
  changeDates,
};
const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(Properties)));
