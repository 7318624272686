import {
  Container,
  AppBar,
  Toolbar,
  TextField,
  FormControl,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import React from "react";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";
import CheckBoxDropdown from "../../components/CheckBoxDropdown/CheckBoxDropdown";
import { updateClientServices } from "../../store/actions";

import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";

const queryString = require("query-string");

class ExtraServicesForm extends React.Component {
  state = {
    params: {},
    formDisplay: true,
    extraServicesChecked: {
      carRental: false,
      airportTransfer: false,
      yachtRental: false,
      reservations: false,
      fridgeStock: false,
      cleaning: false,
      staff: false,
    },
    extraServices: [],
    showExtraServicesReqMessage: false,
  };

  componentDidMount = () => {
    let params = queryString.parse(this.props.location.search);
    params = {
      renterId: Buffer.from(params.r, "base64").toString(),
    };
    this.setState((prevState) => ({
      ...prevState,
      params,
    }));
  };

  extraServicesCheckBoxHandler = (event) => {
    event.persist();
    this.setState((prevState) => ({
      ...prevState,
      extraServicesChecked: {
        ...this.state.extraServicesChecked,
        [event.target.name]: event.target.checked,
      },
    }));
  };

  submitHandler = async () => {
    for (const property in this.state.extraServicesChecked) {
      if (this.state.extraServicesChecked[property]) {
        if (property === "airportTransfer") {
          this.state.extraServices.push("Airport Transfer");
        } else if (property === "carRental") {
          this.state.extraServices.push("Car rental/ Car and driver");
        } else if (property === "cleaning") {
          this.state.extraServices.push("Cleaning");
        } else if (property === "fridgeStock") {
          this.state.extraServices.push("Fridge stock/Shopping");
        } else if (property === "reservations") {
          this.state.extraServices.push("Reservations (Concierge)");
        } else if (property === "staff") {
          this.state.extraServices.push("Staff - Chef, Waiter, Bar, Masseuse");
        } else if (property === "yachtRental") {
          this.state.extraServices.push("Yacht Rental");
        }
      }
    }
    if (this.state.extraServices.length > 0) {
      const info = {
        extraServices: this.state.extraServices.join("|"),
        renterId: this.state.params.renterId,
      };
      let response = await this.props.updateClientServices(info);
      if (response === "success") {
        this.setState((prevState) => ({
          ...prevState,
          formDisplay: false,
        }));
      } else {
        alert("Something wrong has happened. Please try again later.");
      }
    } else {
      this.setState((prevState) => ({
        ...prevState,
        showExtraServicesReqMessage: true,
      }));
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AppBar className={classes.navbar}>
          <Toolbar>
            <div>
              <div style={{ fontSize: "1px", lineHeight: "5px" }}>&nbsp;</div>
              <img
                align="center"
                alt="BRI-icon"
                src="https://berealibiza-properties-images.s3.eu-central-1.amazonaws.com/Logos/BRI_logo.png"
                title="BRI-icon"
                width={140}
              />
            </div>
          </Toolbar>
        </AppBar>
        <div className={classes.card}>
          <span
            className={classes.thankyou}
            style={{ opacity: this.state.formDisplay ? "0" : "1" }}
          >
            Thank you!
          </span>
          <div
            className={classes.form}
            style={{ display: this.state.formDisplay ? "flex" : "none" }}
          >
            <span className={classes.title}>
              Please Select Your Required Services
            </span>
            <span
              className={classes.reqMessage}
              style={{
                opacity: this.state.showExtraServicesReqMessage ? "1" : "0",
              }}
            >
              * Please select at least one option
            </span>
            {/* <CheckBoxDropdown
              onSelect={this.extraServicesCheckBoxHandler}
              values={this.state.extraServices}
              label="Select services"
              options={[
                "Car rental/ Car and driver",
                "Airport Transfer",
                "Yacht rental",
                "Reservations (Concierge)",
                "Fridge stock/Shopping",
                "Cleaning",
                "Staff - Chef, Watier, Bar, Masseuse"
              ]}
            /> */}
            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <GoldCheckbox
                      checked={this.state.extraServicesChecked.airportTransfer}
                      onChange={this.extraServicesCheckBoxHandler}
                      name="airportTransfer"
                    />
                  }
                  label="Airport Transfer"
                />
                <FormControlLabel
                  control={
                    <GoldCheckbox
                      checked={this.state.extraServicesChecked.carRental}
                      onChange={this.extraServicesCheckBoxHandler}
                      name="carRental"
                    />
                  }
                  label="Car rental/ Car and driver"
                />
                <FormControlLabel
                  control={
                    <GoldCheckbox
                      checked={this.state.extraServicesChecked.cleaning}
                      onChange={this.extraServicesCheckBoxHandler}
                      name="cleaning"
                    />
                  }
                  label="Cleaning"
                />
                <FormControlLabel
                  control={
                    <GoldCheckbox
                      checked={this.state.extraServicesChecked.fridgeStock}
                      onChange={this.extraServicesCheckBoxHandler}
                      name="fridgeStock"
                    />
                  }
                  label="Fridge stock/Shopping"
                />
                <FormControlLabel
                  control={
                    <GoldCheckbox
                      checked={this.state.extraServicesChecked.reservations}
                      onChange={this.extraServicesCheckBoxHandler}
                      name="reservations"
                    />
                  }
                  label="Reservations (Concierge)"
                />
                <FormControlLabel
                  control={
                    <GoldCheckbox
                      checked={this.state.extraServicesChecked.staff}
                      onChange={this.extraServicesCheckBoxHandler}
                      name="staff"
                    />
                  }
                  label="Staff - Chef, Waiter, Bar, Masseuse"
                />
                <FormControlLabel
                  control={
                    <GoldCheckbox
                      checked={this.state.extraServicesChecked.yachtRental}
                      onChange={this.extraServicesCheckBoxHandler}
                      name="yachtRental"
                    />
                  }
                  label="Yacht rental"
                />
              </FormGroup>
            </FormControl>
            <DefaultButton text="Submit" onClick={this.submitHandler} />
          </div>
        </div>
      </Container>
    );
  }
}

const styles = (theme) => ({
  navbar: {
    backgroundColor: "#fff", //'#cd943c'
    boxShadow: "0px 1px rgba(138, 138, 138, 0.2)",
  },
  card: {
    marginTop: "10rem",
    minWidth: "30rem",
    minHeight: "30rem",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    padding: "20px",
    color: "#717171",
    fontSize: "20px",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0rem 0rem 1rem rgba(0,0,0,0.2)",
    borderRadius: "2px",
  },
  title: {
    marginBottom: "2.5rem",
    letterSpacing: "1px",
    color: "#cd943c",
    textAlign: "center",
  },
  form: {
    display: "flex",
    opacity: "1",
    transition: "all 1s",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  formItem: {
    marginBottom: "20px",
    width: "20rem",
  },
  submit: {
    width: "10rem",
    height: "2.5rem",
    marginTop: "4rem",
    borderWidth: "0px",
    backgroundColor: "#cbaa5c",
    color: "#fff",
    fontWeight: "400",
    fontSize: "16px",
    textTransform: "uppercase",
    borderRadius: "100px",
    transition: "all 0.3s",
    "&:active": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      backgroundColor: "#fff",
      color: "#cbaa5c",
      borderColor: "#cbaa5c",
      borderWidth: "1px",
    },
  },
  thankyou: {
    letterSpacing: "1px",
    color: "#cd943c",
    textAlign: "center",
    marginBottom: "0",
    textTransform: "uppercase",
    opacity: "0",
    transition: "all 1s",
  },
  reqMessage: {
    alignSelf: "flex-start",
    color: "#ff5151",
    fontSize: "12px",
    opacity: "0",
    transition: "all 0.3s",
  },
});
const formItemStyle = {
  root: {
    "& label.Mui-focused": {
      color: "#e6b74d",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#BBB",
      borderWidth: "1px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBB",
        borderWidth: "1px",
      },
      "&:hover fieldset": {
        borderColor: "#999",
        borderWidth: "1px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e6b74d",
        borderWidth: "1px",
      },
    },
  },
};

const GoldCheckbox = withStyles({
  root: {
    color: "#e6b74d",
    "&$checked": {
      color: "#e6b74d",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CssTextField = withStyles(formItemStyle)(TextField);
const CssFormControl = withStyles(formItemStyle)(FormControl);

const mapDispatchToProps = {
  updateClientServices,
};
const mapStateToProps = (state) => ({
  //   isAuthenticated: state.auth.token
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(ExtraServicesForm)));
