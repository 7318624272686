import { Container } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import React from "react";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { colors, getImageBase64 } from "../../utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextInput from "../../components/TextInput/TextInput";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";
import { updateEvent, getEventDetails, deleteEvent } from "../../store/actions";
import "./EditEvent.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "../../components/Dropdown/Dropdown";
import { Alert } from "reactstrap";
import DropFiles from "../../components/DropFiles/DropFiles";
import YesNoDialog from "../../components/YesNoDialog/YesNoDialog";
import TextField from "@material-ui/core/TextField";
const queryString = require("query-string");

const CssTextField = withStyles({
  root: {
    "input:-internal-autofill-selected": {
      backgroundColor: "#fff",
    },
    "& label.Mui-focused": {
      color: "#e6b74d",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#BBB",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBB",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e6b74d",
      },
    },
  },
})(TextField);

const styles = (theme) => ({
  circularProgress: {
    color: colors.PRIMARY,
    position: "absolute",
    left: "50%",
    top: "50%",
  },
  buttonLoading: {
    color: colors.PRIMARY,
  },
});

class EditEvent extends React.Component {
  state = {
    buttonLoading: false,
    deleteLoading: false,
    eventId: "",
    eventDate: "",
    visible: false,
    // event: null,

    event: {
      title: "",
      category: "",
      facebookUrl: "",
      defaultImage: null,
    },

    alertStatus: "",
    alertMessage: "",
    isError: false,

    eventPage: "",
    buttonLoading: false,
    loading: true,
    files: [],
    showDragAndDrop: false,
    imageTimestamp: Date.now(),
  };

  componentDidMount = async () => {
    let response = null;

    if (this.props.location.pathname === "/editevent") {
      let params = queryString.parse(this.props.location.search);
      let eventId = Buffer.from(params.id, "base64").toString();
      let eventPage = Buffer.from(params.page, "base64").toString();

      response = await this.props.getEventDetails(eventId);
      let eventDate = response.date.split("-");
      eventDate = `${eventDate[1]}/${eventDate[0]}`;

      this.setState((prevState) => ({
        ...prevState,
        event: response,
        eventDate,
        eventId,
        eventPage,
        loading: false,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  showAlert = () => {
    this.setState({ visible: true });
    setTimeout(
      function () {
        this.setState({ visible: false });
      }.bind(this),
      3000
    );
  };

  updateEventHandler = async () => {
    try {
      this.setState((prevState) => ({
        ...prevState,
        buttonLoading: true,
      }));
      if (this.state.event.title != "") {
        let defaultImage = null;
        if (this.state.files.length > 0)
          defaultImage = await getImageBase64(this.state.files[0]);

        let date = this.state.eventDate.split("/");
        date = `${date[1]}-${date[0]}-01`;

        const response = await this.props.updateEvent({
          ...(this.state.eventId !== "" ? { eventId: this.state.eventId } : {}),
          ...this.state.event,
          ...(defaultImage ? { defaultImage } : {}),
          date,
        });

        this.showAlert(
          `Event ${
          this.props.location.pathname === "/editevent" ? "Updated" : "Added"
          } Successfully!`,
          "success"
        );

        if (this.props.location.pathname === "/addevent") {
          this.props.history.replace(
            "/editevent?id=" +
            Buffer.from(response.id).toString("base64") +
            "&page=" +
            Buffer.from("1").toString("base64")
          );
        }

        this.setState((prevState) => ({
          ...prevState,
          showDragAndDrop: false,
          isError: false,
          files: [],
          event: {
            ...prevState.event,
            ...(response.defaultImage
              ? {
                defaultImage: response.defaultImage,
              }
              : {}),
          },
          eventId: response.id,

          ...(response.defaultImage
            ? {
              imageTimestamp: Date.now(),
            }
            : {}),
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          isError: true,
        }));
      }

      // this.showAlert();
    } catch (err) {
      this.showAlert("An error occured, please try again later", "danger");
    } finally {
      this.setState((prevState) => ({
        ...prevState,
        buttonLoading: false,
      }));
    }
  };

  setEvent = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      event: {
        ...prevState.event,
        [name]: value,
      },
    }));
  };

  setEventDate = (value) => {
    const regex = /^[0-9 \/]*$/;
    if (regex.test(value)) {
      this.setState((prevState) => ({
        ...prevState,
        eventDate: value,
      }));
    }
  };

  onDrop = (files) => {
    this.setState((prevState) => ({
      ...prevState,
      files,
    }));
  };

  toggleDragAndDrop = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDragAndDrop: !prevState.showDragAndDrop,
      ...(prevState.showDragAndDrop ? { files: [] } : {}),
    }));
  };

  showAlert = (alertMessage, alertStatus) => {
    this.setState({ visible: true, alertMessage, alertStatus });
    setTimeout(
      function () {
        this.setState({ visible: false });
      }.bind(this),
      3000
    );
  };

  showDeleteDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDeleteDialog: true,
    }));
  };

  closeDeleteDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDeleteDialog: false,
    }));
  };

  deleteEvent = async () => {
    try {

      this.setState((prevState) => ({
        ...prevState,
        deleteLoading: true,
      }));

      const response = await this.props.deleteEvent(
        this.state.eventId
      );

      this.setState((prevState) => ({
        ...prevState,
        deleteLoading: false,
        showDeleteDialog: false,
      }));

      this.props.history.push("events");

    } catch (err) {
      console.log(err);
    }
  };
  render() {
    const { classes } = this.props;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minWidth: 1200,
          padding: 50,
          margin: 0,
          paddingLeft: 216,
        }}
      >
        <Alert
          isOpen={this.state.visible}
          color={this.state.alertStatus}
          style={{
            zIndex: "100",
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "15px",
            width: "300px",
            height: "50px",
            textAlign: "center",
          }}
        >
          {this.state.alertMessage}
        </Alert>
        <YesNoDialog
          visible={this.state.showDeleteDialog}
          onClose={this.closeDeleteDialog}
          title="Are You sure?"
          text="Are you sure you want to delete event?"
          onAccept={this.deleteEvent}
          onReject={this.closeDeleteDialog}
          deleteLoading={this.deleteLoading}
        />
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            paddingRight: "0px",
          }}
        >
          {this.props.location.pathname === "/editevent" ?
            (<div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <DefaultButton
                text="Delete event"
                onClick={this.showDeleteDialog}
              />
            </div>) :
            null
          }
          {!this.state.loading ? (
            <div
              className="content-area single-property single-property-wrapper single-property-header"
              style={{
                backgroundColor: "#FCFCFC",
                width: "100%",
                padding: 20,
                fontWeight: 300,
                textAlign: "center",
              }}
            >
              {this.state.event.defaultImage ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-end",
                    }}
                  >
                    <div
                      className="s-property-content"
                      style={{ cursor: "pointer", height: "30px" }}
                      onClick={this.toggleDragAndDrop}
                    >
                      <p
                        style={{
                          padding: 0,
                          color: colors.PRIMARY,
                        }}
                      >
                        {this.state.showDragAndDrop ? "Cancel" : "Change"}
                      </p>
                    </div>

                    <img
                      src={`${process.env.REACT_APP_AWS_BUCKET_URL}/events/${this.state.event.defaultImage}?${this.state.imageTimestamp}`}
                      crossOrigin="anonymous"
                      style={{
                        "object-fit": "contain",
                        width: "500px",
                        height: "334px",
                      }}
                    />
                  </div>
                </div>
              ) : null}

              {this.state.showDragAndDrop ||
                this.state.event?.defaultImage == null ? (
                  <div style={{ marginTop: 15 }}>
                    <DropFiles
                      onDrop={this.onDrop}
                      files={this.state.files}
                      maxFiles={1}
                    />
                  </div>
                ) : null}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "4%",
                }}
              >
                <TextInput
                  error={this.state.isError && this.state.event.title == ""}
                  id="title"
                  label="Title"
                  type="text"
                  value={this.state.event.title}
                  onChange={(event) =>
                    this.setEvent("title", event.target.value)
                  }
                />
                <Dropdown
                  label="Category"
                  value={this.state.event.category}
                  onSelect={(event) =>
                    this.setEvent("category", event.target.value)
                  }
                  options={[
                    "Weddings",
                    "VIP Events",
                    "Corporate",
                    "Bachelor(ette)",
                    "Boat Party",
                    "Catering",
                  ]}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "4%",
                }}
              >
                <TextInput
                  id="date"
                  type="text"
                  label="Date (MM/YYYY)"
                  value={this.state.eventDate}
                  keyboardType="numeric"
                  onChange={(event) => this.setEventDate(event.target.value)}
                />
                <TextInput
                  width="50%"
                  id="facebookUrl"
                  type="text"
                  label="Facebook Link"
                  value={this.state.event.facebookUrl}
                  onChange={(event) =>
                    this.setEvent("facebookUrl", event.target.value)
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: "30px",
                }}
              >
                {this.state.buttonLoading ? (
                  <CircularProgress className={classes.buttonLoading} />
                ) : (
                    <DefaultButton
                      text={`${
                        this.props.location.pathname === "/editevent"
                          ? "Update"
                          : "Add"
                        } Event`}
                      onClick={this.updateEventHandler}
                    />
                  )}
              </div>
            </div>
          ) : (
              <CircularProgress className={classes.circularProgress} />
            )}
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = {
  updateEvent,
  getEventDetails,
  deleteEvent
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withStyles(styles)(EditEvent)));
