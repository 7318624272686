import React from "react";
import { withStyles } from "@material-ui/styles";
import { colors } from "../../utils";

const InvoiceItem = (props) => {
  return (
    <tr style={{ textAlign: "center" }}>
      <td>
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => props.deleteInvoice(props.invoice.invoiceNumber)}
        >
          <i className="fas fa-trash" />
        </div>
      </td>
      <td style={{ textAlign: "center" }}>{props.invoice.name}</td>
      <td style={{ textAlign: "center" }}>{props.invoice.requestDate}</td>
      <td style={{ textAlign: "center" }}>{props.invoice.startDate + " to " + props.invoice.endDate}</td>
      <td style={{ textAlign: "center" }}>{props.invoice.invoiceNumber}</td>
      <td style={{ textAlign: "center" }}>{props.invoice.serviceProvided}</td>
      <td style={{ textAlign: "center" }}>€{parseInt(props.invoice.price).toFixed(2)}</td>
      <td style={{ textAlign: "center" }}>€{parseInt(props.invoice.paid).toFixed(2)}</td>
      <td style={{ textAlign: "center" }}>€{parseInt(props.invoice.price - props.invoice.paid).toFixed(2)}</td>
      <td>
        <div
          className={props.classes.sendInvitation}
          style={{ textAlign: "center" }}
          onClick={() => props.requestPayment(props.invoice.invoiceNumber)}
        >
          Send Inv
        </div>
      </td>
    </tr>
  );
};

const styles = (theme) => ({
  sendInvitation: {
    color: "#808080",
    background: "#f2f2f2",
    borderRadius: "2px",
    lineHeight: "15px",
    padding: "7px",
    cursor: "pointer",
    "&:active": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      backgroundColor: colors.PRIMARY,
      color: colors.WHITE,
    },
  },
});

export default withStyles(styles)(InvoiceItem);
