import { Container, CardMedia } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import React from "react";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { colors, getImageBase64 } from "../../utils";
import { Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextInput from "../../components/TextInput/TextInput";
import {
  getRenters,
  createInvoice,
  getInvoices,
  deleteInvoice,
} from "../../store/actions";
import "./Invoice.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "../../components/Dropdown/Dropdown";
import CheckBoxDropdown from "../../components/CheckBoxDropdown/CheckBoxDropdown";
import TextField from "@material-ui/core/TextField";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";
import Card from "@material-ui/core/Card";
import DateFnsUtils from "@date-io/date-fns";
import InvoicesList from "../../components/InvoicesList/InvoicesList";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import YesNoDialog from "../../components/YesNoDialog/YesNoDialog";

const CssTextField = withStyles({
  root: {
    "input:-internal-autofill-selected": {
      backgroundColor: "#fff",
    },
    "& label.Mui-focused": {
      color: "#e6b74d",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#BBB",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBB",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e6b74d",
      },
    },
  },
})(TextField);

const CssKeyboardDatePicker = withStyles({
  root: {
    "input:-internal-autofill-selected": {
      backgroundColor: "#fff",
    },
    "& label.Mui-focused": {
      color: "#e6b74d",
    },
    "& .MuiPickersDay-current": {
      color: "#e6b74d",
    },
    "& .MuiPickersDay-daySelected": {
      backgroundColor: "#e6b74d",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#e6b74d",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBB",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e6b74d",
      },
    },
  },
})(KeyboardDatePicker);

const styles = (theme) => ({
  circularProgress: {
    color: colors.PRIMARY,
    position: "absolute",
    left: "50%",
    top: "50%",
  },
  buttonLoading: {
    color: colors.PRIMARY,
  },
  reqMessage: {
    alignSelf: "flex-start",
    color: "#ff5151",
    fontSize: "12px",
    opacity: "0",
    transition: "all 0.3s",
  },
});

class Invoice extends React.Component {
  state = {
    showDialog: false,
    deleteLoading: false,
    loading: false,
    deleteInvoice: "",
    propertyId: "",
    clientType: "new",
    clientName: "",
    address: "",
    email: "",
    phone: "",
    invoiceNumber: "",
    serviceProvided: "",
    price: "",
    client: "",
    clients: [],
    clientIds: [],
    invoices: [],
    currentPage: 1,
    invoicesPerPage: 10,
    showRequiredMessage: {
      clientName: false,
      address: false,
      email: false,
      phone: false,
      invoiceNumber: false,
      serviceProvided: false,
      price: false,
    },
  };

  componentDidMount = async () => {
    let role = localStorage.getItem("authType");
    this.setState((prevState) => ({
      ...prevState,
      role,
    }));

    const response = await this.props.getRenters();
    let clients = response.map((client) => client.name + ", " + client.email);
    let clientIds = response.map((client) => client.id);

    const invoices = await this.props.getInvoices();

    var date = new Date();
    date.setHours(date.getHours() + 2);
    var isodate = date.toISOString().slice(0, 10);
    this.setState((prevState) => ({
      ...prevState,
      clients: clients,
      clientIds: clientIds,
      startDate: isodate,
      endDate: isodate,
      invoices: invoices,
    }));
  };

  setShowRequiredMessage = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      showRequiredMessage: {
        ...prevState.showRequiredMessage,
        [name]: value,
      },
    }));
  };

  createInvoice = async () => {
    if (
      (this.state.clientName != "" &&
        this.state.address != "" &&
        this.state.email != "" &&
        this.state.phone != "" &&
        this.state.invoiceNumber != "" &&
        this.state.serviceProvided != "" &&
        this.state.price != "") ||
      (this.state.clientType == "existing" &&
        this.state.invoiceNumber != "" &&
        this.state.serviceProvided != "" &&
        this.state.price != "")
    ) {
      try {
        this.setState((prevState) => ({
          ...prevState,
          loading: true,
        }));
        let invoice = {
          invoiceNumber: this.state.invoiceNumber,
          serviceProvided: this.state.serviceProvided,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          price: this.state.price,
        };
        if (this.state.clientType == "new") {
          var clientInfo = {
            name: this.state.clientName,
            phone: this.state.phone,
            email: this.state.email,
            address: this.state.address,
          };
        } else {
          var clientInfo = {
            id: this.state.client,
          };
        }
        const response = await this.props.createInvoice(
          invoice,
          clientInfo,
          this.state.clientType
        );
        var date = new Date();
        date.setHours(date.getHours() + 2);
        var isodate = date.toISOString().slice(0, 10);
        this.setState((prevState) => ({
          ...prevState,
          invoices: response,
          loading: false,
          clientName: "",
          address: "",
          email: "",
          phone: "",
          invoiceNumber: "",
          serviceProvided: "",
          price: "",
          startDate: isodate,
          endDate: isodate,
        }));
        this.setShowRequiredMessage("clientName", false);
        this.setShowRequiredMessage("address", false);
        this.setShowRequiredMessage("email", false);
        this.setShowRequiredMessage("phone", false);
        this.setShowRequiredMessage("invoiceNumber", false);
        this.setShowRequiredMessage("serviceProvided", false);
        this.setShowRequiredMessage("price", false);
      } catch (err) {
        this.setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    } else {
      if (this.state.clientName == "") {
        this.setShowRequiredMessage("clientName", true);
      } else {
        this.setShowRequiredMessage("clientName", false);
      }
      if (this.state.address == "") {
        this.setShowRequiredMessage("address", true);
      } else {
        this.setShowRequiredMessage("address", false);
      }
      if (this.state.email == "") {
        this.setShowRequiredMessage("email", true);
      } else {
        this.setShowRequiredMessage("email", false);
      }
      if (this.state.phone == "") {
        this.setShowRequiredMessage("phone", true);
      } else {
        this.setShowRequiredMessage("phone", false);
      }
      if (this.state.invoiceNumber == "") {
        this.setShowRequiredMessage("invoiceNumber", true);
      } else {
        this.setShowRequiredMessage("invoiceNumber", false);
      }
      if (this.state.serviceProvided == "") {
        this.setShowRequiredMessage("serviceProvided", true);
      } else {
        this.setShowRequiredMessage("serviceProvided", false);
      }
      if (this.state.price == "") {
        this.setShowRequiredMessage("price", true);
      } else {
        this.setShowRequiredMessage("price", false);
      }
    }
  };

  resetCurrentPageHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
  };

  paginate = async (pageNumber) => {
    this.setState({
      currentPage: pageNumber,
    });
  };

  setField = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  deleteInvoice = async () => {
    this.setState((prevState) => ({
      ...prevState,
      deleteLoading: true,
    }));

    let status = await this.props.deleteInvoice(this.state.deleteInvoice);

    if (status == "success") {
      let invoices = this.state.invoices.filter(
        (invoice) => invoice.invoiceNumber != this.state.deleteInvoice
      );
      this.setState((prevState) => ({
        ...prevState,
        invoices: invoices,
      }));
    } else {
      console.log("Delete invoice failed");
    }
    this.closeDialog();
  };

  requestPayment = (invoiceNumber) => {
    this.props.history.push(
      `/requestpayment?id=${Buffer.from(invoiceNumber.toString()).toString(
        "base64"
      )}`
    );
  };

  showDialog = (invoiceNumber) => {
    this.setState((prevState) => ({
      ...prevState,
      showDialog: true,
      deleteInvoice: invoiceNumber
    }));
  };

  closeDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDialog: false,
      deleteLoading: false,
    }));
  };

  render() {
    const { classes } = this.props;

    return (
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginBottom: "2%",
          minWidth: 1200,
          paddingTop: 50,
          margin: 0,
          paddingLeft: 150,
        }}
      >
        <YesNoDialog
          visible={this.state.showDialog}
          onClose={this.closeDialog}
          title="Are you sure?"
          text="Are you sure you want to delete this invoice?"
          onAccept={this.deleteInvoice}
          onReject={this.closeDialog}
          deleteLoading={this.state.deleteLoading}
        />
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: "2%",
          }}
        >
          <Card
            style={{
              width: "800px",
              padding: "20px",
              height: "590px",
              marginLeft: "100px",
            }}
            elevation={5}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "50%",
                  height: "95%",
                }}
              >
                <h4
                  className="title-5"
                  style={{
                    paddingBottom: "10px",
                    borderBottomWidth: "2px",
                    borderBottomStyle: "solid",
                    borderBottomColor: "rgb(229, 229, 229)",
                    width: "90%",
                  }}
                >
                  Client Info
                </h4>
                <div style={{ padding: "0.9rem", paddingLeft: "1.5rem" }}>
                  <h4
                    className="s-property-title"
                    style={{ marginTop: "0.5rem" }}
                  >
                    Client Type
                  </h4>
                  <div className="s-property-content">
                    <Dropdown
                      styles={{ marginBottom: 0 }}
                      error={this.state.showRequiredMessage.clientType}
                      helperText={"* Select the client type"}
                      value={this.state.clientType}
                      values={["new", "existing"]}
                      onSelect={(event) =>
                        this.setField("clientType", event.target.value)
                      }
                      options={["New", "Existing"]}
                    />
                  </div>
                </div>
                {this.state.clientType != "existing" ? (
                  <div>
                    <TextInput
                      styles={{ margin: "0.9rem", marginLeft: "1.5rem" }}
                      error={this.state.showRequiredMessage.clientName}
                      helperText={"* Enter the client name"}
                      required={true}
                      id="clientName"
                      label="Client Name"
                      type="text"
                      value={this.state.clientName}
                      onChange={(event) =>
                        this.setField("clientName", event.target.value)
                      }
                    />
                    <TextInput
                      styles={{ margin: "0.9rem", marginLeft: "1.5rem" }}
                      error={this.state.showRequiredMessage.address}
                      helperText={"* Enter the address"}
                      required={true}
                      id="address"
                      label="Address"
                      type="text"
                      value={this.state.address}
                      onChange={(event) =>
                        this.setField("address", event.target.value)
                      }
                    />
                    <TextInput
                      styles={{ margin: "0.9rem", marginLeft: "1.5rem" }}
                      error={this.state.showRequiredMessage.email}
                      helperText={"* Enter the email"}
                      required={true}
                      id="email"
                      type="text"
                      label="Email"
                      value={this.state.email}
                      onChange={(event) =>
                        this.setField("email", event.target.value)
                      }
                    />
                    <TextInput
                      styles={{ margin: "0.9rem", marginLeft: "1.5rem" }}
                      error={this.state.showRequiredMessage.phone}
                      helperText={"* Enter the phone"}
                      required={true}
                      id="phone"
                      type="text"
                      label="Telephone"
                      value={this.state.phone}
                      onChange={(event) =>
                        this.setField("phone", event.target.value)
                      }
                    />
                  </div>
                ) : (
                    <div style={{ padding: "0.9rem", paddingLeft: "1.5rem" }}>
                      <h4
                        className="s-property-title"
                        style={{ marginTop: "0.5rem" }}
                      >
                        Clients
                    </h4>
                      <div className="s-property-content">
                        <Dropdown
                          error={this.state.showRequiredMessage.client}
                          helperText={"* Select the client"}
                          value={this.state.client}
                          onSelect={(event) => {
                            this.setField("client", event.target.value);
                          }}
                          values={this.state.clientIds} //client ids
                          label=""
                          options={this.state.clients} //clients names
                        //options={["dummy", "dummy"]}
                        />
                      </div>
                    </div>
                  )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "50%",
                  height: "95%",
                }}
              >
                <h4
                  className="title-5"
                  style={{
                    paddingBottom: "10px",
                    borderBottomWidth: "2px",
                    borderBottomStyle: "solid",
                    borderBottomColor: "rgb(229, 229, 229)",
                    width: "90%",
                  }}
                >
                  Create Invoice
                </h4>
                <TextInput
                  styles={{ margin: "0.9rem", marginLeft: "1.5rem" }}
                  width={"54%"}
                  error={this.state.showRequiredMessage.invoiceNumber}
                  helperText={"* Enter the invoice number"}
                  required={true}
                  id="invoiceNumber"
                  label="Invoice number"
                  type="text"
                  value={this.state.invoiceNumber}
                  onChange={(event) =>
                    this.setField("invoiceNumber", event.target.value)
                  }
                />
                <TextInput
                  styles={{ margin: "0.9rem", marginLeft: "1.5rem" }}
                  width={"54%"}
                  error={this.state.showRequiredMessage.serviceProvided}
                  helperText={"* Enter the service provided"}
                  required={true}
                  id="serviceProvided"
                  label="Service Provided"
                  type="text"
                  value={this.state.serviceProvided}
                  onChange={(event) =>
                    this.setField("serviceProvided", event.target.value)
                  }
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <CssKeyboardDatePicker
                    style={{
                      margin: "0.9rem",
                      marginLeft: "1.5rem",
                      width: "54%",
                    }}
                    required={true}
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="startDate"
                    label="Start Date of service"
                    value={this.state.startDate}
                    autoOk={true}
                    onChange={(date) => this.setField("startDate", date)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <CssKeyboardDatePicker
                    style={{
                      margin: "0.9rem",
                      marginLeft: "1.5rem",
                      width: "54%",
                    }}
                    required={true}
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="endDate"
                    label="End Date of service"
                    value={this.state.endDate}
                    autoOk={true}
                    onChange={(date) => this.setField("endDate", date)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
                <TextInput
                  styles={{ margin: "0.9rem", marginLeft: "1.5rem" }}
                  width={"54%"}
                  error={this.state.showRequiredMessage.price}
                  helperText={"* Enter the price"}
                  required={true}
                  id="price"
                  type="number"
                  label="Price"
                  value={this.state.price}
                  onChange={(event) =>
                    this.setField("price", event.target.value)
                  }
                />
                {this.state.loading ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      margin: "0.9rem",
                      marginLeft: "1.5rem",
                      width: "54%",
                    }}
                  >
                    <CircularProgress className={classes.buttonLoading} />
                  </div>
                ) : (
                    <DefaultButton
                      text="Create Invoice"
                      onClick={this.createInvoice}
                      style={{
                        margin: "0.9rem",
                        marginLeft: "1.5rem",
                        width: "57%",
                        padding: "0.5rem 1rem",
                      }}
                    />
                  )}
              </div>
            </div>
          </Card>
        </Container>
        <InvoicesList
          page={"invoices"}
          invoices={this.state.invoices}
          invoicesPerPage={this.state.invoicesPerPage}
          currentPage={this.state.currentPage}
          paginate={this.paginate}
          resetCurrentPage={this.resetCurrentPageHandler}
          deleteInvoice={this.showDialog}
          requestPayment={this.requestPayment}
        />
        
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  //   isAuthenticated: state.auth.token
});

const mapDispatchToProps = {
  getRenters,
  createInvoice,
  getInvoices,
  deleteInvoice,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(Invoice)));
