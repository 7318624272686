import api from "../../services/api";
import {errorHandler} from "../../utils";
//import {} from "./actionTypes";

export const getRequests = () => async (dispatch) => {
  try {
    const response = await api.getRequests();
    return response.data.requests;
  } catch (err) {
    console.log(err);
  }
};

export const getStartingSeason = () => async (dispatch) => {
  try {
    const response = await api.getStartingSeason();
    return response.data.startingSeason;
  } catch (err) {
    console.log(err);
  }
};

export const createInvoice = (invoice, clientInfo, clientType) => async (
  dispatch,
) => {
  try {
    const response = await api.createInvoice(invoice, clientInfo, clientType);
    return response.data.invoices;
  } catch (err) {
    console.log(err);
  }
};

export const updateStartingSeason = (startingSeason) => async (dispatch) => {
  try {
    const response = await api.updateStartingSeason(startingSeason);
    return response.data.status;
  } catch (err) {
    console.log(err);
  }
};

export const changeDates = (type) => async (dispatch) => {
  try {
    const response = await api.changeDates(type);
    return response.data.status;
  } catch (err) {
    console.log(err);
  }
};

export const duplicatePrices = (propertyId) => async (dispatch) => {
  try {
    const response = await api.duplicatePrices(propertyId);
    return response.data.status;
  } catch (err) {
    console.log(err);
  }
};

export const updateOwnerDetails = (owner) => async (dispatch) => {
  try {
    const response = await api.updateOwnerDetails(owner);
    return response.data.status;
  } catch (err) {
    console.log(err);
  }
};

export const getAllProperties = () => async (dispatch) => {
  try {
    const response = await api.getAllProperties();
    return response.data.properties;
  } catch (err) {
    console.log(err);
  }
};

export const getCalendarRequests = () => async (dispatch) => {
  try {
    const response = await api.getCalendarRequests();
    return response.data.calendarRequests;
  } catch (err) {
    console.log(err);
  }
};

export const getInvoices = () => async (dispatch) => {
  try {
    const response = await api.getInvoices();
    return response.data.invoices;
  } catch (err) {
    console.log(err);
  }
};

export const deleteInvoice = (invoiceNumber) => async (dispatch) => {
  try {
    const response = await api.deleteInvoice(invoiceNumber);
    return response.data.status;
  } catch (err) {
    console.log(err);
  }
};

export const getRequestPayments = (invoiceNumber) => async (dispatch) => {
  try {
    const response = await api.getRequestPayments(invoiceNumber);
    return response.data.requests;
  } catch (err) {
    console.log(err);
  }
};

export const deleteRequest = (id) => async (dispatch) => {
  try {
    const response = await api.deleteRequest(id);
    return response.data.status;
  } catch (err) {
    console.log(err);
  }
};

export const createRequest = (requestInfo) => async (dispatch) => {
  try {
    const response = await api.createRequest(requestInfo);
    return response.data.requests;
  } catch (err) {
    console.log(err);
  }
};

export const getOwners = () => async (dispatch) => {
  try {
    const response = await api.getOwners();
    return response.data.owners;
  } catch (err) {
    console.log(err);
  }
};

export const getRenters = () => async (dispatch) => {
  try {
    const response = await api.getRenters();
    return response.data.renters;
  } catch (err) {
    console.log(err);
  }
};

export const getProperties = (id, from, to) => async (dispatch) => {
  try {
    const response = await api.getProperties(id, from, to);
    return response.data.properties;
  } catch (err) {
    console.log(err);
  }
};

export const sendProperties = (info) => async (dispatch) => {
  try {
    const response = await api.sendProperties(info);
    return response.data.status;
  } catch (err) {
    console.log(err);
  }
};

export const approveProperties = (info) => async (dispatch) => {
  try {
    const response = await api.approveProperties(info);
    return response.data.status;
  } catch (err) {
    console.log(err);
  }
};
export const sendOwnersEmail = (owners, subject, body) => async (dispatch) => {
  try {
    const response = await api.sendOwnersEmail(owners, subject, body);
    return response.data.status;
  } catch (err) {
    console.log(err);
  }
};

export const sendOwnersPropertiesLink = (owners) => async (dispatch) => {
  try {
    const response = await api.sendOwnersPropertiesLink(owners);
    return response.data.status;
  } catch (err) {
    console.log(err);
  }
};

export const updatePaymentMethod = (info) => async (dispatch) => {
  try {
    const response = await api.updatePaymentMethod(info);
    return response.data.status;
  } catch (err) {
    console.log(err);
  }
};
export const sendClientsEmail = (clients, subject, body) => async (
  dispatch,
) => {
  try {
    const response = await api.sendClientsEmail(clients, subject, body);
    return response.data.status;
  } catch (err) {
    console.log(err);
  }
};

export const insertRequest = (info) => async (dispatch) => {
  try {
    const response = await api.insertRequest(info);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getPropertyImagesAdmin = (propertyId) => async (dispatch) => {
  try {
    const response = await api.getPropertyImagesAdmin(propertyId);
    return response.data.property;
  } catch (err) {
    console.log(err);
  }
};

export const getOwnerDetails = (ownerId) => async (dispatch) => {
  try {
    const response = await api.getOwnerDetails(ownerId);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const updatePropertyImagesAdmin = (info) => async (dispatch) => {
  try {
    const response = await api.updatePropertyImagesAdmin(info);

    if (response.data.status !== "success") {
      return response.data.status;
    }
    return response.data;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while processing property details");
  }
};

export const updatePropertyDefaultImageAdmin = (info) => async (dispatch) => {
  try {
    const response = await api.updatePropertyDefaultImageAdmin(info);

    if (response.data.status !== "success") {
      return response.data.status;
    }
    return response.data;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while processing property details");
  }
};

export const getEventDetails = (eventId) => async (dispatch) => {
  try {
    const response = await api.getEventDetails(eventId);
    return response.data.event;
  } catch (err) {
    console.log(err);
  }
};

export const updateEvent = (event) => async (dispatch) => {
  // await api.updateEvent(event);

  try {
    const response = await api.updateEvent(event);

    if (response.data.status !== "success") {
      return response.data.status;
    }
    return response.data;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while updating event details");
  }
};

export const getYachtDetails = (yachtId) => async (dispatch) => {
  try {
    const response = await api.getYachtDetails(yachtId);
    return response.data.yacht;
  } catch (err) {
    console.log(err);
  }
};

export const updateYacht = (yachtInfo) => async (dispatch) => {
  try {
    const response = await api.updateYacht(yachtInfo);

    if (response.data.status !== "success") {
      return response.data.status;
    }
    return response.data;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while updating yacht details");
  }
};

export const removeYachtPhoto = (yachtId, imageId) => async (dispatch) => {
  try {
    const response = await api.removeYachtPhoto(yachtId, imageId);

    console.log(response.data);

    if (response.data.status !== "success") {
      return response.data.status;
    }
    return response.data;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while removing yacht photo");
  }
};

export const updateYachtDefaultImage = (info) => async (dispatch) => {
  try {
    const response = await api.updateYachtDefaultImage(info);

    if (response.data.status !== "success") {
      return response.data.status;
    }
    return response.data;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while updating yacht image");
  }
};

export const deleteYacht = (yachtId) => async (dispatch) => {
  try {
    const response = await api.deleteYacht(yachtId);

    if (response.data.status !== "success") {
      return response.data.status;
    }
    return response.data;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while deleting yacht");
  }
};

export const deleteEvent = (eventId) => async (dispatch) => {
  try {
    const response = await api.deleteEvent(eventId);

    if (response.data.status !== "success") {
      return response.data.status;
    }
    return response.data;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while deleting event");
  }
};

export const addOwner = (owner) => async (dispatch) => {
  try {
    const response = await api.addOwner(owner);

    if (response.data.status !== "success") {
      return response.data.status;
    }
    return response.data;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while adding owner");
  }
};

export const deleteProperty = (propertyId) => async (dispatch) => {
  try {
    const response = await api.deleteProperty(propertyId);

    if (response.data.status !== "success") {
      return response.data.status;
    }
    return response.data;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while deleting property");
  }
};

export const deleteOwner = (ownerId) => async (dispatch) => {
  try {
    const response = await api.deleteOwner(ownerId);

    if (response.data.status !== "success") {
      return response.data.status;
    }
    return response.data;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while deleting owner");
  }
};
