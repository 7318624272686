import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {colors, addMonths} from "../../utils";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import "./main.scss";
import {getCalendarRequests} from "../../store/actions";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";

class AdminCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.calendarComponentRef1 = React.createRef();
    this.calendarComponentRef2 = React.createRef();
    this.calendarComponentRef3 = React.createRef();
  }
  state = {
    events: null,
    url: "",
    firstCalendar: addMonths(new Date(), 0),
    secondCalendar: addMonths(new Date(), 1),
    thirdCalendar: addMonths(new Date(), 2),
    firstDate: addMonths(new Date(), 0),
    secondDate: addMonths(new Date(), 1),
    thirdDate: addMonths(new Date(), 2),
  };

  componentDidMount = async () => {
    let response = [];
    response = await this.props.getCalendarRequests();
    let requests = response.map((event) => {
      return {
        start: event.start,
        end: event.end,
        title:
          event.renterName +
          " | Status: " +
          event.requestStatus,
        backgroundColor:
          event.requestStatus == "Pending First Payment & Contract"
            ? colors.LIGHT_PRIMARY
            : event.requestStatus == "Pending Second Payment"
            ? colors.YELLOW
            : colors.GREEN,
        borderColor:
          event.requestStatus == "Pending First Payment & Contract"
            ? colors.LIGHT_PRIMARY
            : event.requestStatus == "Pending Second Payment"
            ? colors.YELLOW
            : colors.GREEN,
        textColor: colors.BLACK,
        id: event.requestId,
        url:
          "/portal/requests?id=" +
          Buffer.from(event.requestId.toString()).toString("base64"),
      };
    });
    this.setState((prevState) => ({
      ...prevState,
      events: requests,
    }));
  };

  eventClick = (info) => {
    var eventObj = info.event;
    if (eventObj.url) {
      this.setState(
        {
          url: eventObj.url,
        },
        () => {
          this.refs.request.click();
        },
      );
      //window.open(eventObj.url);
      //info.jsEvent.preventDefault(); // prevents browser from following link in current tab.
    }
  };

  changeMonths = (months) => {
    let calendarApi1 = this.calendarComponentRef1.current.getApi();
    let calendarApi2 = this.calendarComponentRef2.current.getApi();
    let calendarApi3 = this.calendarComponentRef3.current.getApi();
    let firstDate = this.state.firstCalendar;
    let secondDate = this.state.secondCalendar;
    let thirdDate = this.state.thirdCalendar;
    let firstCalendar = addMonths(new Date(firstDate), months);
    let secondCalendar = addMonths(new Date(secondDate), months);
    let thirdCalendar = addMonths(new Date(thirdDate), months);
    calendarApi1.gotoDate(firstCalendar);
    calendarApi2.gotoDate(secondCalendar);
    calendarApi3.gotoDate(thirdCalendar);
    this.setState((prevState) => ({
      ...prevState,
      firstCalendar: firstCalendar,
      secondCalendar: secondCalendar,
      thirdCalendar: thirdCalendar,
    }));
  };
  render() {
    return (
      <div
        style={{
          minWidth: 1440,
          paddingTop: 30,
          margin: 0,
          paddingLeft: 210,
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            padding: "0 30px",
            paddingBottom: 20,
          }}>
          <DefaultButton
            text="Previous"
            style={{
              height: "42px",
              marginTop: 0,
              marginBottom: 0,
            }}
            onClick={() => this.changeMonths(-3)}
          />
          <DefaultButton
            text="Next"
            style={{
              height: "42px",
              marginLeft: "10px",
              marginTop: 0,
              marginBottom: 0,
            }}
            onClick={() => this.changeMonths(3)}
          />
        </div>
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="row">
              <div className="col removeScrollbar">
                <FullCalendar
                  selectable={true}
                  defaultDate={this.state.firstDate}
                  defaultView="dayGridMonth"
                  plugins={[dayGridPlugin, interactionPlugin]}
                  events={this.state.events}
                  eventClick={this.eventClick}
                  firstDay={1}
                  height={550}
                  header={{left: "title", center: "", right: ""}}
                  ref={this.calendarComponentRef1}
                />
                <a
                  ref="request"
                  href={"/portal" + this.state.url}
                  style={{display: "none"}}></a>
              </div>
              <div className="col removeScrollbar">
                <FullCalendar
                  selectable={true}
                  defaultDate={this.state.secondDate}
                  defaultView="dayGridMonth"
                  plugins={[dayGridPlugin, interactionPlugin]}
                  events={this.state.events}
                  eventClick={this.eventClick}
                  firstDay={1}
                  height={550}
                  header={{left: "title", center: "", right: ""}}
                  ref={this.calendarComponentRef2}
                />
                <a
                  ref="request"
                  href={"/portal" + this.state.url}
                  style={{display: "none"}}></a>
              </div>
              <div className="col removeScrollbar">
                <FullCalendar
                  selectable={true}
                  defaultDate={this.state.thirdDate}
                  defaultView="dayGridMonth"
                  plugins={[dayGridPlugin, interactionPlugin]}
                  events={this.state.events}
                  eventClick={this.eventClick}
                  firstDay={1}
                  height={550}
                  header={{left: "title", center: "", right: ""}}
                  ref={this.calendarComponentRef3}
                />
                <a
                  ref="request"
                  href={"/portal" + this.state.url}
                  style={{display: "none"}}></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  //   isAuthenticated: state.auth.token
});

const mapDispatchToProps = {
  getCalendarRequests,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AdminCalendar));
