import {Container, CardMedia} from "@material-ui/core";
import {Link, withRouter} from "react-router-dom";
import React, {createRef} from "react";
import {withStyles} from "@material-ui/styles";
import {connect} from "react-redux";
import {colors, getImageBase64} from "../../utils";
import {Redirect} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextInput from "../../components/TextInput/TextInput";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";
import {
  getYachtDetails,
  updateYacht,
  removeYachtPhoto,
  updateYachtDefaultImage,
  deleteYacht,
} from "../../store/actions";
import "./EditYacht.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import Dropdown from "../../components/Dropdown/Dropdown";
import CheckBoxDropdown from "../../components/CheckBoxDropdown/CheckBoxDropdown";
import {Alert} from "reactstrap";
import DropFiles from "../../components/DropFiles/DropFiles";
import YesNoDialog from "../../components/YesNoDialog/YesNoDialog";
import Pagination from "../../components/Pagination/Pagination";
import TextField from "@material-ui/core/TextField";
import {Map, GoogleApiWrapper} from "google-maps-react";
const queryString = require("query-string");

const CssTextField = withStyles({
  root: {
    "input:-internal-autofill-selected": {
      backgroundColor: "#fff",
    },
    "& label.Mui-focused": {
      color: "#e6b74d",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#BBB",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBB",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e6b74d",
      },
    },
  },
})(TextField);

const mapStyles = {
  // width: "100%",
  // height: "100%"
};

const styles = (theme) => ({
  circularProgress: {
    color: colors.PRIMARY,
    position: "absolute",
    left: "50%",
    top: "50%",
  },
  buttonLoading: {
    color: colors.PRIMARY,
  },
});

class EditYacht extends React.Component {
  state = {
    buttonLoading: false,
    deleteLoading: false,
    loading: true,
    yachtId: "",
    yacht: {
      name: "",
      price: "",
      capacity: "",
      length: "",
      fuel: "",
      description: "",
      types: [],
    },
    images: [],
    index: 0,
    direction: 0,
    showAlert: false,
    alertStatus: "",
    alertMessage: "",
    showDragAndDrop: false,
    showDialog: false,
    showDeleteDialog: false,
    files: [],
    yachtPage: "",
    reference: null,
    visible: false,
    isError: false,
  };

  componentDidMount = async () => {
    let params = queryString.parse(this.props.location.search);
    let response = null;

    if (this.props.location.pathname === "/edityacht") {
      let yachtId = Buffer.from(params.id, "base64").toString();
      let yachtPage = Buffer.from(params.page, "base64").toString();
      this.setState((prevState) => ({
        ...prevState,
        yachtId,
        yachtPage,
      }));

      response = await this.props.getYachtDetails(yachtId);
    }
    this.setState((prevState) => ({
      ...prevState,
      ...(response ? {yacht: response} : {}),
      loading: false,
    }));
  };

  handleSelect = (selectedIndex, e) => {
    this.setState((prevState) => ({
      ...prevState,
      index: selectedIndex,
      direction: e.direction,
    }));
  };

  updateYachtHandler = async () => {
    try {
      if (
        this.state.yacht.name != "" &&
        this.state.yacht.price != "" &&
        this.state.yacht.capacity != "" &&
        this.state.yacht.length != "" &&
        this.state.yacht.fuel != ""
      ) {
        this.setState((prevState) => ({
          ...prevState,
          buttonLoading: true,
          yacht: {
            description: prevState.yacht.description.trim(),
            ...prevState.yacht,
          },
        }));

        const response = await this.props.updateYacht({
          ...this.state.yacht,
          files: this.state.files,
          id: this.state.yachtId,
        });
        console.log(response);

        this.showAlert(
          `Yacht ${
            this.props.location.pathname === "/edityacht" ? "Updated" : "Added"
          } Successfully!`,
          "success",
        );

        if (this.props.location.pathname === "/addyacht") {
          console.log(this.props.location.pathname);
          this.props.history.replace(
            "/edityacht?id=" +
              Buffer.from(response.id).toString("base64") +
              "&page=" +
              Buffer.from("1").toString("base64"),
          );
          // this.props.history.go(0);
        }

        this.setState((prevState) => ({
          ...prevState,
          showDragAndDrop: false,
          isError: false,
          files: [],
          yacht: {
            ...prevState.yacht,
            images: response.images ? response.images : [],
          },
          yachtId: response.id,
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          isError: true,
        }));
      }
    } catch (err) {
      this.showAlert("An error occured, please try again later", "danger");
    } finally {
      this.setState((prevState) => ({
        ...prevState,
        buttonLoading: false,
      }));
    }
  };

  setYacht = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      yacht: {
        ...prevState.yacht,
        [name]: value,
      },
    }));
  };

  typeCheckBoxHandler = (event) => {
    const value = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      yacht: {
        ...prevState.yacht,
        types: value,
      },
    }));
  };

  toggleDragAndDrop = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDragAndDrop: !prevState.showDragAndDrop,
      ...(prevState.showDragAndDrop ? {files: []} : {}),
    }));
  };

  onDrop = (files) => {
    this.setState((prevState) => ({
      ...prevState,
      files,
    }));
  };

  showDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDialog: true,
    }));
  };

  closeDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDialog: false,
    }));
  };

  showDeleteDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDeleteDialog: true,
    }));
  };

  closeDeleteDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDeleteDialog: false,
    }));
  };

  removePhoto = async () => {
    try {
      let imageId = this.state.yacht.images[this.state.index];

      const response = await this.props.removeYachtPhoto(
        this.state.yachtId,
        imageId,
      );

      this.setState((prevState) => ({
        ...prevState,
        showDialog: false,
        yacht: {
          ...prevState.yacht,
          images: response.images ? response.images : [],
        },
        ...(response.images && prevState.index === response.images.length
          ? {index: response.images.length - 1}
          : {}),
      }));
    } catch (err) {
      console.log(err);
    }
  };

  paginate = async (pageNumber) => {
    this.setState((prevState) => ({
      ...prevState,
      index: pageNumber - 1,
    }));
  };

  showAlert = (alertMessage, alertStatus) => {
    this.setState({visible: true, alertMessage, alertStatus});
    setTimeout(
      function () {
        this.setState({visible: false});
      }.bind(this),
      3000,
    );
  };

  setDefaultImage = async () => {
    let defaultImage = this.state.yacht.images[this.state.index];

    let info = {
      yachtId: this.state.yachtId,
      defaultImage,
    };

    let response = await this.props.updateYachtDefaultImage(info);

    this.setState((prevState) => ({
      ...prevState,
      yacht: {...prevState.yacht, defaultImage},
    }));
  };

  deleteYacht = async () => {
    try {
      this.setState((prevState) => ({
        ...prevState,
        deleteLoading: true,
      }));

      const response = await this.props.deleteYacht(this.state.yachtId);

      this.setState((prevState) => ({
        ...prevState,
        deleteLoading: false,
        showDeleteDialog: false,
      }));

      this.props.history.push("yachts");
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const {classes} = this.props;
    let carouselItems = [];
    var imgSources = [];
    for (var i = 0; i < 9; i++) {
      imgSources[i] = "";
    }
    if (this.state.yacht?.images?.length) {
      carouselItems = this.state.yacht.images.map((image, index) => {
        if (index <= 8) {
          imgSources[index] = `${process.env.REACT_APP_AWS_BUCKET_URL}/yachts/${
            this.state.yachtId
          }/${image}?t=${Date.now()}`;
        }
        return (
          <Carousel.Item key={`carousel-${index}`}>
            <img
              className="d-block w-100 img"
              src={`${process.env.REACT_APP_AWS_BUCKET_URL}/yachts/${
                this.state.yachtId
              }/${image}?t=${Date.now()}`}
            />
          </Carousel.Item>
        );
      });
    }
    this.state.reference = createRef(null);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minWidth: 1200,
          padding: 50,
          margin: 0,
          paddingLeft: 216,
        }}>
        <Alert
          isOpen={this.state.visible}
          color={this.state.alertStatus}
          style={{
            zIndex: "100",
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "15px",
            width: "300px",
            height: "50px",
            textAlign: "center",
          }}>
          {this.state.alertMessage}
        </Alert>
        <YesNoDialog
          visible={this.state.showDialog}
          onClose={this.closeDialog}
          title="Are You sure?"
          text="Are you sure you want to delete this photo?"
          onAccept={this.removePhoto}
          onReject={this.closeDialog}
        />
        <YesNoDialog
          visible={this.state.showDeleteDialog}
          onClose={this.closeDeleteDialog}
          title="Are You sure?"
          text="Are you sure you want to delete yacht?"
          onAccept={this.deleteYacht}
          onReject={this.closeDeleteDialog}
          deleteLoading={this.deleteLoading}
        />
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            paddingRight: "0px",
          }}>
          {this.props.location.pathname === "/edityacht" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                width: "100%",
              }}>
              <DefaultButton
                text="Delete yacht"
                onClick={this.showDeleteDialog}
              />
            </div>
          ) : null}
          {!this.state.loading ? (
            <div
              className="content-area single-property single-property-wrapper single-property-header"
              style={{
                backgroundColor: "#FCFCFC",
                width: "100%",
                padding: 20,
                fontWeight: 300,
              }}>
              {this.state.yacht?.images?.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    padding: 0,
                  }}>
                  <div />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                      padding: 0,
                    }}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          height: "20px",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            ...(this.state.yacht.defaultImage !=
                            this.state.yacht.images[this.state.index]
                              ? {cursor: "pointer"}
                              : {}),
                            marginBottom: 15,
                          }}
                          onClick={
                            this.state.yacht.defaultImage !=
                            this.state.yacht.images[this.state.index]
                              ? this.setDefaultImage
                              : null
                          }>
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              color: colors.PRIMARY,
                              marginRight: 5,
                              marginTop: 2,
                            }}>
                            {this.state.yacht.defaultImage ==
                            this.state.yacht.images[this.state.index]
                              ? "Default"
                              : "Set as default"}
                          </p>
                        </div>
                        <p>{`${this.state.index + 1} / ${
                          this.state.yacht.images.length
                        }`}</p>
                        <div
                          className="s-property-content"
                          style={{cursor: "pointer"}}
                          onClick={this.showDialog}>
                          <p
                            style={{
                              padding: 0,
                              color: "red",
                            }}>
                            Remove
                          </p>
                        </div>
                      </div>
                      <Carousel
                        activeIndex={this.state.index}
                        direction={this.state.direction}
                        onSelect={this.handleSelect}
                        interval={null}
                        style={{
                          height: "340px",
                          width: "500px",
                          backgroundColor: colors.BLACK,
                          marginBottom: 10,
                        }}>
                        {carouselItems}
                      </Carousel>
                      <Pagination
                        postsPerPage={1}
                        totalPosts={this.state.yacht.images.length}
                        paginate={this.paginate}
                        currentPage={this.state.index + 1}
                      />
                    </div>
                  </div>
                  <div
                    style={{marginRight: 10, cursor: "pointer"}}
                    onClick={this.toggleDragAndDrop}>
                    {this.state.showDragAndDrop ? (
                      <div className="s-property-content">
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            color: colors.PRIMARY,
                          }}>
                          Cancel
                        </p>
                      </div>
                    ) : (
                      <div style={{display: "flex", flexDirection: "row"}}>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            color: colors.PRIMARY,
                            marginRight: 5,
                            marginTop: 2,
                          }}>
                          Add image
                        </p>
                        <span className="property-info-icon icon-add-to-photos">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24">
                            <path
                              className="meta-icon"
                              fill={colors.PRIMARY}
                              d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2z"
                            />
                          </svg>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
              {this.state.showDragAndDrop ||
              this.state.yacht?.images == null ? (
                <div style={{marginTop: 15}}>
                  <DropFiles onDrop={this.onDrop} files={this.state.files} />
                </div>
              ) : null}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  marginTop: "4%",
                }}>
                <TextInput
                  error={this.state.isError && this.state.yacht.name == ""}
                  id="yachtName"
                  label="Name"
                  type="text"
                  value={this.state.yacht.name}
                  onChange={(event) =>
                    this.setYacht("name", event.target.value)
                  }
                />
                <TextInput
                  error={this.state.isError && this.state.yacht.price == ""}
                  id="price"
                  label="Price"
                  style={{
                    marginRight: "29px",
                  }}
                  type="text"
                  value={this.state.yacht.price}
                  onChange={(event) =>
                    this.setYacht("price", event.target.value)
                  }
                />
                <TextInput
                  error={this.state.isError && this.state.yacht.capacity == ""}
                  id="capacity"
                  type="text"
                  label="Capacity"
                  value={this.state.yacht.capacity}
                  onChange={(event) =>
                    this.setYacht("capacity", event.target.value)
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "4%",
                  marginBottom: "2%",
                }}>
                <TextInput
                  error={this.state.isError && this.state.yacht.length == ""}
                  id="length"
                  label="Length"
                  type="text"
                  value={this.state.yacht.length}
                  onChange={(event) =>
                    this.setYacht("length", event.target.value)
                  }
                />
                <TextInput
                  error={this.state.isError && this.state.yacht.fuel == ""}
                  id="fuel"
                  label="Fuel"
                  type="text"
                  value={this.state.yacht.fuel}
                  onChange={(event) =>
                    this.setYacht("fuel", event.target.value)
                  }
                />
                <div
                  className="section property-features"
                  style={{
                    color: "#929FB2",
                    fontFamily: "Open Sans, sans-serif",
                  }}>
                  <h4 className="s-property-title">Types</h4>
                  <CheckBoxDropdown
                    value={"-"}
                    onSelect={this.typeCheckBoxHandler}
                    values={this.state.yacht?.types}
                    options={[
                      "Event Boats",
                      "Sailing Yachts",
                      "Motor Cruisers",
                      "Super Yachts",
                    ]}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}>
                <CssTextField
                  style={{borderColor: "#cd943c"}}
                  id="standard-multiline-static"
                  label={
                    "Description " +
                    this.state.yacht?.description?.length +
                    "/1200"
                  }
                  multiline
                  rows="6"
                  value={this.state.yacht.description}
                  onChange={(event) =>
                    this.setYacht("description", event.target.value)
                  } //{this.bodyHandler}
                  fullWidth
                  variant="outlined"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: "20px",
                }}>
                {this.state.buttonLoading ? (
                  <CircularProgress className={classes.buttonLoading} />
                ) : (
                  <DefaultButton
                    text={`${
                      this.props.location.pathname === "/edityacht"
                        ? "Update"
                        : "Add"
                    } Yacht`}
                    onClick={this.updateYachtHandler}
                  />
                )}
              </div>
            </div>
          ) : (
            <CircularProgress className={classes.circularProgress} />
          )}
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getYachtDetails,
  updateYacht,
  removeYachtPhoto,
  updateYachtDefaultImage,
  deleteYacht,
};

export default connect(
  null,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(EditYacht)));
