import api from "../../services/api";
import { errorHandler } from "../../utils";

export const getPropertyDetails = (propertyId, requestId) => async (
  dispatch
) => {
  try {
    const response = await api.getPropertyDetails(propertyId, requestId);
    if (response.data.status !== "success") {
      return response.data;
    }
    return response.data.data.property;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while processing property details");
  }
};

export const selectProperty = (info) => async (dispatch) => {
  try {
    const response = await api.selectProperty(info);
    if (response.data.status !== "success") {
      return response.data;
    }
    return response.data.status;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while processing property details");
  }
};

export const updateClientServices = (info) => async (dispatch) => {
  try {
    const response = await api.updateClientServices(info);
    return response.data.status;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while updating client services");
  }
};

export const updateRating = (info) => async (dispatch) => {
  try {
    const response = await api.updateRating(info);
    return response.data.status;
  } catch (err) {
    console.log(err);
    return errorHandler("An error occured while updating properties rating");
  }
};

export const getRequestIsRated = (r) => async (dispatch) => {
  try {
    const response = await api.getRequestIsRated(r);
    return response.data;
  } catch (err) {
    console.log(err);
    return errorHandler(
      "An error occured while fetching request is_rated field"
    );
  }
};

export const getAllYachts = () => async (dispatch) => {
  try {
    const response = await api.getAllYachts();
    return response.data.yachts;
  } catch (err) {
    console.log(err);
  }
};

export const getAllEvents = () => async (dispatch) => {
  try {
    console.log("here");
    const response = await api.getAllEvents();
    return response.data.events;
  } catch (err) {
    console.log(err);
  }
};
