import React from "react";
import { Route, Redirect } from "react-router-dom";
import SideBar from "../SideBar/SideBar";
import PrivateTopBar from "../TopBar/PrivateTopBar";

function PrivateRoute({ component: Component, path, ...rest }) {
  //let role = path.split("/")[1];
  let user = JSON.parse(localStorage.getItem("user"));
  let username = "";
  if(user)
  {
    username = user.username;
  }
  
  //if (role !== "owner") role = "admin";
  let role = localStorage.getItem("authType");

  let isAuthenticated =
    localStorage.getItem("token") != null &&
    localStorage.getItem("expirationDate") != null &&
    new Date(localStorage.getItem("expirationDate")) > new Date();

  return isAuthenticated ? (
    <Route {...rest} path={path}>
      <PrivateTopBar />
      {role === "admin" ? <SideBar /> : null}
      {path === "/" ? (
        role === "admin" ? (
          <Redirect to="/properties" />
        ) : (
          <Redirect to="/owner/properties" />
        )
      ) : (role == "owner" && !path.startsWith("/owner/")) ||
        (role == "admin" && path.startsWith("/owner/")) ||
        (username == "booking@berealibiza.com" &&
          (path.startsWith("/owners") ||
            path.startsWith("/ownerdetails") ||
            path.startsWith("/ownersemails") ||
            path.startsWith("/clientsemails"))) ? (
        <Redirect to="/" />
      ) : (
        <Component />
      )}
    </Route>
  ) : (
    <Redirect to="/login" />
  );
}

export default PrivateRoute;
