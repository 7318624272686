import React from "react";
import { AppBar, Toolbar } from "@material-ui/core";

function TopBar() {
  return (
    <AppBar
      style={{
        backgroundColor: "#fff",
        boxShadow: "0px 1px rgba(138, 138, 138, 0.2)",
        position: "sticky",
      }}
    >
      <Toolbar>
        <div>
          <div
            style={{
              fontSize: "1px",
              lineHeight: "5px"
            }}
          >
            &nbsp;
          </div>
          <img
            align="center"
            alt="BRI-icon"
            src="https://berealibiza-properties-images.s3.eu-central-1.amazonaws.com/Logos/BRI_logo.png"
            title="BRI-icon"
            width={140}
          />
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
